// Dependencies
import React, {Component} from "react";
import {BrowserRouter} from 'react-router-dom';

// Components
import UserProfile from './UserProfile'
import SearchBar from '../Bars/SearchBar.js'

// Assets
import user2 from '../../Assets/user2.png';

class UserDetails extends Component {
  constructor(props) {
    var userRole = JSON.parse(localStorage.getItem('User'));
    super(props);
    this.state = {
      currentUser: JSON.parse(localStorage.getItem("selectedUser")),
      userRole: [userRole.role]
    }
  }
  render() {

    const Print = () => {
      return (
        <div>
          <SearchBar
            SearchType="user"
            AddNewLink="/users/addnew"
            CardType="UserCards"
            hide=" d-none"
            cardViewCallback={this.selectedCard}/>
        </div>
      );
    }

    return (
      <div>
        <Print/>
        <div className="container-fluid">
          <BrowserRouter>
            <div className="row datesHeader">
              <div className="col-6 text-left my-3">
                <strong>Member since:
                </strong>
                {" " + this
                  .state
                  .currentUser
                  .createdOn
                  .substring(0, 10)}
              </div>
              <div className="col-6 text-right  my-3 ">
                <strong>Last login on:
                </strong>
                {!!(this.state.currentUser.lastLoginOn)
                  ? this
                    .state
                    .currentUser
                    .lastLoginOn
                    .substring(0, 10)
                  : " Never"}
              </div>
            </div>

            <div className="row userdetails pt-4 text-left">
              <div className="col-1 text-center m-auto imageContainer">
                <img src={user2} alt="User" className="User"/>
              </div>
              <div className="col-11">
                <div className="col-12 UserDetailName">User Information<hr/></div>
                <div className="row">
                  <div className="col-6 m-auto">
                    <b>User name:
                    </b>{!!(this.state.currentUser.userName)
                      ? this.state.currentUser.userName
                      : "N/a"}</div>
                  <div className="col-6 m-auto">
                    <b>Phone:
                    </b>{!!(this.state.currentUser.phone)
                      ? this.state.currentUser.phone
                      : "-"}</div>
                  <div className="col-6 m-auto">
                    <b>e-mail:
                    </b>{!!(this.state.currentUser.email)
                      ? this.state.currentUser.email
                      : "-"}</div>
                  <div className="col-6 m-auto">
                    <b>City:
                    </b>{!!(this.state.currentUser.city)
                      ? this.state.currentUser.city
                      : "-"}</div>
                  <div className="col-6 m-auto">
                    <b>Role:
                    </b>{!!(this.state.currentUser.role)
                      ? this.state.currentUser.role
                      : "-"}</div>
                  <div className="col-6 m-auto">
                    <b>Birth date:
                    </b>{!!(this.state.currentUser.birthDate)
                      ? this.state.currentUser.birthDate
                      : "-"}</div>
                </div>
              </div>
            </div>

            <UserProfile description={this.state.currentUser.description}/>
          </BrowserRouter>

        </div>
      </div>
    )
  }
}

export default UserDetails;