// Dependencies
import React, { Component } from "react";
import { Link } from "react-router-dom";
import SemiCircleProgressBar from "react-progressbar-semicircle";
import { withTranslation } from "react-i18next";
import Swal from "sweetalert2";

// Components
import AdminCard from "./AdminCard";
import Interests from "../Users/Interests";
import TranslationsCards from "./TranslationsCards";

// Assets
import imgEx from "../../Assets/imgEx.jpg";

class InstitutionProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      prevProfessionalInterests: this.props.professionalInterests,
      institutionTranslations: JSON.parse(
        localStorage.getItem("institutionTranslations")
      )
        ? JSON.parse(localStorage.getItem("institutionTranslations"))
        : [],
    };
  }

  componentDidMount() {
    if (this.props.image == undefined) {
      this.setState({
        img: imgEx,
      });
    } else {
      this.setState({
        img:
          "https://mentorme-staging-upload.s3-us-west-2.amazonaws.com/" +
          this.props.image,
      });
    }
  }

  addTranslation() {
    if (this.props.professionalInterests) {
       window.open("/institutions/details/addtranslation", "_self");
    } else {
       Swal.fire({
         title: "Error",
         text: this.props.t("alerts.cant_add_translation"),
         icon: "error",
       });
    }
  }

  render() {
    const Print = () => {
      return this.props.admins.map((result, index, array) => (
        <AdminCard
          key={index}
          email={result.mail}
          confirmationDate={result.confirmationDate}
          adminStatus={result.active}
          institutionId={this.props._id}
        />
      ));
    };

    const PrintInterests = () => {
      if (this.props.professionalInterests) {
        return this.props.professionalInterests.map((result, index, array) => (
          <Interests
            key={index}
            interests={result.interests}
            category={result.category}
          />
        ));
      } else {
        return "No interests";
      }
    };

    const PrintTranslations = () => {
      return this.state.institutionTranslations.map((result, index, array) => (
        <TranslationsCards
          name={result.institutionName}
          _id={result._id}
          language={result.language}
          type="institution"
        />
      ));
    };

    let text;

    if (this.props.institutionReferenceId) {
      text = "Esta es una traducción de la institución original";
      this.state.display = "none";
    } else {
      text = "Esta es la institución base en lenguaje: " + this.props.language;
      this.state.displayBase = "none";
    }

    return (
      <div className="container-fluid mt-3">
        <div className="row py-2">
          <div className="col-8 float-left text-left DescriptionHeaderTitle">
            <span className="h5">{text}</span>
          </div>
          <div className="col-4 float-right text-right">
            {/*<button className="btn btn-transparent mr-2">Delete</button>*/}
            <Link
              className="btn btn-outline-primary"
              to={"/institutions/details/edit"}
            >
              {this.props.t("edit")}
            </Link>
          </div>
        </div>
        <div className="row py-2">
          <div
            className="col-2 imageContainer"
            style={{ backgroundImage: `url(${this.state.img})` }}
          ></div>

          <div className="col p-0 pl-1">
            <div className="row p-0">
              <div className="col-7 text-left h3 font-weight-bold">
                {this.props.institutionName}
              </div>
              <div className="col-12 text-left">
                <span className="h5">
                  {this.props.t("institutions.about_institution")}:
                </span>
                <p className="smallText font-weight-light">
                  {this.props.description}
                </p>
              </div>
            </div>
          </div>

          <div
            style={{
              display: `${this.state.display}`,
            }}
            className="col-2 text-center"
          >
            <div className="row pt-2 text-center">
              <div className="col-12 text-center">
                <span className="InstProfileInfoHeader">
                  {this.props.t("interests_weight")}
                </span>
              </div>
              <div className="col-12 text-center">
                <SemiCircleProgressBar
                  percentage={this.props.weightPersonalInterests}
                  diameter="110"
                  stroke="#62DDCD"
                  background="#7CAFDD"
                />
              </div>
              <div className="col-6 text-center weigth-text">
                <span>
                  <b>{this.props.t("personal")}</b>
                </span>
                <br />
                <span>{this.props.weightPersonalInterests}%</span>
              </div>
              <div className="col-6 text-center weigth-text">
                <span>
                  <b>{this.props.t("professional")}</b>
                </span>
                <br />
                <span>{100 - this.props.weightPersonalInterests}%</span>
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            display: `${this.state.display}`,
          }}
          className="row pt-4"
        >
          <div className="col p-0 ">
            <div className="row">
              <div className="col-2 text-right p-0">
                <i className="fas fa-map-marked-alt InstProfileInfoHeader"></i>
              </div>
              <div className="col-10 text-left">
                <div className="row">
                  <span className="InstProfileInfoHeader">
                    {this.props.t("address")}
                  </span>
                </div>
                <div className="row">
                  <p className="InstProfileInfoText">
                    {this.props.streetAddress +
                      ". " +
                      this.props.city +
                      ", " +
                      this.props.state +
                      ", " +
                      this.props.country}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col p-0">
            <div className="row">
              <div className="col-2 text-right p-0">
                <i className="fa fa-envelope InstProfileInfoHeader"></i>
              </div>
              <div className="col-10 text-left">
                <div className="row">
                  <span className="InstProfileInfoHeader">
                    {this.props.t("email")}
                  </span>
                </div>
                <div className="row">
                  <p className="InstProfileInfoText">
                    <a href={"mailto:" + this.props.email}>
                      {this.props.email}
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col p-0">
            <div className="row">
              <div className="col-2 text-right p-0">
                <i className="fa fa-phone InstProfileInfoHeader"></i>
              </div>
              <div className="col-10 text-left">
                <div className="row">
                  <span className="InstProfileInfoHeader">
                    {this.props.t("phone")}
                  </span>
                </div>
                <div className="row">
                  <p className="InstProfileInfoText">
                    <a href={"tel:" + this.props.phone}>{this.props.phone}</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col p-0">
            <div className="row">
              <div className="col-2 text-right p-0">
                <i className="fa fa-window-maximize InstProfileInfoHeader"></i>
              </div>
              <div className="col-10 text-left">
                <div className="row">
                  <span className="InstProfileInfoHeader">
                    {this.props.t("website")}
                  </span>
                </div>
                <div className="row">
                  <p className="InstProfileInfoText">
                    <a
                      href={"https://" + this.props.webPageUrl}
                      target="_blank"
                    >
                      {this.props.webPageUrl}
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            display: `${this.state.display}`,
          }}
          className="row Interests text-left py-2"
        >
          <div className="col-6 pt-1">
            <h5>{this.props.t("institutions.translations")}</h5>
          </div>
          <div className="col-6 text-right ">
            <button
              className="btn btn-primary btn-sm"
              onClick={() => this.addTranslation()}
            >
              {this.props.t("add_translation")}
            </button>
          </div>
          <div className="col-12">
            <div className="row">
              <PrintTranslations />
            </div>
          </div>
        </div>

        <div className="row Interests text-left py-2">
          <div className="col-6 pt-1">
            <h5>{this.props.t("professional_interests")}</h5>
          </div>
          <div className="col-6 text-right ">
            <Link
              className="btn btn-primary btn-sm"
              to={
                "/institutions/details/newinterests/" +
                this.props.institutionName +
                "/" +
                this.props._id
              }
            >
              {this.props.t("edit_interests")}
            </Link>
          </div>
          <div className="col-12 profesionalInterests">
            <PrintInterests />
          </div>
        </div>

        <div
          style={{
            display: `${this.state.display}`,
          }}
          className="row InstitutionalsAdmins px-4"
        >
          <div className="col-6 text-left">
            <h5>{this.props.t("institutions.admins.institutional_admins")}</h5>
          </div>
          <div className="col-6 text-right">
            <Link
              className="AddNew pr-2"
              to={
                "/institutions/details/newadmin/" +
                this.props.institutionName +
                "/" +
                this.props._id
              }
            >
              <i className="fas fa-plus-circle"></i>
              <span className="AddText">
                &nbsp;&nbsp;{this.props.t("institutions.admins.add_admin")}
              </span>
            </Link>
          </div>

          <div></div>
          <Print />
        </div>
      </div>
    );
  }
}

export default withTranslation("Generals")(InstitutionProfile);
