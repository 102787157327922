// Dependecies
import React, { Component } from "react";
import { withTranslation } from 'react-i18next';

// Server URL
import serverURL from "../../serverURL";
var direccion = serverURL;

class InstitutionsHeaderCards extends Component {
  constructor(props) {
    super(props);
    var userRole = JSON.parse(localStorage.getItem("User"));
    this.state = {
      institutionStatus: this.props.isActive,
      userRole: userRole.role,
    };
  }

  render() {
    const AllowHandler = () => {
      if (this.state.userRole === "sysadmin") {
        return (
          <div className="col-6 text-left mt-2">
            <label className="switch">
              <input
                name="institutionStatus"
                type="checkbox"
                checked={this.state.institutionStatus}
                onChange={(e) => this.handleInstitutionStatus(e)}
              />
              <span className="slider round"></span>
            </label>
          </div>
        );
      } else {
        return(<div></div>)
      }
    }

    return (
      <div className="row InstitutionsHeaderCards">
        <div className="col-6 border-right">
          <div className="col-12">
            <i className="fas fa-university"></i>
          </div>
          <div className="col-12">{this.props.programs.length}</div>
          <div className="col-12 subtitle">{ this.props.t('programs') }</div>
        </div>
        <div className="col-6 border-right">
          <div className="col-12">
            <i className="fas fa-user"></i>
          </div>
          <div className="col-12">{this.props.users?this.props.users.length:1}</div>
          <div className="col-12 subtitle">{ this.props.t('users') }</div>
        </div>
      </div>
    );
  }
}

export default withTranslation('Generals')(InstitutionsHeaderCards);
