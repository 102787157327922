// Dependencies
import React, { Component } from "react";
import axios from "axios";

// Assets
import logo from '../../Assets/logo.png';
import appStore from '../../Assets/app-store-badge.jpg';
import playStore from '../../Assets/google-play-badge.jpg';
import webapp from '../../Assets/webapp_b.jpg';


class Download extends Component {


    render() {
        return (
            <div className="Container loginBG">
                <img src={logo} className="App-logo" alt="logo" />
                <div className="row">
                    <div className="col-1 col-md-4"></div>
                    <div className="col-10 col-md-4">
                        <div className="card login-card">
                            <div className="card-body">
                                <div className="card-title gray">Invite confirmed</div>
                                <div className="container">
                                    <p style={{ color: "gray" , fontSize:"20px"}}>  
                                        You can start using the app now!
                                    </p>
                                    <hr />
                                    <h2 className="gray my-3">
                                        Still don't have it?
                                    </h2>
                                    <div className="row w-100">
                                        <div className="col-12 col-sm-4 mt-2"><a href="https://play.google.com/store/apps/details?id=mentorme.app" target="_blank"><img src={playStore} width="100%"/></a></div>
                                        <div className="col-12 col-sm-4 mt-2"><a href="https://apps.apple.com/gt/app/mentorme/id1464320491" target="_blank"><img src={appStore} width="100%" /></a></div>
                                        <div className="col-12 col-sm-4 mt-2"><a href="https://app.mentormeconnect.com" target="_blank"><img src={webapp} width="100%" /></a></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-1 col-md-4"></div>
                </div>
            </div>
        )
    }
}

export default Download;
