//Dependencies
import React, { Component } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { withTranslation } from "react-i18next";

// Server URL
import serverURL from "../../serverURL";
var direccion = serverURL;

class AdminCards extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adminStatus: this.props.adminStatus,
      email: this.props.email,
    };
  }

  hadleAdminStatus(e) {
    const name = e.target.name;
    const value = e.target.checked;
    this.setState({ [name]: value });
    if (this.state.adminStatus === false) {
      Swal.fire({
        title: "Are you sure?",
        text: "You will enable " + this.state.email + " as admin",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          axios
            .put(
              direccion +
                "api/institution/enableAdmin/" +
                this.props.institutionId,
              {
                toMail: this.state.email,
              },
              {
                headers: {
                  Authorization:
                    "Bearer " + localStorage.getItem("UserSession"),
                  
                },
              }
            )
            .then((response) => {
              Swal.fire({
                position: "bottom-end",
                icon: "success",
                title: "Admin enabled",
                showConfirmButton: false,
                timer: 1000,
                backdrop: false,
                timerProgressBar: true,
              });
            })
            .catch((error) => {
              Swal.fire({
                position: "bottom-end",
                icon: "error",
                title: "Something went wrong",
                text: error.data.stack,
                showConfirmButton: false,
                timer: 1000,
                backdrop: false,
                timerProgressBar: true,
              });
            });
        } else {
          this.setState({ adminStatus: false });
        }
      });
    } else if (this.state.adminStatus === true) {
      Swal.fire({
        title: "Are you sure?",
        text: "You will disable " + this.state.email + " as admin",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          axios
            .put(
              direccion +
                "api/institution/disableAdmin/" +
                this.props.institutionId,
              {
                toMail: this.state.email,
              },
              {
                headers: {
                  Authorization:
                    "Bearer " + localStorage.getItem("UserSession"),
                  
                },
              }
            )
            .then((response) => {
              localStorage.setItem("response data", JSON.stringify(response));
              Swal.fire({
                position: "bottom-end",
                icon: "success",
                title: "Admin disabled",
                showConfirmButton: false,
                timer: 1000,
                backdrop: false,
                timerProgressBar: true,
              });
            })
            .catch((error) => {
              Swal.fire({
                position: "bottom-end",
                icon: "error",
                title: "Something went wrong",
                text: error.data.stack,
                showConfirmButton: false,
                timer: 1000,
                backdrop: false,
                timerProgressBar: true,
              });
            });
        } else {
          this.setState({ adminStatus: true });
        }
      });
    }
  }

  onDeleteAdmin(e) {
    e.preventDefault();
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        axios
          .put(
            direccion +
              "api/institution/deleteAdmin/" +
              this.props.institutionId,
            {
              toMail: this.state.email,
            },
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("UserSession"),
                
              },
            }
          )
          .then((response) => {
            localStorage.setItem(
              "selectedInstitution",
              JSON.stringify(response.data.institutions[0])
            );
            Swal.fire({
              title: "Success",
              icon: "success",
              text: this.state.email + " was removed",
            }).then((result) => {
              if (result.value) {
                window.location.reload();
              }
            });
          })
          .catch((error) => {
            Swal.fire({ title: "Error", icon: "error" });
          });
      }
    });
  }

  render() {
    const Print = () => {
      if (this.props.confirmationDate === undefined) {
        return (
          <span
            className="col-3 exclamationAlert h-2"
            data-toggle="tooltip"
            data-placement="right"
            title="Invite not confirmed yet"
          >
            <i className="fas fa-exclamation "></i>
          </span>
        );
      } else {
        return null;
      }
    };

    return (
      <div className="col-12 AdminCard">
        <div className="row">
          <div className="col-7 text-left">
            <strong>{this.props.t("institutions.admins.admin_mail")}</strong>
          </div>
          <div className="col-3 text-center">
            <strong>{this.props.t("institutions.admins.enable_disable")}</strong>
          </div>
          <div className="col-2 text-cebter">
            <strong>{this.props.t("institutions.admins.delete_admin")}</strong>
          </div>
        </div>
        <div className="row pt-2">
          <div className="col-7 text-left">
            {" " + this.props.email}
            <Print />
          </div>
          <div className="col-3 text-center">
            <label className="switch">
              <input
                name="adminStatus"
                type="checkbox"
                checked={this.state.adminStatus}
                onChange={(e) => this.hadleAdminStatus(e)}
              />
              <span className="slider round"></span>
            </label>
          </div>
          <div className="col-2 text-center">
            <button
              className="btn btn-sm btn-outline-danger ml-2 px-1 py-0"
              onClick={(e) => this.onDeleteAdmin(e)}
            >
              <i className="fas fa-trash-alt"></i>
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation("Generals")(AdminCards);
