// Dependencies
import React, { Component } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

// CSS
import "./SideBar.css";

// Assets
import logo2 from "../../Assets/logo2.png";
import { withTranslation } from "react-i18next";

class SideBar extends Component {
  static propTypes = {
    items: PropTypes.array.isRequired,
  };

  render() {
    const PrintLinks = () => {
      if (this.props.userType === "user") {
        return (
          <ul className="list-unstyled components">
            <li>
              <NavLink
                to="/dashboard"
                className="Raleway"
                activeClassName="NavActive"
              >
                <i className="fas fa-tachometer-alt"></i>&nbsp;&nbsp;
                {this.props.t("dashboard")}
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/institutions"
                className="Raleway"
                activeClassName="NavActive"
              >
                <i className="fas fa-university"></i>&nbsp;&nbsp;
                {this.props.t("institutions.institutions")}
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/programs"
                className="Raleway"
                activeClassName="NavActive"
              >
                <i className="fas fa-tasks"></i>&nbsp;&nbsp;
                {this.props.t("programs")}
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/analytics/user-activity"
                className="Raleway"
                activeClassName="NavActive"
              >
                <i className="far fa-chart-bar"></i>&nbsp;&nbsp;
                {this.props.t("analytics.analytics")}
              </NavLink>
            </li>
          </ul>
        );
      } else {
        return (
          <ul className="list-unstyled components">
            <li>
              <NavLink
                to="/dashboard"
                className="Raleway"
                activeClassName="NavActive"
              >
                <i className="fas fa-tachometer-alt"></i>&nbsp;&nbsp;
                {this.props.t("dashboard")}
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/institutions"
                className="Raleway"
                activeClassName="NavActive"
              >
                <i className="fas fa-university"></i>&nbsp;&nbsp;
                {this.props.t("institutions.institutions")}
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/programs"
                className="Raleway"
                activeClassName="NavActive"
              >
                <i className="fas fa-tasks"></i>&nbsp;&nbsp;
                {this.props.t("programs")}
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/users"
                className="Raleway"
                activeClassName="NavActive"
              >
                <i className="fas fa-user-tie"></i>&nbsp;&nbsp;
                {this.props.t("users")}
              </NavLink>
            </li>
          </ul>
        );
      }
    };

    return (
      <div>
        <nav id="sidebar" className="sidebarNav">
          <div className="sidebar-header">
            <img src={logo2} alt="logo" />
          </div>
          <div className="sidebar-body">
            <div className="row ">
              <PrintLinks />
            </div>
            <div className="row bottom">
              {/*<div className="col-6">
                          <button className="settings Raleway"  onClick={()=> {}}><i className="fas fa-cogs"></i>&nbsp;&nbsp;Settings</button>
                    </div>*/}
              <div className="col-12 text-left">
                <hr />
                <button
                  className="logout ml-4"
                  onClick={() => {
                    window.open("/login", "_self");
                    localStorage.clear();
                  }}
                >
                  <i className="fas fa-power-off"></i>&nbsp;&nbsp;{this.props.t('logout')}
                </button>

                <hr />
              </div>
            </div>
          </div>
        </nav>
      </div>
    );
  }
}

export default withTranslation("Generals")(SideBar);
