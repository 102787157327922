// Dependencies
import React, {Component} from 'react';
import axios from "axios";

// Components
import ProgramsCards from '../Programs/ProgramsCards';

// Server URL
import serverURL from "../../serverURL";
import { withTranslation } from 'react-i18next';
var direccion = serverURL;

class InstitutionalPrograms extends Component {

  constructor(props) {
    super(props);
    this.state = {}
  }

  openProgram(id){
    axios
    .get(
      direccion +
        "api/program/getProgramByID/" + id,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("UserSession"),
          
        }
      }
    )
      .then(response => {
        localStorage.setItem("selectedProgram", JSON.stringify(response.data.programs));
        window.open("/programs/details/summary", "_self");
      })
    .catch(error => {
    });
  }

  render() {
    const ProgramInfo = JSON.parse(localStorage.getItem('InstitutionsPrograms'));
    const Print = () => {
      return ProgramInfo.map((result, index, array) =><div className="py-2"  onClick={(e) => this.openProgram(result._id)}><ProgramsCards
        key={index}
        name={result.name}
        institutionName={result.institutionName}
        startDate={result
        .startDate
        .substring(0, 10)}
        endDate={result
        .endDate
        .substring(0, 10)}/></div>)
    }

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 text-right mt-2">
            <a
              className="AddNew pr-2"
              replace="true"
              href={"/programs/addnew/" + this.props.institutionName + "/" + this.props._id}>
              <i className="fas fa-plus-circle"></i>
              <span className="AddText">&nbsp;&nbsp;{this.props.t('add_new')}</span>
            </a>
          </div>
        </div>

        <Print/>
      </div>

    )
  }
}

export default withTranslation('Generals')(InstitutionalPrograms);