// Dependencies
import React, { Component } from "react";
import PropTypes from "prop-types";

// Components
import TopBar from "./NavBar/TopBar";
import Content from "./Content";
import SideBar from "./NavBar/SideBar.js";

// Data
import menuV from "./NavBar/SideBarData";
import menuV2 from "./NavBar/SideBarData2";
import axios from "axios";

class Main extends Component {
  static propTypes = {
    children: PropTypes.object.isRequired,
  };

  dynamicMenu() {
    var user;
    var userRole;
    if ("UserSession" in localStorage) {
      user = JSON.parse(localStorage.getItem("User"));
      userRole = user.role;
      if (userRole === "user") {
        return "user";
      } else {
        return "admin";
      }
    }
  }


  render() {
    var user;
    var userName;

    if ("UserSession" in localStorage) {
      user = JSON.parse(localStorage.getItem("User"));
      userName = user.userName;
    }

    const { children } = this.props;

    return (
      <div className="">
        <SideBar userType={this.dynamicMenu()} />

        <div id="MainContent">
          <TopBar UserName={userName} />
          <Content body={children} />
        </div>
      </div>
    );
  }
}

export default Main;
