// Dependencies
import React, { Component } from "react";
import axios from "axios";
import Swal from "sweetalert2";

// Server URL
import serverURL from "../../serverURL";
var direccion = serverURL;

class AddTranslation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentProgram: JSON.parse(localStorage.getItem("selectedProgram")),
      language: "",
      todayDate: "",
      startDate: "",
      endDate: "",
      name: this.props.name,
      institutionName:this.props.institutionName,
      description: this.props.description,
      responsabilities: this.props.responsabilities,
      links:this.props.links,
      goals: this.props.goals,
    };
  }

  hadleUserInput(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value });
  }

  handleButtonAnimation(e) {
    var position = e.target.className.toString();
    if (position.includes("collapsed") === true) {
      e.target.className =
        "btn btn-sm btn-primary w-100 fas fa-chevron-up collapsed h-100";
    } else {
      e.target.className =
        "btn btn-sm btn-primary w-100 fas fa-chevron-down h-100";
    }
  }

  changeResponsabilitie(e, index, currentValue) {
    this.state.responsabilities[index][e.target.name] =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    this.setState({ responsabilities: this.state.responsabilities });
  }

  changeGoal(e, index, currentValue) {
    this.state.goals[index][e.target.name] =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    this.setState({ goals: this.state.goals });
  }

  changeTask(e, index, currentValue, taskIndex) {
    this.state.goals[index].tasks[taskIndex][e.target.name] =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    this.setState({ goals: this.state.goals });
  }

  changeLink(e, index, currentValue) {
    this.state.links[index][e.target.name] = e.target.value;
    this.setState({ links: this.state.links });
  }

  changeGoalLink(e, index, currentValue, linkIndex) {
    let currentState = this.state;
    currentState.goals[index].links[linkIndex][e.target.name] = e.target.value;
    this.setState(currentState);
  }

  cancelHandler(e) {
    e.preventDefault();
    Swal.fire({
      title: "Are you sure you want to leave?",
      text: "The information will be lost",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        window.open("/programs/summary", "_self");
      }
    });
  }

  submitHandler(e) {
    e.preventDefault();
    e.target.className += " was-validated";
    var object = {
      name: this.state.name,
      description: this.state.description,
      language: this.state.language,
      institutionName: this.state.institutionName,
      responsabilities: this.state.responsabilities,
      links: this.state.links,
      goals: this.state.goals
    };

    axios
      .post(
        direccion +
          "api/institutionalAdmin/addProgramTranslation/" +
          this.props._id,
        object,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("UserSession"),
            
          },
        }
      )
      .then((response) => {
        localStorage.setItem(
          "selectedProgram",
          JSON.stringify(response.data.programs)
        );
        Swal.fire({
          title: "Success",
          icon: "success",
          text: "Translation created",
        }).then((result) => {
          if (result.value) {
            window.open("/programs/details/summary", "_self");
          }
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.stack[1],
          icon: "error",
        });
      });
  }

  render() {
    return (
      <div className="container my-3">
        <h4>Add Institution Translation</h4>
        <form onSubmit={(e) => this.submitHandler(e)} autocomplete="off">
          <div className="row text-left">
            <div className="col-10  mt-4">
              Program Name
              <input
                type="text"
                className="mt-2 form-control"
                name="name"
                value={this.state.name}
                onChange={(event) => this.hadleUserInput(event)}
                placeholder={this.state.currentProgram.name}
                required
              />
            </div>
            <div className="col-2 mt-4 text-center">
              Language
              <br />
              <select
                className="selectP"
                name="language"
                id=""
                onChange={(event) => this.hadleUserInput(event)}
                value={this.state.language}
              >
                <option value="es">ES</option>
                <option value="en">EN</option>
                <option value="fr">FR</option>
                <option value="de">DE</option>
                <option value="pt">PT</option>
              </select>
            </div>
            <div className="col-12 mt-4">
              Program description
              <span className="font-italic"> (Max 150 characters) </span>
              <input
                type="text"
                className="mt-2 form-control"
                name="description"
                value={this.state.description}
                placeholder={this.state.currentProgram.description}
                maxLength={150}
                onChange={(event) => this.hadleUserInput(event)}
                required
              />
            </div>

            <div className="col-12 mt-4">
              <div className="row">
                <div className="col-12 p-0 mt-4">Goals</div>
                <div className="col-12  mt-2 p-0">
                  <div id="accordion">
                    {this.state.goals.map((currentValue, index, array) => {
                      return (
                        <div className="card" key={index}>
                          <div className="card-header" id={"heading-" + index}>
                            <div className="row">
                              <div className="col-10 text-left">
                                <div className="row">
                                  <div className="col">Goal subject:</div>
                                  <div className="col-10">
                                    <input
                                      className="AddNewTitleInput form-control"
                                      name="subject"
                                      value={currentValue.subject}
                                      placeholder={this.state.currentProgram.goals[index].subject}
                                      onChange={(e) =>
                                        this.changeGoal(e, index, currentValue)
                                      }
                                      required
                                    ></input>
                                  </div>
                                </div>
                              </div>
                              <div className="col">
                                <button
                                  type="button"
                                  onClick={(e) => this.handleButtonAnimation(e)}
                                  className="btn btn btn-primary w-100 fas fa-chevron-down"
                                  data-toggle="collapse"
                                  data-target={"#collapse" + index}
                                  aria-expanded="true"
                                  aria-controls={"collapse" + index}
                                ></button>
                              </div>
                            </div>
                          </div>

                          <div
                            id={"collapse" + index}
                            className="collapse show"
                            aria-labelledby="headingOne"
                            data-parent="#accordion"
                          >
                            <div className="card-body">
                              <div className="row">
                                <div className="col-12">
                                  Goal description:
                                  <input
                                    className="AddNewTitleInput form-control"
                                    name="description"
                                    maxLength={150}
                                    value={currentValue.description}
                                    placeholder={this.state.currentProgram.goals[index].description}
                                    onChange={(e) =>
                                      this.changeGoal(e, index, currentValue)
                                    }
                                  ></input>
                                </div>

                                <div className="col-12 mt-3">Goal Tasks:</div>
                                <div className="col-12  mt-2 p-0">
                                  <div id="tasksAccordion">
                                    {this.state.goals[index].tasks.map(
                                      (currentValue, taskIndex, array) => {
                                        return (
                                          <div className="card" key={taskIndex}>
                                            <div
                                              className="card-header"
                                              id={"heading-" + taskIndex}
                                            >
                                              <div className="row">
                                                <div className="col-9 text-left ">
                                                  {this.state.goals[index]
                                                    .subject + " "}
                                                  task No.{taskIndex + 1}
                                                </div>
                                                <div className="col-3">
                                                  <button
                                                    type="button"
                                                    className="btn btn-sm btn-primary w-100 "
                                                    data-toggle="collapse"
                                                    data-target={
                                                      "#collapseTasks" +
                                                      taskIndex
                                                    }
                                                    aria-expanded="true"
                                                    aria-controls={
                                                      "collapseTasks" +
                                                      taskIndex
                                                    }
                                                  >
                                                    See / hide more
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                            <div
                                              id={"collapseTasks" + taskIndex}
                                              className="collapse show"
                                              data-parent="#tasksAccordion"
                                            >
                                              <div className="card-body">
                                                <div className="row">
                                                  <div className="col-12 mt-3">
                                                    Task description:
                                                    <input
                                                      className="AddNewTitleInput form-control"
                                                      name="description"
                                                      placeholder = {
                                                        this.state.currentProgram.goals[index].tasks[taskIndex].description
                                                      }
                                                      value={
                                                        currentValue.description
                                                      }
                                                      maxLength={150}
                                                      onChange={(e) =>
                                                        this.changeTask(
                                                          e,
                                                          index,
                                                          currentValue,
                                                          taskIndex
                                                        )
                                                      }
                                                      required
                                                    ></input>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                </div>
                                <div className="col-12 mt-3">Goal Links:</div>
                                <div className="col-12 addNewContainer mt-2 p-3">
                                  <div className="row addNewHeaders">
                                    <div className="col-5">Title</div>
                                    <div className="col-7 text-center">URL</div>
                                  </div>
                                  {this.state.goals[index].links.map(
                                    (currentValue, linkIndex, array) => {
                                      return (
                                        <div className="row py-2" key={index}>
                                          <div className="col-5">
                                            <input
                                              className="AddNewTitleInput form-control"
                                              name="title"
                                              placeholder = {
                                                this.state.currentProgram.goals[index].links[linkIndex].title
                                              }
                                              value={currentValue.title}
                                              onChange={(e) =>
                                                this.changeGoalLink(
                                                  e,
                                                  index,
                                                  currentValue,
                                                  linkIndex
                                                )
                                              }
                                              required
                                            ></input>
                                          </div>
                                          <div className="col-7 text-center">
                                            <input
                                              type="url"
                                              pattern="https?://.+"
                                              className="AddNewTitleInput form-control"
                                              name="address"
                                              value={this.state.currentProgram.goals[index].links[linkIndex].address}
                                              disabled
                                              required
                                            ></input>
                                          </div>
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>

                <div className="col-12 p-0 mt-4">Responsabilities</div>
                <div className="col-12 addNewContainer mt-2 p-3">
                  <div className="row addNewHeaders">
                    <div className="col-12">Title</div>
                  </div>
                  {this.state.responsabilities.map(
                    (currentValue, index, array) => {
                      return (
                        <div className="row py-2" key={index}>
                          <div className="col-12">
                            <input
                              className="AddNewTitleInput  form-control"
                              name = "title"
                              placeholder = {
                                this.state.currentProgram.responsabilities[index].title
                              }
                              value={currentValue.title}
                              onChange={(e) =>
                                this.changeResponsabilitie(
                                  e,
                                  index,
                                  currentValue
                                )
                              }
                              required
                            ></input>
                          </div>
                        </div>
                      );
                    }
                  )}
                </div>
                <div className="col-12 p-0 mt-4">Links</div>
                <div className="col-12 addNewContainer mt-2 p-3">
                  <div className="row addNewHeaders">
                    <div className="col-5">Title</div>
                    <div className="col-7 text-center">URL</div>
                  </div>
                  {this.state.links.map((currentValue, index, array) => {
                    return (
                      <div className="row py-2" key={index}>
                        <div className="col-5">
                          <input
                            className="AddNewTitleInput form-control"
                            name="title"
                            value={currentValue.title}
                            onChange={(e) =>
                              this.changeLink(e, index, currentValue)
                            }
                            required
                          ></input>
                        </div>
                        <div className="col-7 text-center">
                          <input
                            type="url"
                            pattern="https?://.+"
                            className="AddNewTitleInput form-control"
                            name="address"
                            value={currentValue.address}
                            disabled
                            required
                          ></input>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>

          <div className="row float-right">
            <div className="col-12 my-4">
              <input
                type="reset"
                value="Cancel"
                className="btn btn-outline-danger mr-2"
                onClick={(e) => this.cancelHandler(e)}
              />
              <input type="submit" className="btn btn-primary" value="Save" />
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default AddTranslation;
