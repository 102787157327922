// Dependencies
import React, { Component } from "react";
import {
  NavLink,
  Switch,
  Route,
  BrowserRouter,
  Redirect,
} from "react-router-dom";
import axios from "axios";
import {
  withTranslation
} from "react-i18next";

// Components
import InstitutionsHeaderCards from "./InstitutionsHeaderCards";
import InstitutionProfile from "./InstitutionProfile";
import InstitutionalPrograms from "./InstitutionalPrograms";
import InstitutionalUsers from "./InstitutionalUsers";
import AddNewAdmin from "./AddNewAdmin";
import AddInterests from "./AddInterests";
import SearchBar from "../Bars/SearchBar.js";
import InstitutionsCards from "./InstitutionsCards.js";
import AddUsers from "./AddUsers";
import EditInstitution from "./EditInstitution";
import AddTranslation from "./AddTranslation";

// Server URL
import serverURL from "../../serverURL";
var direccion = serverURL;

class InstitutionDetails extends Component {
  constructor(props) {
    var userRole = JSON.parse(localStorage.getItem("User"));
    super(props);
    this.state = {
      currentInstitution: [
        JSON.parse(localStorage.getItem("selectedInstitution")),
      ],
      userRole: userRole.role,
      institutions: JSON.parse(localStorage.getItem("Institutions")),
    };
  }

  selectedCard(institution) {
    localStorage.setItem("selectedInstitution", JSON.stringify(institution));
    localStorage.setItem("InstitutionsUsers", JSON.stringify(institution.users));
    localStorage.setItem("InstitutionsUsersLength", JSON.stringify(institution.usersLength));

    axios
      .post(direccion + "api/institution/getTranslation/" + institution._id, {}, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("UserSession"),
          'Access-Control-Allow-Origin': "*"
        },
      })
      .then((response) => {
        localStorage.setItem(
          "institutionTranslations",
          JSON.stringify(response.data.translations)
        );
      });
    
    axios
      .post(
        direccion + "api/institution/getAllPrograms/" + institution._id,
        {
          isProgramTemplate: true,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("UserSession"),
            
          },
        }
      )
      .then((response) => {
        localStorage.setItem(
          "InstitutionsPrograms",
          JSON.stringify(response.data.programs)
        );
        window.location.reload();
      })
      .catch((error) => {});
  }

  render() {
    if (this.state.currentInstitution[0].institutionReferenceId) {
      this.state.display = "none";
    } 
    const Print = () => {
      if (this.state.userRole === "sysadmin") {
        return (
          <div>
            <SearchBar
              AddNewLink="/institutions/addnew"
              SearchType="institution"
              cardViewCallback={this.selectedCard}
            />
          </div>
        );
      } else {
        return (
          <div className="">
            <div className="navbar navbar-dark SearchBar text-left row ">
              <div className="col-5">
                <button
                  className="btn btn-outline-primary btn-sm px-2 py-0"
                  data-toggle="collapse"
                  data-target="#multiCollapseExample1"
                  aria-expanded="false"
                  aria-controls="multiCollapseExample1"
                >
                  {this.props.t("show_institutions")}
                </button>
              </div>
            </div>
            {this.state.institutions.map((result, index, array) => (
              <div
                onClick={(e) => this.selectedCard(array[index])}
                id="multiCollapseExample1"
                className="collapse multi-collapse"
                key={index}
              >
                <InstitutionsCards
                  InstitutionName={result.institutionName}
                  programs={result.programs}
                />
              </div>
            ))}
          </div>
        );
      }
    };

    return (
      <div>
        <Print />
        <BrowserRouter>
          <div
            style={{
              display: `${this.state.display}`,
            }}
          >
            <InstitutionsHeaderCards
              style={{
                display: `${this.state.display}`,
              }}
              users={this.state.currentInstitution[0].users}
              _id={this.state.currentInstitution[0]._id}
              institutionName={this.state.currentInstitution[0].institutionName}
              programs={this.state.currentInstitution[0].programs}
              isActive={this.state.currentInstitution[0].isActive}
            />
          </div>
          <div className="SubMenu">
            <ul className="row">
              <NavLink to="/institutions/details/profile" className="col">
                <li>{this.props.t("institution_profile")}</li>
              </NavLink>
              <NavLink
                style={{
                  display: `${this.state.display}`,
                }}
                to="/institutions/details/programs"
                className="col-4"
              >
                <li>{this.props.t("institutional_programs")}</li>
              </NavLink>
              <NavLink
                style={{
                  display: `${this.state.display}`,
                }}
                to="/institutions/details/users"
                className="col-4"
              >
                <li>{this.props.t("institutional_users")}</li>
              </NavLink>
            </ul>
          </div>

          <Switch>
            <Route
              path="/institutions/details/profile"
              render={(props) => (
                <InstitutionProfile
                  {...props}
                  _id={this.state.currentInstitution[0]._id}
                  institutionName={
                    this.state.currentInstitution[0].institutionName
                  }
                  status={this.state.currentInstitution[0].status}
                  admins={this.state.currentInstitution[0].admins}
                  contact={this.state.currentInstitution[0].contact}
                  createdOn={this.state.currentInstitution[0].createdOn}
                  city={this.state.currentInstitution[0].city}
                  country={this.state.currentInstitution[0].country}
                  description={this.state.currentInstitution[0].description}
                  phone={this.state.currentInstitution[0].phone}
                  email={this.state.currentInstitution[0].email}
                  programs={this.state.currentInstitution[0].programs}
                  state={this.state.currentInstitution[0].state}
                  streetAddress={this.state.currentInstitution[0].streetAddress}
                  users={this.state.currentInstitution[0].users}
                  zip={this.state.currentInstitution[0].zip}
                  webPageUrl={this.state.currentInstitution[0].webPageUrl}
                  professionalInterests={
                    this.state.currentInstitution[0].professionalInterests
                  }
                  image={this.state.currentInstitution[0].image}
                  weightPersonalInterests={
                    this.state.currentInstitution[0].weightPersonalInterests
                  }
                  language={this.state.currentInstitution[0].language}
                  institutionReferenceId={
                    this.state.currentInstitution[0].institutionReferenceId
                  }
                />
              )}
            />
            <Route
              path="/institutions/details/programs"
              render={(props) => (
                <InstitutionalPrograms
                  {...props}
                  institutionName={
                    this.state.currentInstitution[0].institutionName
                  }
                  _id={this.state.currentInstitution[0]._id}
                  programs={this.state.currentInstitution[0].programs}
                />
              )}
            />
            <Route
              path="/institutions/details/users"
              render={(props) => (
                <InstitutionalUsers
                  {...props}
                  users={this.state.currentInstitution[0].users}
                />
              )}
            />
            <Route
              path="/institutions/details/newadmin"
              component={AddNewAdmin}
            ></Route>
            <Route
              path="/institutions/details/newinterests"
              component={AddInterests}
            ></Route>
            <Route
              path="/institutions/details/newusers"
              component={AddUsers}
            ></Route>
            <Route
              path="/institutions/details/edit"
              component={EditInstitution}
            ></Route>
            <Route
              path="/institutions/details/addtranslation"
              render={(props) => (
                < AddTranslation
                  {...props}
                  professionalInterests={this.state.currentInstitution[0].professionalInterests}
                  institutionName={this.state.currentInstitution[0].institutionName}
                  description={this.state.currentInstitution[0].description}
                />)}
            />
            <Redirect from="/institutions" to="/institutions/details/profile" />
          </Switch>
        </BrowserRouter>
      </div>
    );
  }
}

export default withTranslation("Generals")(InstitutionDetails);
