// Dependencies
import React, { Component } from "react";
import {
  NavLink,
  Switch,
  Route,
  BrowserRouter,
  Redirect,
} from "react-router-dom";
import axios from "axios";

// Components
import ProgramsSummary from "./ProgramsSummary";
import ProgramsGoalsTasks from "./ProgramsGoalsTasks";
import ProgramsLinks from "./ProgramsLinks";
import ProgramsUsers from "./ProgramsUsers";
import ProgramsMatches from "./ProgramsMatches";
import EditResponsabilities from "./EditResponsabilities";
import EditGoals from "./EditGoals";
import EditLinks from "./EditLinks";
import EditPrograms from "./EditPrograms";
import ProgramsRequests from "./ProgramsRequests";
import AddMatch from "./AddMatch";
import SearchBar from "../Bars/SearchBar.js";
import ProgramsCards from "../Programs/ProgramsCards";
import AddTranslation from "./AddTranslation";

// Server URL
import serverURL from "../../serverURL";
import AddUsers from "./AddUsers";
import MassiveMatch from "./MassiveMatch";
import { withTranslation } from "react-i18next";
var direccion = serverURL;

class ProgramDetails extends Component {
  constructor(props) {
    var userRole = JSON.parse(localStorage.getItem("User"));
    super(props);
    this.state = {
      currentProgram: JSON.parse(localStorage.getItem("selectedProgram")),
      userRole: [userRole.role],
      programs: JSON.parse(localStorage.getItem("Programs")),
    };
  }

  selectedCard(program) {
    if (this.state.userRole === "sysadmin") {
      localStorage.setItem("selectedProgram", JSON.stringify(program));
      axios
        .post(direccion + "api/program/getTranslation/" + program._id, {}, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("UserSession"),
            'Access-Control-Allow-Origin': "*"
          },
        })
        .then((response) => {
          localStorage.setItem(
            "programTranslations",
            JSON.stringify(response.data)
          );
        });
    } else {
      axios
        .post(direccion + "api/program/getTranslation/" + program._id, {}, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("UserSession"),
            'Access-Control-Allow-Origin': "*"
          },
        })
        .then((response) => {
          localStorage.setItem(
            "programTranslations",
            JSON.stringify(response.data)
          );
          axios
            .get(direccion + "api/program/getProgramByID/" + program._id , {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("UserSession"),
                
              },
            })
            .then((response) => {
              localStorage.setItem(
                "selectedProgram",
                JSON.stringify(response.data.programs)
              );
              localStorage.setItem(
                "programsResult",
                JSON.stringify(response.data.programs)
              );
              window.open("/programs/details/summary", "_self");
            })
            .catch((error) => {});
        });
    }

    this.setState({ show: "", expanded: "false" });
  }

  render() {
    if (this.state.currentProgram.isTranslation == true) {
      this.state.display = "none";
    }
    const Print = () => {
      if (this.state.userRole == "sysadmin") {
        return (
          <div>
            <SearchBar
              AddNewLink="/programs/addnew"
              SearchType="program"
              cardViewCallback={this.selectedCard}
              hide="d-none"
            />
          </div>
        );
      } else {
        return (
          <div className="">
            <div className="navbar navbar-dark SearchBar text-left row">
              <div className="col-5">
                <button
                  className="btn btn-outline-primary btn-sm px-2 py-0"
                  data-toggle="collapse"
                  data-target="#multiCollapseExample1"
                  aria-expanded="false"
                  aria-controls="multiCollapseExample1"
                >
                  {this.props.t('show_programs')}
                </button>
              </div>
            </div>
            {this.state.programs.map((result, index, array) => (
              <div
                key={index}
                onClick={(e) => this.selectedCard(array[index])}
                id="multiCollapseExample1"
                className="collapse multi-collapse"
              >
                <ProgramsCards
                  name={result.name}
                  institutionName={result.institutionName}
                  startDate={result.startDate.substring(0, 10)}
                  endDate={result.endDate.substring(0, 10)}
                />
              </div>
            ))}
          </div>
        );
      }
    };
    return (
      <div>
        <Print />
        <BrowserRouter>
          <div className="SubMenu bt-purple">
            <ul className="row">
              <NavLink to="/programs/details/summary" className="col">
                <li>{this.props.t('program_summary') }</li>
              </NavLink>
              <NavLink to="/programs/details/goalstasks" className="col">
                <li>{this.props.t('goals_and_tasks') }</li>
              </NavLink>
              <NavLink to="/programs/details/links" className="col">
                <li>{this.props.t('links') }</li>
              </NavLink>
              <NavLink
                style={{
                  display: `${this.state.display}`,
                }}
                to="/programs/details/users"
                className="col"
              >
                <li>{this.props.t('enrolled_users') }</li>
              </NavLink>
              {/*
              Waiting for workflow design... available until 1.1
              <NavLink to="/programs/details/requests" className="col-2">
                <li>Requests</li>
              < /NavLink>*/}
              <NavLink
                style={{
                  display: `${this.state.display}`,
                }}
                to="/programs/details/matches"
                className="col"
              >
                <li>{this.props.t('matches') }</li>
              </NavLink>
            </ul>
          </div>

          <Switch>
            <Route
              exact
              path="/programs/details/summary"
              render={(props) => (
                <ProgramsSummary
                  {...props}
                  name={this.state.currentProgram.name}
                  startDate={this.state.currentProgram.startDate}
                  endDate={this.state.currentProgram.endDate}
                  institutionName={this.state.currentProgram.institutionName}
                  description={this.state.currentProgram.description}
                  responsabilities={this.state.currentProgram.responsabilities}
                  isTranslation={this.state.currentProgram.isTranslation}
                  language={this.state.currentProgram.language}
                  allowMenteMatchRequest={this.state.currentProgram.allowMenteMatchRequest === undefined? true : this.state.currentProgram.allowMenteMatchRequest}
                  allowMenteeChangeRandR={this.state.currentProgram.allowMenteeChangeRandR === undefined? true : this.state.currentProgram.allowMenteeChangeRandR}
                  allowMentorChangeRandR={this.state.currentProgram.allowMentorChangeRandR === undefined? true : this.state.currentProgram.allowMentorChangeRandR}
                  maxMatchesPerMentor={this.state.currentProgram.maxMatchesPerMentor === undefined? 1 : this.state.currentProgram.maxMatchesPerMentor}
                  allowMenteeReopenProgram={this.state.currentProgram.allowMenteeReopenProgram === undefined? true : this.state.currentProgram.allowMenteeReopenProgram}
                  allowMentorReopenProgram={this.state.currentProgram.allowMentorReopenProgram === undefined? true : this.state.currentProgram.allowMentorReopenProgram}
                  allowMenteeAddNewGoals={this.state.currentProgram.allowMenteeAddNewGoals === undefined? true : this.state.currentProgram.allowMenteeAddNewGoals}
                  allowMentorAddNewGoals={this.state.currentProgram.allowMentorAddNewGoals === undefined? true : this.state.currentProgram.allowMentorAddNewGoals}
                />
              )}
            />
            <Route
              path="/programs/details/goalstasks"
              render={(props) => (
                <ProgramsGoalsTasks
                  {...props}
                  goals={this.state.currentProgram.goals}
                />
              )}
            />
            <Route
              path="/programs/details/links"
              render={(props) => (
                <ProgramsLinks
                  {...props}
                  links={this.state.currentProgram.links}
                />
              )}
            />
            <Route
              path="/programs/details/users"
              render={(props) => (
                <ProgramsUsers
                  {...props}
                  programId={this.state.currentProgram._id}
                />
              )}
            />
            <Route
              path="/programs/details/matches"
              render={(props) => (
                <ProgramsMatches
                  {...props}
                  id={this.state.currentProgram._id}
                />
              )}
            />
            {/*<Route
              path="/programs/details/requests"
              render={(props) => (
                <ProgramsRequests
                  {...props}
                  requestProgramAccess={
                    this.state.currentProgram.requestProgramAccess
                  }
                />
              )}
                />*/}

            <Route
              path="/programs/details/edit/responsabilities"
              render={(props) => <EditResponsabilities {...props} />}
            />
            <Route
              path="/programs/details/edit/summary"
              render={(props) => <EditPrograms {...props} />}
            />
            <Route
              path="/programs/details/edit/goals"
              render={(props) => <EditGoals {...props} />}
            />
            <Route
              path="/programs/details/edit/links"
              render={(props) => <EditLinks {...props} />}
            />
            <Route
              path="/programs/details/add/match"
              render={(props) => <AddMatch {...props} />}
            />
            <Route
              path="/programs/details/add/massive-match"
              render={(props) => <MassiveMatch {...props} />}
            />
            <Route
              path="/programs/details/add/users"
              render={(props) => (
                <AddUsers
                  {...props}
                  institution={this.state.currentProgram.institution}
                  currentUsers={this.state.currentProgram.users}
                  id={this.state.currentProgram._id}
                />
              )}
            />
            <Route
              path="/programs/details/addtranslation"
              render={(props) => (
                <AddTranslation
                  {...props}
                  name={this.state.currentProgram.name}
                  startDate={this.state.currentProgram.startDate}
                  endDate={this.state.currentProgram.endDate}
                  institutionName={this.state.currentProgram.institutionName}
                  description={this.state.currentProgram.description}
                  responsabilities={this.state.currentProgram.responsabilities}
                  links={this.state.currentProgram.links}
                  goals={this.state.currentProgram.goals}
                  _id={this.state.currentProgram._id}
                />
              )}
            />
            <Redirect from="/programs" exact to="/programs/details/summary" />
          </Switch>
        </BrowserRouter>
      </div>
    );
  }
}

export default withTranslation('Generals')(ProgramDetails);
