import React, { Component } from "react";
import axios from "axios";
import readXlsxFile from "read-excel-file";
import Swal from "sweetalert2";

// Assets
import interestsFile from "../../Assets/Templates/intereses.xlsx";

// Server URL
import serverURL from "../../serverURL";
import { withTranslation } from "react-i18next";
var direccion = serverURL;

class AddInterests extends Component {
  constructor(props) {
    var institution = JSON.parse(localStorage.getItem("selectedInstitution"));
    super(props);
    this.state = {
      buttonStatus: true,
      professionalInterests: institution.professionalInterests
        ? institution.professionalInterests
        : [
            {
              interests: [""],
              category: "",
            },
          ],
      file: {},
      fileName: "",
    };
  }

  hadleUserInput(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value });
  }

  changeProfessionalInterest(e, index, currentValue) {
    this.state.professionalInterests[index][e.target.name] = e.target.value;
    this.setState({ professionalInterests: this.state.professionalInterests });
  }

  onChangeHandler = (event) => {
    readXlsxFile(event.target.files[0]).then((data) => {
      this.setState({ file: data });
    });
    this.setState({
      fileName: event.target.files[0].name,
      buttonStatus: false,
    });
  };

  convert(e) {
    e.preventDefault();
    var currentArray = this.state.file;
    var a = currentArray.slice(1, currentArray.length);

    for (let i = 0; i < a.length; i++) {
      var findCategory = this.state.professionalInterests.findIndex(
        (x) => x.category === a[i][1]
      );
      if (findCategory >= 0) {
        var findInterest = this.state.professionalInterests[
          findCategory
        ].interests.find((z) => z === a[i][0]);
        if (!findInterest) {
          this.state.professionalInterests[findCategory].interests.push(
            a[i][0]
          );
        }
      } else {
        this.state.professionalInterests.push({
          category: a[i][1],
          interests: [a[i][0]],
        });
      }
    }

    this.setState({ professionalInterests: this.state.professionalInterests });
  }

  addCategory(e) {
    e.preventDefault();
    this.setState({
      professionalInterests: [
        ...this.state.professionalInterests,
        {
          interests: [],
          category: "",
        },
      ],
    });
  }

  addInterest(e, index) {
    let currentState = this.state;
    currentState.professionalInterests[index].interests = [
      ...currentState.professionalInterests[index].interests,
      "",
    ];

    this.setState(currentState);
  }

  changeCategory(e, index, currentValue) {
    this.state.professionalInterests[index][e.target.name] =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    this.setState({ goals: this.state.goals });
  }

  changeInterest(e, index, interestIndex, currentValue) {
    this.state.professionalInterests[index].interests[interestIndex] =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    this.setState({ goals: this.state.goals });
  }

  onDeleteGoal(e, index) {
    e.preventDefault();
    this.state.professionalInterests.splice([index], 1);
    this.setState({ professionalInterests: this.state.professionalInterests });
  }

  onDeleteInterest(e, index, interestIndex) {
    e.preventDefault();
    this.state.professionalInterests[index].interests.splice(
      [interestIndex],
      1
    );
    this.setState({ professionalInterests: this.state.professionalInterests });
  }

  handleButtonAnimation(e) {
    var position = e.target.className.toString();
    if (position.includes("collapsed") === true) {
      e.target.className =
        "btn btn-sm btn-primary w-100 fas fa-chevron-up collapsed h-100";
    } else {
      e.target.className =
        "btn btn-sm btn-primary w-100 fas fa-chevron-down h-100";
    }
  }

  submitHandler(e) {
    const current_id = this.props.location.pathname.split("/").slice(5)[0];
    e.preventDefault();
    e.target.className += " was-validated";
    var object = {
      professionalInterests: this.state.professionalInterests,
    };
    Swal.fire({
      title: "Alerta",
      text: "Recuerda cambiar los intereses tambien en tus traducciones",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Enterado",
    }).then((result) => {
      if (result.value) {
        axios
          .post(
            direccion +
              "api/institution/updateArrayProfessionalInterests/" +
              current_id,
            object,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("UserSession"),
                
              },
            }
          )
          .then((response) => {
            localStorage.setItem(
              "selectedInstitution",
              JSON.stringify(response.data.institutions[0])
            );
            Swal.fire({
              title: "Success",
              icon: "success",
              text: "Professional interests updated correctly",
            }).then((result) => {
              if (result.value) {
                window.open("/institutions/details/profile", "_self");
              }
            });
          })
          .catch((error) => {
            var errorString = "";
            if (
              error.response.data.stack[3].includes("Duplicate values in array")
            ) {
              errorString = "Can't repeat the same interest";
            }
            Swal.fire({ title: "Error", text: errorString, icon: "error" });
          });
      }
    });
  }

  cancelHandler(e) {
    e.preventDefault();
    Swal.fire({
      title: "Are you sure you want to leave?",
      text: "The information will be lost",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        window.open("/institutions/details/profile", "_self");
      }
    });
  }

  render() {
    return (
      <div className="container mt-4">
        <div className="row text-center">
          <div className="col-12">
            <h3>{this.props.t("edit_interests")}</h3>
          </div>
        </div>

        <div className="col-12 text-left my-2">
          <button
            className="btn btn-outline-primary"
            onClick={(e) => this.addCategory(e)}
          >
            {this.props.t("add") + " " + this.props.t("category")}
          </button>
        </div>

        <form onSubmit={(e) => this.submitHandler(e)}>
          <div id="accordion">
            {this.state.professionalInterests.map(
              (currentValue, index, array) => {
                return (
                  <div className="card my-1" key={index}>
                    <div className="card-header" id={"heading-" + index}>
                      <div className="row">
                        <div className="col-10 text-left ">
                          <div className="row align-middle">
                            <div className="col py-1">
                              {this.props.t("category")}:
                            </div>
                            <div className="col-10">
                              <input
                                className="AddNewTitleInput form-control"
                                name="category"
                                value={currentValue.category}
                                onChange={(e) =>
                                  this.changeCategory(e, index, currentValue)
                                }
                                required
                              ></input>
                            </div>
                          </div>
                        </div>
                        <div className="col">
                          <button
                            type="button"
                            onClick={(e) => this.handleButtonAnimation(e)}
                            className="btn btn-sm btn-primary w-100 fas fa-chevron-down h-100"
                            data-toggle="collapse"
                            data-target={"#collapse" + index}
                            aria-expanded="true"
                            aria-controls={"collapse" + index}
                          ></button>
                        </div>

                        <div className="col">
                          <button
                            className="btn btn-sm btn-danger w-100"
                            onClick={(e) => this.onDeleteGoal(e, index)}
                          >
                            <i className="fas fa-trash" aria-hidden="true"></i>
                          </button>
                        </div>
                      </div>
                    </div>

                    <div
                      id={"collapse" + index}
                      className="collapse show"
                      aria-labelledby="headingOne"
                      data-parent="#accordion"
                    >
                      <div className="card-body text-left">
                        <div className="row ">
                          <div className="col-12 mt-3 justify-content-between d-flex">
                            {this.props.t("interests")}:
                            <button
                              type="button"
                              className="btn btn-sm btn-primary py-0 h6 self-align-right"
                              onClick={(e) => this.addInterest(e, index)}
                            >
                              {this.props.t("add") +
                                " " +
                                this.props.t("interest")}
                            </button>
                          </div>
                          <div className="col-12  mt-2 p-0">
                            <div id="tasksAccordion">
                              {this.state.professionalInterests[
                                index
                              ].interests.map(
                                (currentValue, interestIndex, array) => {
                                  return (
                                    <div className="row py-1">
                                      <div className="col-11">
                                        <input
                                          className="AddNewTitleInput form-control"
                                          name="subject"
                                          value={currentValue}
                                          onChange={(e) =>
                                            this.changeInterest(
                                              e,
                                              index,
                                              interestIndex,
                                              currentValue
                                            )
                                          }
                                          required
                                        ></input>
                                      </div>
                                      <div className="col text-right">
                                        <button
                                          className="btn btn-sm btn-outline-danger"
                                          onClick={(e) =>
                                            this.onDeleteInterest(
                                              e,
                                              index,
                                              interestIndex
                                            )
                                          }
                                        >
                                          <i
                                            className="fa fa-times"
                                            aria-hidden="true"
                                          ></i>
                                        </button>
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
            )}
          </div>

          <div className="row">
            <div className="col-6">
              <div className="my-4 inputArea">
                <label>
                  <b>Step 1: Dowload the template file</b>
                </label>
                <label className="personalizedFile2">
                  <i
                    style={{
                      color: "gray",
                      fontSize: "70px",
                      marginBottom: "5px",
                    }}
                    className="fas fa-file-download"
                  ></i>
                  <br />
                  User template
                </label>
                <a className="m-3 convert" href={interestsFile} download>
                  {this.props.t("download")}
                </a>
              </div>
            </div>
            <div className="col-6">
              <div className="my-4 inputArea">
                <label>
                  <b>Step 2: Upload edited file and then click convert</b>
                </label>
                <div className="excelFile">
                  <input
                    id="file"
                    className="file m-3"
                    type="file"
                    name="file"
                    onChange={this.onChangeHandler}
                  />
                  <span></span>
                  <label className="personalizedFile" for="file">
                    <i className="far fa-file-excel"></i>
                    <br />
                    Choose a file
                  </label>
                </div>

                <button
                  className="m-3 convert"
                  onClick={(e) => this.convert(e)}
                  disabled={this.state.buttonStatus}
                >
                  {this.props.t("convert")} {" " + this.state.fileName}
                </button>
              </div>
            </div>
          </div>

          <div className="row float-right">
            <div className="col-12 mb-4">
              <input
                type="reset"
                value="Cancel"
                className="btn btn-outline-danger mr-2"
                onClick={(e) => this.cancelHandler(e)}
              />
              <input type="submit" className="btn btn-primary" value="Save" />
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default withTranslation("Generals")(AddInterests);
