// Dependencies
import React from 'react';
import {Route, BrowserRouter as Router, Switch} from 'react-router-dom';

// Assets
import './App.css';

// Routes
import AppRoutes from './routes'

// Components
import Login from './components/Login/Login.js'
import ResetPassword from './components/shared/ResetPassword';
import AdminConfirmation from './components/shared/AdminConfirmation';
import UserConfirmation from './components/shared/UserConfirmation';
import AccountConfirmation from './components/shared/AccountConfirmation';
import Download from './components/shared/Download';

function App() {
  return (
    <div className="App">
      <Switch>
        <Route exact path="/login" component={Login}/>
        <Route path="/acceptAdminInvite" component={AdminConfirmation} />
        <Route path="/recoverPassword" component={ResetPassword} />
        <Route path="/acceptUserInvite" component={UserConfirmation} />
        <Route path="/confirmAccount" component={AccountConfirmation} />
        <Route path="/download" component={Download} />
        <Router>
          <AppRoutes/>
        </Router>
      </Switch>
    </div>
  );
}

export default App;
