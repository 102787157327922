// Dependecies
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

// Components
import RolesResponsabilities from "./RolesResponsabilities";
import TranslationsCards from "../Institutions/TranslationsCards";

class ProgramsSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      programTranslations: JSON.parse(
        localStorage.getItem("programTranslations")
      ),
    };
    console.log("++++++++++++++++++++++++++++++++++++++++++++++++++++++++");
    console.log(props);
  }

  render() {
    if (this.props.isTranslation == true) {
      this.state.display = "none";
    }
    const PrintTranslations = () => {
      if (this.state.programTranslations)
        return this.state.programTranslations.translations.map(
          (result, index, array) => (
            <TranslationsCards
              name={result.name}
              _id={result._id}
              language={result.language}
              type="program"
            />
          )
        );
      else
        return null
    };

    const Print = () => {
      return this.props.responsabilities.map((result, index, array) => (
        <RolesResponsabilities
          key={index}
          createdOn={result.createdOn}
          isMenteeOwner={result.isMenteeOwner}
          isMentorOwner={result.isMentorOwner}
          title={result.title}
        />
      ));
    };

    return (
      <div className="container-fluid text-left summary">
        <div className="row py-4">
          <div className="col-8">
            <h5>
              <strong>{this.props.name}</strong>
            </h5>
          </div>
          <div className="col-4 float-right text-right">
            <Link
              className="btn btn-primary"
              to={"/programs/details/edit/summary"}
            >
              {this.props.t('edit')}
            </Link>
          </div>
        </div>
        <div className="row py-2">
          <div className="col-2">
            <div className="row">
              <strong> {this.props.t('start_date')}</strong>
            </div>
            <div className="row">{this.props.startDate.substring(0, 10)}</div>
          </div>
          <div className="col-2">
            <div className="row">
              <strong>{this.props.t('end_date')}</strong>
            </div>
            <div className="row">{this.props.endDate.substring(0, 10)}</div>
          </div>
          <div className="col-2">
            <div className="row">
              <strong>{this.props.t('language')}</strong>
            </div>
            <div className="row">{this.props.language}</div>
          </div>
        </div>
        <div className="row py-2">
          <div className="col-12">
            <strong>{this.props.t('institution')}</strong>
          </div>
          <div className="col-12">{this.props.institutionName}</div>
        </div>
        <div className="row py-2">
          <div className="row">
            <div className="col-12">
              <strong>{this.props.t('description')}</strong>
            </div>
            <div className="col-12">
              <p>{this.props.description}</p>
            </div>
          </div>
        </div>
        
        <div className="row py-2">
          <div className="col-6">
            <div className="row">
              <strong> {this.props.t('gral_controls.gral_controls')}</strong>
            </div>
            <div className="row"><label>{this.props.t('gral_controls.allowMenteMatchRequest')}</label></div>
            <div className="row"><label>{this.props.t('gral_controls.allowMenteeChangeRandR')}</label></div>
            <div className="row"><label>{this.props.t('gral_controls.allowMentorChangeRandR')}</label></div>
            <div className="row"><label>{this.props.t('gral_controls.maxMatchesPerMentor')}</label></div>
            <div className="row"><label>{this.props.t('gral_controls.allowMenteeReopenProgram')}</label></div>
            <div className="row"><label>{this.props.t('gral_controls.allowMentorReopenProgram')}</label></div>
            <div className="row"><label>{this.props.t('gral_controls.allowMenteeAddNewGoals')}</label></div>
            <div className="row"><label>{this.props.t('gral_controls.allowMentorAddNewGoals')}</label></div>
          </div>
          <div className="col-6">
            <div className="row">
              <strong>True/Flase</strong>
            </div>
            <div className="row"><label><input type="checkbox" name="allowMenteMatchRequest" defaultChecked={this.props.allowMenteMatchRequest} disabled/>{this.props.allowMenteMatchRequest ? 'ON' : 'OFF'}</label></div>
            <div className="row"><label><input type="checkbox" name="allowMenteeChangeRandR" defaultChecked={this.props.allowMenteeChangeRandR} disabled/>{this.props.allowMenteeChangeRandR ? 'ON' : 'OFF'}</label></div>
            <div className="row"><label><input type="checkbox" name="allowMentorChangeRandR" defaultChecked={this.props.allowMentorChangeRandR} disabled/>{this.props.allowMentorChangeRandR ? 'ON' : 'OFF'}</label></div>
            <div className="row"><label><input type="number" name="maxMatchesPerMentor" value={this.props.maxMatchesPerMentor} disabled/></label></div>
            <div className="row"><label><input type="checkbox" name="allowMenteeReopenProgram" defaultChecked={this.props.allowMenteeReopenProgram} disabled/>{this.props.allowMenteeReopenProgram ? 'ON' : 'OFF'}</label></div>
            <div className="row"><label><input type="checkbox" name="allowMentorReopenProgram" defaultChecked={this.props.allowMentorReopenProgram} disabled/>{this.props.allowMentorReopenProgram ? 'ON' : 'OFF'}</label></div>
            <div className="row"><label><input type="checkbox" name="allowMenteeAddNewGoals" defaultChecked={this.props.allowMenteeAddNewGoals} disabled/>{this.props.allowMenteeAddNewGoals ? 'ON' : 'OFF'}</label></div>
            <div className="row"><label><input type="checkbox" name="allowMentorAddNewGoals" defaultChecked={this.props.allowMentorAddNewGoals} disabled/>{this.props.allowMentorAddNewGoals ? 'ON' : 'OFF'}</label></div>
          </div>
        </div>
        <hr />
        <div
          style={{
            display: `${this.state.display}`,
          }}
          className="row"
        >
          <div className="col-10 py-1">
            <h5>
              <strong>{this.props.t('translations')}</strong>
            </h5>
          </div>
          <div className="col-2 text-right">
            <Link
              className="btn btn-primary btn-sm"
              to={"/programs/details/addtranslation"}
            >
              {this.props.t("add_translation")}
            </Link>
          </div>
          <div className="col-12">
            <div className="row">
              <PrintTranslations />
            </div>
          </div>
        </div>

        <hr
          style={{
            display: `${this.state.display}`,
          }}
        />
        <div className="row roles">
          <div className="col-4"></div>
          <div className="col-4 text-center">
            <h5 className="mt-1">
              <b>{this.props.t('responsabilities') }</b>
            </h5>
          </div>
          <div className="col-4 text-right">
            <Link
              className="btn btn-primary"
              to={"/programs/details/edit/responsabilities"}
            >
              {this.props.t('edit')}
            </Link>
          </div>
          <div className="col-12 text-left  py-3">
            <div className="row">
              <div className="col-5">
                <strong>{this.props.t('responsability') }</strong>
              </div>
              <div className="col-3">{this.props.t('date') }</div>
              <div className="col-2 text-center">
                <strong>Mentee</strong>
              </div>
              <div className="col-2 text-center">
                <strong>Mentor</strong>
              </div>
            </div>
            <hr />
          </div>

          <Print />
        </div>
      </div>
    );
  }
}

export default withTranslation("Generals")(ProgramsSummary);
