// Dependencies
import React from "react";
import { withTranslation } from 'react-i18next';

// Components 
import { withRouter } from "react-router-dom";
// CSS
import './TopBar.css';

export class TopBar extends React.Component {


  render() {
    
    

    return (
      <div className="TopBar">
        <nav className="navbar navbar-dark">
          <div className="row">
            <div className="col-4 text-left pt-1">
              <button type="button" id="sidebarCollapse" className="btn menubtn">
                 <span id="menu-button" className="fas fa-chevron-right"></span>
              </button>
            </div>
            <div className="col-4 pt-1">
              <span className="align-middle h4 Raleway">{this.props.t('topbar.title')}</span>
            </div>
            <div className="col-4 text-right">
              <div className="row">
                <div className="col-12 pt-2 nameBanner d-none d-md-block Raleway">
                  {this.props.t('topbar.welcome') + ", " + this.props.UserName}
                </div>
                {/* V-2 Capability
                
                <div className="col-3 pt-2 text-left bellBorder "><div className="btn-group">
                  <button type="button" className="bell-button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <span className="AlertsCircle">{Alerts}</span>
                    <i className="far fa-bell"></i>
                  </button>
                    <Dropdown
                      AlertNumber={Alerts}
                    /> 
                  </div>
                </div>
                
                */}
              </div>
            </div>
          </div>
        </nav>
      </div>
      
    )

  }

}

export default withTranslation('Navbar')(withRouter(TopBar));
