// Dependencies
import React, {Component} from 'react';

class RequestsCards extends Component {

  render() {

    return (

      <div className="Users">
        <div className="UsersRows row ">

          <div className="col-3">
            <div className="row">
              <div className="col-12  UserInfoHeader Raleway text-center">Mentee</div>
            </div>
            <div className="row">
              <div className="col-12 text-center">{this.props.UserName}</div>
            </div>
          </div>

          <div className="col-3">
            <div className="row">
              <div className="col-12  UserInfoHeader Raleway text-center">Mentor</div>
            </div>
            <div className="row">
              <div className="col-12 text-center">{this.props.UserName}</div>
            </div>
          </div>

          <div className="col-3">
            <div className="row">
              <div className="col-12  UserInfoHeader Raleway text-center">Start date</div>
            </div>
            <div className="row">
              <div className="col-12 text-center">{this.props.UserName}</div>
            </div>
          </div>

          
          <div className="col-3">
            <div className="row">
              <div className="col-12  UserInfoHeader Raleway text-center">Start date</div>
            </div>
            <div className="row">
              <div className="col-12 text-center">{this.props.UserName}</div>
            </div>
          </div>

        </div>
        <hr/>
      </div>

    )
  }
}

export default RequestsCards;