import React from "react";
import { Line } from "react-chartjs-2";

class LineCharts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      chartOptions: {
        responsive: true,
        labels: this.props.dataArray.labels,
        tooltips: {
          mode: "label",
        },
        layout: {
          padding: {
            left: 15,
            right: 15,
            top: 5,
            bottom: 10,
          },
        },
        legend: {
          position: "bottom",
          labels: {
            fontColor: "#7553BB",
            boxWidth: 15,
            fontFamily: "Raleway",
            fontStyle: "bold",
            padding: 20,
          },
        },
        elements: {
          line: {
            fill: false,
          },
        },
        scales: {
          xAxes: [
            {
              display: true,
              gridLines: {
                display: true,
              },
              labels: this.props.dataArray.labels,
              ticks: {
                fontColor: "#7553BB",
                fontFamily: "Raleway",
                fontStyle: "bold",
              },
            },
          ],
          yAxes: [
            {
              type: "linear",
              display: true,
              position: "left",
              id: "y-axis-1",
              gridLines: {
                display: true,
              },
              labels: {
                show: true,
              },
              ticks: {
                fontColor: "#7553BB",
                fontFamily: "Raleway",
                fontStyle: "bold",
                fontSize: "15",
                stepSize: 1,
              },
            },
          ],
        },
      },
      chartData: {
        datasets: [
          {
            label: this.props.label,
            fill: true,
            lineTension: 0.5,
            backgroundColor: "rgba(59, 212, 192, .3)",
            borderColor: "rgb(59, 212, 192)",
            borderCapStyle: "round",
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: "round",
            pointBorderColor: "rgb(62, 181, 165)",
            pointBackgroundColor: "rgb(0, 0, 0)",
            pointBorderWidth: 8,
            pointRadius: 5,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: "rgb(255, 255, 255)",
            pointHoverBorderColor: "rgba(62, 181, 165)",
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: this.props.dataArray.count,
          },
        ],
      },
      dataPicker: 0,
    };
  }

  changeData(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value });
  }

  render() {
    return (
      <div className="row">
        <div className="col-12 p-0">
          <div className="chart-card">
            <h4 className="chart-header">{this.props.label}</h4>
            <Line
              data={this.state.chartData}
              options={this.state.chartOptions}
            />
          </div>
        </div>
        <div className="col-6"></div>
      </div>
    );
  }
}

export default LineCharts;
