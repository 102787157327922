// Dependecies
import React, { Component } from "react";
import Swal from "sweetalert2";
import axios from "axios";

// Server URL
import serverURL from "../../serverURL";
import { withTranslation } from "react-i18next";
var direccion = serverURL;

class EditLinks extends Component {
  constructor(props) {
    var program = JSON.parse(localStorage.getItem("selectedProgram"));
    super(props);
    this.state = {
      programId: program._id,
      programName: program.name,
      links: program.links,
    };
  }

  onDeleteLinks(e, index, currentValue) {
    e.preventDefault();
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        this.state.links.splice([index], 1);
        this.setState({ responsabilities: this.state.responsabilities });
      }
    });
  }

  addLink(e) {
    e.preventDefault();
    this.setState({
      links: [
        ...this.state.links,
        {
          title: "",
          address: "",
          createdBy: localStorage.getItem("UserId"),
          createdOn: "",
        },
      ],
    });
  }

  changeLink(e, index, currentValue) {
    this.state.links[index][e.target.name] = e.target.value;
    this.setState({ links: this.state.links });
  }

  submitHandler(e) {
    e.preventDefault();
    e.target.className += " was-validated";
    var object = { links: this.state.links };
    Swal.fire({
      title: "Alerta",
      text: "Recuerda cambiar los links tambien en tus traducciones",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Enterado",
    }).then((result) => {
      if (result.value) {
        axios
          .post(
            direccion + "api/program/updateArrayLinks/" + this.state.programId,
            object,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("UserSession"),
                
              },
            }
          )
          .then((response) => {
            localStorage.setItem(
              "selectedProgram",
              JSON.stringify(response.data.programs)
            );
            Swal.fire({
              title: "Success",
              icon: "success",
              text: "Links updated correctly",
            }).then((result) => {
              if (result.value) {
                window.open("/programs/details/links", "_self");
              }
            });
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: error.response.data.stack[4],
              icon: "error",
            });
          });
      }
    });
  }

  cancelHandler(e) {
    e.preventDefault();
    Swal.fire({
      title: "Are you sure you want to leave?",
      text: "The information will be lost",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        window.open("/programs/details/links", "_self");
      }
    });
  }

  render() {
    return (
      <div className="container mt-4">
        <div className="col-12 p-0 mt-4 text-right">
          Links
          <button className="ProgramAddNew" onClick={(e) => this.addLink(e)}>
            {
              this.props.t('add_new')
            }
          </button>
        </div>
        <form
          className=" needs-validation"
          onSubmit={(e) => this.submitHandler(e)}
        >
          <div className="col-12 addNewContainer mt-2 p-3">
            <div className="row addNewHeaders">
              <div className="col-4">Title</div>
              <div className="col-7 text-center">URL</div>
            </div>
            {this.state.links.map((currentValue, index, array) => {
              return (
                <div className="row py-2" key={index}>
                  <div className="col-4">
                    <input
                      className="AddNewTitleInput form-control"
                      name="title"
                      value={currentValue.title}
                      onChange={(e) => this.changeLink(e, index, currentValue)}
                      required
                    ></input>
                  </div>
                  <div className="col-7 text-center">
                    <input
                      type = "url"
                      pattern = "https?://.+"
                      className="AddNewTitleInput form-control"
                      name="address"
                      value={currentValue.address}
                      onChange={(e) => this.changeLink(e, index, currentValue)}
                      required
                    ></input>
                  </div>
                  <div className="col-1 text-center">
                    <button
                      className="removeInput"
                      onClick={(e) =>
                        this.onDeleteLinks(e, index, currentValue)
                      }
                    >
                      <i className="fas fa-times"></i>
                    </button>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="row float-right">
            <div className="col-12 my-4">
              <input
                type="reset"
                value="Cancel"
                className="btn btn-outline-danger mr-2"
                onClick={(e) => this.cancelHandler(e)}
              />
              <input type="submit" className="btn btn-primary" value="Save" />
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default withTranslation('Generals')(EditLinks);
