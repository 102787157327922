// Dependencies
import React, {Component} from 'react';
import axios from "axios";
import readXlsxFile from 'read-excel-file';
import Swal from 'sweetalert2'

// Server URL
import serverURL from '../../serverURL';
var direccion = serverURL;

class AddUser extends Component {

  constructor(props) {
    super(props);
    this.state = {
      emails: [
        {
          name: '',
          email: ''
        }
      ],
      file: {}
    }
  }

  hadleUserInput(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({[name]: value});
  }

  onChangeHandler = event => {
    readXlsxFile(event.target.files[0]).then(data => {
      this.setState({file: data});
    });
    this.setState({fileName: event.target.files[0].name});

  }

  convert(e) {
    e.preventDefault()
    this
      .state
      .file
      .forEach((element, index, array) => {
        this.state.emails[index] = {
          name: element[0],
          email: element[1]
        }
      });

    this.setState({emails: this.state.emails});
  }

  changeEmail(e, index, currentValue) {
    this.state.emails[index][e.target.name] = e.target.value;
    this.setState({emails: this.state.emails});
  }

  onDelete(e, index, currentValue) {
    e.preventDefault()
    this
      .state
      .emails
      .splice([index], 1);
    this.setState({emails: this.state.emails});
  };

  cancelHandler(e) {
    e.preventDefault();
    Swal
      .fire({
      title: 'Are you sure you want to leave?',
      text: 'The information will be lost',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    })
      .then((result) => {
        if (result.value) {
          window.open('/institutions/details/users', '_self');
        }
      })
  }

  submitHandler(e) {
    e.preventDefault();
    e.target.className += " was-validated";
    axios.post(direccion + "api/institution/program/" + this.props.location.pathname.split("/").slice(1)[3], this.state, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("UserSession"),
        
      }
    }).then((response) => {
      localStorage.setItem("selectedInstitution", JSON.stringify(response.data.institutions[0]));
      Swal
        .fire({title: 'Success', icon: 'success', text: 'Program created'})
        .then((result) => {
          if (result.value) {
            window.open("/institutions/details/programs", "_self");
          }
        });
    }).catch((error) => {
      Swal.fire({title: 'Error', text: error.response.data.stack[2], icon: 'error'});
    });
  }

  render() {
    return (
      <div className="container my-3">
        <div className="row">
          <div className="col-12">
            <h3>Add Users</h3>
          </div>
        </div>
        <form action="">
          <div className="row text-left">
            <div className="col-12 mt-4">
              {this
                .state
                .emails
                .map((currentValue, index, array) => {
                  return (
                    <div className="row py-2" key={index}>
                      <div className="col-4">
                        <input
                          className="AddNewTitleInput"
                          name="name"
                          value={currentValue.name}
                          onChange={(e) => this.changeEmail(e, index, currentValue)}></input>
                      </div>
                      <div className="col-7 text-center">
                        <input
                          className="AddNewTitleInput"
                          name="email"
                          value={currentValue.email}
                          onChange={(e) => this.changeEmail(e, index, currentValue)}></input>
                      </div>
                      <div className="col-1 text-center">
                        <button
                          className="removeInput"
                          onClick={(e) => this.onDelete(e, index, currentValue)}>
                          <i className="fas fa-times"></i>
                        </button>
                      </div>
                    </div>
                  )
                })
}
            </div>
          </div>
          <div className="my-5 inputArea">
            <label>Upload Excel File and then click convert
            </label>
            <div className="excelFile">
              <input
                id="file"
                className="file m-3"
                type="file"
                name="file"
                onChange={this.onChangeHandler}/>
              <span></span>
              <label className="personalizedFile" for="file">
                <i className="far fa-file-excel"></i><br/>Choose a file</label>
            </div>

            <button className="m-3 convert" onClick={(e) => this.convert(e)}>
              Convert {" " + this.state.fileName}
            </button>
          </div>

          <div className="row float-right">
            <div className="col-12 my-4">
              <input
                type="reset"
                value="Cancel"
                className="btn btn-outline-danger mr-2"
                onClick={(e) => this.cancelHandler(e)}/>
              <input
                type="submit"
                className="btn btn-primary"
                value="Save"
                onClick={(e) => this.submitHandler(e)}/>
            </div>
          </div>

        </form>
      </div>
    )
  }
}

export default AddUser;