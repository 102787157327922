// Dependencies
import React, { Component } from "react";
import axios from "axios";

// Components
import SearchBar from "../Bars/SearchBar.js";
import ProgramsCards from "../Programs/ProgramsCards";

// CSS
import "./Programs.css";

// Server URL
import serverURL from "../../serverURL";
import { withTranslation } from "react-i18next";
var direccion = serverURL;

class Programs extends Component {
  constructor(props) {
    var userRole = JSON.parse(localStorage.getItem("User"));
    super(props);
    this.state = {
      currentProgram: [],
      programs: JSON.parse(localStorage.getItem("Programs")),
      userRole: userRole.role,
    };
  }

  componentDidMount() {
    var user = JSON.parse(localStorage.getItem("User"));
    var userRole = user.role;
    if (userRole === "user") {
      axios
        .post(
          direccion + "api/user/dashboard/" + user._id,
          { module: "admin" },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("UserSession"),
              
            },
          }
        )
        .then((response) => {
          localStorage.setItem(
            "Institutions",
            JSON.stringify(response.data.institutions)
          );
          localStorage.setItem(
            "Programs",
            JSON.stringify(response.data.programs)
          );
        });
    }
  }

  selectedCard(program) {
    if (this.state.userRole === "sysadmin") {
      localStorage.setItem("selectedProgram", JSON.stringify(program));
      axios
        .post(direccion + "api/program/getTranslation/" + program._id, {}, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("UserSession"),
            'Access-Control-Allow-Origin': "*"
          },
        })
        .then((response) => {
          localStorage.setItem(
            "programTranslations",
            JSON.stringify(response.data)
          );
        });
    } else {
      axios
        .post(direccion + "api/program/getTranslation/" + program._id, {}, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("UserSession"),
            'Access-Control-Allow-Origin': "*"
          },
        })
        .then((response) => {
          localStorage.setItem(
            "programTranslations",
            JSON.stringify(response.data)
          );

          axios
            .get(direccion + "api/program/getProgramByID/" + program._id , {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("UserSession"),
                
              },
            })
            .then((response) => {
              localStorage.setItem(
                "selectedProgram",
                JSON.stringify(response.data.programs)
              );
              localStorage.setItem(
                "programsResult",
                JSON.stringify(response.data.programs)
              );
              window.open("/programs/details/summary", "_self");
            })
            .catch((error) => {});
        });

      
    }

    this.setState({ show: "", expanded: "false" });
  }

  render() {
    const Print = () => {
      if (this.state.userRole === "sysadmin") {
        return (
          <SearchBar
            AddNewLink="/programs/addnew"
            SearchType="program"
            cardViewCallback={this.selectedCard}
            hide="d-none"
          />
        );
      } else {
        return (
          <div className="">
            <div className="navbar navbar-dark SearchBar text-left row">
              <div className="col-7">
                <button
                  className="btn btn-outline-primary btn-sm px-2 py-0"
                  data-toggle="collapse"
                  data-target="#multiCollapseExample1"
                  aria-expanded="true"
                  aria-controls="multiCollapseExample1"
                >
                  {this.props.t('show_programs')}
                </button>
              </div>
            </div>
            {this.state.programs.map((result, index, array) => (
              <div
                onClick={(e) => this.selectedCard(array[index])}
                id="multiCollapseExample1"
                className="show multi-collapse"
              >
                <ProgramsCards
                  name={result.name}
                  institutionName={result.institutionName}
                  startDate={result.startDate.substring(0, 10)}
                  endDate={result.endDate.substring(0, 10)}
                />
              </div>
            ))}
          </div>
        );
      }
    };

    return (
      <div className="Programs">
        <Print />
      </div>
    );
  }
}

export default withTranslation('Generals')(Programs);
