// Dependencies
import React, { Component } from "react";
import axios from "axios";
import Swal from "sweetalert2";

// Server URL
import serverURL from "../../serverURL";
import { withTranslation } from "react-i18next";
var direccion = serverURL;

class AddProgram extends Component {
  constructor(props) {
    super(props);
    var program = JSON.parse(localStorage.getItem("selectedProgram"));
    this.state = {
      startDate: program.startDate,
      originalStartDate: program.startDate,
      endDate: program.endDate,
      name: program.name,
      description: program.description,
      id: program._id,
      allowMenteMatchRequest: program.allowMenteMatchRequest===undefined ? true: program.allowMenteMatchRequest,
      allowMenteeChangeRandR : program.allowMenteeChangeRandR===undefined ? true: program.allowMenteeChangeRandR,
      allowMentorChangeRandR : program.allowMentorChangeRandR===undefined ? true: program.allowMentorChangeRandR,
      maxMatchesPerMentor : program.maxMatchesPerMentor===undefined ? 1: program.maxMatchesPerMentor,
	    allowMenteeReopenProgram : program.allowMenteeReopenProgram===undefined ? true: program.allowMenteeReopenProgram,
	    allowMentorReopenProgram : program.allowMentorReopenProgram===undefined ? true: program.allowMentorReopenProgram,
	    allowMenteeAddNewGoals : program.allowMenteeAddNewGoals===undefined ? true: program.allowMenteeAddNewGoals,
	    allowMentorAddNewGoals :	program.allowMentorAddNewGoals===undefined ? true: program.allowMentorAddNewGoals,
      language: program.language
        ? program.language
        : "es",
    };
  }

  hadleUserInput(e) {
    if (e.target.name == "allowMenteMatchRequest")
      this.state.allowMenteMatchRequest = e.target.checked;
    if (e.target.name == "allowMenteeChangeRandR")
      this.state.allowMenteeChangeRandR = e.target.checked;
    if (e.target.name == "allowMentorChangeRandR")
      this.state.allowMentorChangeRandR = e.target.checked;
    if (e.target.name == "allowMenteeReopenProgram")
      this.state.allowMenteeReopenProgram = e.target.checked;
    if (e.target.name == "allowMentorReopenProgram")
      this.state.allowMentorReopenProgram = e.target.checked;
    if (e.target.name == "allowMenteeAddNewGoals")
      this.state.allowMenteeAddNewGoals = e.target.checked;
    if (e.target.name == "allowMentorAddNewGoals")
      this.state.allowMentorAddNewGoals = e.target.checked;
    const name = e.target.name;
    const value = e.target.type=="checkbox"?e.target.checked: e.target.value;
    this.setState({ [name]: value }, ()=>{
      console.log(this.state)
    });
  }

  handleButtonAnimation(e) {
    var position = e.target.className.toString();
    if (position.includes("collapsed") === true) {
      e.target.className =
        "btn btn-sm btn-primary w-100 fas fa-chevron-up collapsed";
    } else {
      e.target.className = "btn btn-sm btn-primary w-100 fas fa-chevron-down ";
    }
  }

  submitHandler(e) {
    e.preventDefault();
    e.target.className += " was-validated";
    var object = {
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      name: this.state.name,
      description: this.state.description,
      allowMenteMatchRequest: this.state.allowMenteMatchRequest,
      allowMenteeChangeRandR: this.state.allowMenteeChangeRandR,
      allowMentorChangeRandR: this.state.allowMentorChangeRandR,
      allowMenteeReopenProgram: this.state.allowMenteeReopenProgram,
      allowMentorReopenProgram: this.state.allowMentorReopenProgram,
      allowMenteeAddNewGoals: this.state.allowMenteeAddNewGoals,
      allowMentorAddNewGoals: this.state.allowMentorAddNewGoals,
      maxMatchesPerMentor: this.state.maxMatchesPerMentor
    };
    console.log(object);
    axios
      .post(
        direccion + "api/program/updateProgramObj/" + this.state.id,
        object,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("UserSession"),
            
          },
        }
      )
      .then((response) => {
        localStorage.setItem(
          "selectedProgram",
          JSON.stringify(response.data.programs)
        );
        Swal.fire({
          title: "Success",
          icon: "success",
          text: "Program modified",
        }).then((result) => {
          if (result.value) {
            window.open("/programs/details/summary", "_self");
          }
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.stack[2],
          icon: "error",
        });
      });
  }

  cancelHandler(e) {
    e.preventDefault();
    Swal.fire({
      title: this.props.t('alerts.are_you_sure'),
         text: this.props.t('alerts.information_will_be_lost'),
         icon: 'warning',
         showCancelButton: true,
         confirmButtonColor: '#3085d6',
         cancelButtonColor: '#d33',
         confirmButtonText: this.props.t('yes'),
         cancelButtonText: this.props.t('cancel')
    }).then((result) => {
      if (result.value) {
        window.open("/programs/details/summary", "_self");
      }
    });
  }

  render() {
    return (
      <div className="container my-3">
        <form onSubmit={(e) => this.submitHandler(e)} autocomplete="off" >
          <div className="row text-left">
            <div className="col-6  mt-4">
              {this.props.t('name')}
              <input
                type="text"
                className="mt-2 form-control"
                name="name"
                value={this.state.name}
                onChange={(event) => this.hadleUserInput(event)}
                required
              />
            </div>
            <div className="col-2 mt-4">
              {this.props.t('start_date')}
              <br />
              <input
                type="date"
                className="mt-2 w-100 form-control"
                name="startDate"
                 min = {
                   this.state.originalStartDate.substring(
                     0,
                     10
                   )
                 }
                value={this.state.startDate.substring(0, 10)}
                onChange={(event) => this.hadleUserInput(event)}
                required
              />
            </div>
            <div className="col-2 mt-4 mb-2">
              {this.props.t('end_date')}
              <br />
              <input
                type="date"
                className="mt-2 w-100 form-control"
                name="endDate"
                 min = {
                   this.state.startDate.substring(
                     0,
                     10
                   )
                 }
                value={this.state.endDate.substring(0, 10)}
                onChange={(event) => this.hadleUserInput(event)}
                required
              />
            </div>
            
             <div className="col-2 mt-4 text-center">
              {this.props.t('language')}
              <br />
              <select
                className="selectP"
                name="language"
                id=""
                onChange={(event) => this.hadleUserInput(event)}
                value={this.state.language}
              >
                <option value="es" >{this.props.t("languages.spanish")}</option>
                <option value="en" >{this.props.t("languages.english")}</option>
                <option value="fr" >{this.props.t("languages.french")}</option>
                <option value="de" >{this.props.t("languages.deutsch")}</option>
                <option value="pt" >{this.props.t("languages.portuguese")}</option>
              </select>
            </div>
            <div className="col-12 mt-4">
              {this.props.t('description') }<span className="font-italic"> (Max 150 characters) </span>
              <input
                type="text"
                className="mt-2 form-control"
                name="description"
                maxLength = {
                  150
                }
                value={this.state.description}
                onChange={(event) => this.hadleUserInput(event)}
              />
            </div>
          </div>
          <div className="row py-2">
            <div className="col-6">
              <div className="row">
                <strong> {this.props.t('gral_controls.gral_controls')}</strong>
              </div>
              <div className="row"><label>{this.props.t('gral_controls.allowMenteMatchRequest')}</label></div>
              <div className="row"><label>{this.props.t('gral_controls.allowMenteeChangeRandR')}</label></div>
              <div className="row"><label>{this.props.t('gral_controls.allowMentorChangeRandR')}</label></div>
              <div className="row"><label>{this.props.t('gral_controls.maxMatchesPerMentor')}</label></div>
              <div className="row"><label>{this.props.t('gral_controls.allowMenteeReopenProgram')}</label></div>
              <div className="row"><label>{this.props.t('gral_controls.allowMentorReopenProgram')}</label></div>
              <div className="row"><label>{this.props.t('gral_controls.allowMenteeAddNewGoals')}</label></div>
              <div className="row"><label>{this.props.t('gral_controls.allowMentorAddNewGoals')}</label></div>
            </div>
            <div className="col-6">
              <div className="row">
                <strong>ON/OFF</strong>
              </div>
              <div className="row"><label><input type="checkbox" name="allowMenteMatchRequest" defaultChecked={this.state.allowMenteMatchRequest} onChange={(event) => this.hadleUserInput(event)}/></label></div>
              <div className="row"><label><input type="checkbox" name="allowMenteeChangeRandR" defaultChecked={this.state.allowMenteeChangeRandR} onChange={(event) => this.hadleUserInput(event)}/></label></div>
              <div className="row"><label><input type="checkbox" name="allowMentorChangeRandR" defaultChecked={this.state.allowMentorChangeRandR} onChange={(event) => this.hadleUserInput(event)}/></label></div>
              <div className="row"><label><input type="number" min="1" name="maxMatchesPerMentor" value={this.state.maxMatchesPerMentor} onChange={(event) => this.hadleUserInput(event)}/></label></div>
              <div className="row"><label><input type="checkbox" name="allowMenteeReopenProgram" defaultChecked={this.state.allowMenteeReopenProgram} onChange={(event) => this.hadleUserInput(event)}/></label></div>
              <div className="row"><label><input type="checkbox" name="allowMentorReopenProgram" defaultChecked={this.state.allowMentorReopenProgram} onChange={(event) => this.hadleUserInput(event)}/></label></div>
              <div className="row"><label><input type="checkbox" name="allowMenteeAddNewGoals" defaultChecked={this.state.allowMenteeAddNewGoals} onChange={(event) => this.hadleUserInput(event)}/></label></div>
              <div className="row"><label><input type="checkbox" name="allowMentorAddNewGoals" defaultChecked={this.state.allowMentorAddNewGoals} onChange={(event) => this.hadleUserInput(event)}/></label></div>
            </div>
          </div>

          <div className="row float-right">
            <div className="col-12 my-4">
              <input
                type="reset"
                value={this.props.t('cancel') }
                className="btn btn-outline-danger mr-2"
                onClick={(e) => this.cancelHandler(e)}
              />
              <input type="submit" className="btn btn-primary" value={this.props.t('save') } />
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default withTranslation('Generals')(AddProgram);
