import React, { Component } from "react";
import axios from "axios";
import readXlsxFile from "read-excel-file";
import Swal from "sweetalert2";

// Assets
import usuariosFile from "../../Assets/Templates/usuarios.xlsx";

// Server URL
import serverURL from "../../serverURL";
import { withTranslation } from "react-i18next";
import convertToJson from "read-excel-file/commonjs/convertToJson";
var direccion = serverURL;

class AddUsers extends Component {
  constructor(props) {
    super(props);
    const Institution = JSON.parse(localStorage.getItem("selectedInstitution"));
    this.state = {
      buttonStatus: true,
      saveStatus: false,
      institutionName: Institution.institutionName,
      institutionId: Institution._id,
      users: [
        {
          name: "",
          mail: "",
          language: "es",
          isPredefinedMentor: false,
        },
      ],
      file: {},
      fileName: "",
      showModal: "",
      displayModal: "none",
      invitesTotal: "",
      invitesCount: 0,
      invitesFailed: [],
      inviteStatus: "",
    };
  }

  hadleUserInput(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value });
  }

  changeInput(e, index, currentValue) {
    this.state.users[index][e.target.name] = e.target.value;
    this.setState({ users: this.state.users });
  }

  changeCheckbox(e, index, currentValue) {
    this.state.users[index][e.target.name] =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    this.setState({ responsabilities: this.state.responsabilities });
  }

  onChangeHandler = (event) => {
    readXlsxFile(event.target.files[0]).then((data) => {
      this.setState({ file: data });
    });
    this.setState({ fileName: event.target.files[0].name, buttonStatus: false, saveStatus: true });
  };

  convert(e) {
    e.preventDefault();
    var currentArray = this.state.file;
    var a = currentArray.slice(1, currentArray.length);
    a.forEach((element, index, array) => {
      this.state.users[index] = {
        name: element[0],
        mail: element[1],
        language: element[2],
        isPredefinedMentor: element[3] === "true",
      };
    });

    this.setState({ users: this.state.users, saveStatus:false });
  }

  addProfessionalInterest(e) {
    e.preventDefault();
    this.setState({
      users: [
        ...this.state.users,
        {
          name: "",
          mail: "",
          language: "",
          isPredefinedMentor: "",
        },
      ],
    });
  }

  onDelete(e, index, currentValue) {
    e.preventDefault();
    this.state.users.splice([index], 1);
    this.setState({ users: this.state.users });
  }

  cancelHandler(e) {
    e.preventDefault();
    Swal.fire({
      title: "Are you sure you want to leave?",
      text: "The information will be lost",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        window.open("/institutions/details/users", "_self");
      }
    });
  }

  closeModal(e) {
    this.setState({
      showModal: "",
      displayModal: "none",
    });
    window.open("/institutions/details/users", "_self");
  }

  async submitHandler(e) {
    e.preventDefault();
    e.target.className += " was-validated";
    this.setState({
      showModal: "show d-block personalized-modal",
      displayModal: "block",
    });

    var total = this.state.users.length;
    this.setState({
      invitesTotal: total,
      invitesCount: 0,
      inviteStatus: "Request in process",
    });
    var responseArray = [];

    for (let i = 0; i < this.state.users.length; i++) {
      setTimeout(() => { 
        const object = {
          toName: this.state.users[i].name,
          toMail: this.state.users[i].mail,
          institutionName: this.state.institutionName,
          language: this.state.users[i].language,
          isPredefinedMentor: this.state.users[i].isPredefinedMentor,
        };
        axios
          .post(
            direccion +
            "api/institution/sendUserInvite/" +
            this.state.institutionId,
            object,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("UserSession"),

              },
            }
          )
          .then((response) => {
            this.setState({ invitesCount: this.state.invitesCount + 1 });
            responseArray.push(response);
            localStorage.setItem(
              "selectedInstitution",
              JSON.stringify(
                responseArray[responseArray.length - 1].data.institutions[0]
              )
            );
            return response;
          })
          .catch((error) => {
            return error;
          });        
      },(i+1) * 5000)
    }
    this.setState({
      inviteStatus: "Invites sent",
    });
  }

  render() {
    return (
      <div className="container mt-4">
        <div className="row text-center">
          <div className="col-12">
            <h3>{this.props.t('add_users') }</h3>
          </div>
        </div>
        <form className="text-left">
          <div className="col-12 p-0 mt-4">
            <button
              className="ProgramAddNew"
              onClick={(e) => this.addProfessionalInterest(e)}
            >
              {this.props.t('add')}
            </button>
          </div>
          <div className="col-12 addNewContainer mt-2 p-3">
            <div className="row addNewHeaders">
              <div className="col-4">{this.props.t('name')}</div>
              <div className="col-4 ">{this.props.t('email')}</div>
              <div className="col ">{this.props.t('language')}</div>
              <div className="col text-center">{this.props.t('is_predefined_mentor')}</div>
              <div className="col-1"></div>
            </div>
            {this.state.users.map((currentValue, index, array) => {
              return (
                <div className="row py-2" key={index}>
                  <div className="col-4">
                    <input
                      className="AddNewTitleInput"
                      name="name"
                      value={currentValue.name}
                      onChange={(e) => this.changeInput(e, index, currentValue)}
                      required
                    ></input>
                  </div>
                  <div className="col-4">
                    <input
                      className="AddNewTitleInput"
                      name="mail"
                      value={currentValue.mail}
                      onChange={(e) => this.changeInput(e, index, currentValue)}
                      required
                    ></input>
                  </div>
                  <div className="col">
                    <select
                      className="AddNewTitleInput"
                      name="language"
                      value={currentValue.language}
                      onChange={(e) => this.changeInput(e, index, currentValue)}
                    >
                      <option value="es">ES</option>
                      <option value="en">EN</option>
                    </select>
                  </div>
                  <div className="col text-center">
                    <input
                      className="AddNewCheckbox mt-1"
                      type="checkbox"
                      name="isPredefinedMentor"
                      id=""
                      checked={currentValue.isPredefinedMentor}
                      onChange={(e) =>
                        this.changeCheckbox(e, index, currentValue)
                      }
                    />
                  </div>
                  <div className="col-1 text-center">
                    <button
                      className="removeInput"
                      onClick={(e) => this.onDelete(e, index, currentValue)}
                    >
                      <i className="fas fa-times"></i>
                    </button>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="row">
            <div className="col-6">
              <div className="my-4 inputArea">
                <label>
                  <b>Step 1: Dowload the template file</b>
                </label>
                <label className="personalizedFile2">
                  <i
                    style={{
                      color: "gray",
                      fontSize: "70px",
                      marginBottom: "5px",
                    }}
                    className="fas fa-file-download"
                  ></i>
                  <br />
                  User template
                </label>
                <a className="m-3 convert" href={usuariosFile} download>
                  {this.props.t('download')}
                </a>
              </div>
            </div>
            <div className="col-6">
              <div className="my-4 inputArea">
                <label>
                  <b>Step 2: Upload the edited file and the click on convert</b>
                </label>
                <div className="excelFile">
                  <input
                    id="file"
                    className="file m-3"
                    type="file"
                    name="file"
                    onChange={this.onChangeHandler}
                  />
                  <span></span>
                  <label className="personalizedFile" for="file">
                    <i className="far fa-file-excel"></i>
                    <br />
                    Choose a file
                  </label>
                </div>

                <button
                  className="m-3 convert"
                  onClick={(e) => this.convert(e)}
                  disabled={this.state.buttonStatus}
                >
                  {this.props.t('convert')} {" " + this.state.fileName}
                </button>
              </div>
            </div>
          </div>

          <div className="row float-right">
            <div className="col-12 mb-4">
              <input
                type="reset"
                value={this.props.t('cancel')}
                className="btn btn-outline-danger mr-2"
                onClick={(e) => this.cancelHandler(e)}
              />
              <input
                type="submit"
                className="btn btn-primary"
                value={this.props.t('save')}
                disabled={this.state.saveStatus}
                onClick={(e) => this.submitHandler(e)}
              />
            </div>
          </div>
        </form>

        {/***** Modal ******/}
        <div
          className={"modal fade " + this.state.showModal}
          id="exampleModalCenter"
          tabindex="-1"
          style={{
            display: `${this.state.display}`,
          }}
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalCenterTitle">
                  {this.state.inviteStatus}
                </h5>
                <button
                  type="button"
                  className="close"
                  onClick={(e) => this.closeModal(e)}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                Sending invites to users emails.{" "}
                {this.state.invitesCount + " / " + this.state.invitesTotal}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation('Generals')(AddUsers);
