import React, { Component } from "react";
import axios from "axios";
import Swal from "sweetalert2";

// Server URL
import serverURL from "../../serverURL";
import { withTranslation } from "react-i18next";
var direccion = serverURL;

class AddNewAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toName: "",
      toMail: "",
      institutionName: this.props.location.pathname.split("/").slice(4)[0],
      language: "es",
    };
  }

  hadleUserInput(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value });
  }

  submitHandler(e) {
    e.preventDefault();
    e.target.className += " was-validated";
    axios
      .post(
        direccion +
          "api/institution/sendAdminInvite/" +
          this.props.location.pathname.split("/").slice(5)[0],
        this.state,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("UserSession"),
            
          },
        }
      )
      .then((response) => {
        localStorage.setItem(
          "selectedInstitution",
          JSON.stringify(response.data.institutions[0])
        );
        Swal.fire({
          title: "Success",
          icon: "success",
          text: this.state.toMail + " added as admin",
        }).then((result) => {
          if (result.value) {
            window.open("/institutions/details", "_self");
          }
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.stack[4],
          icon: "error",
        });
      });
  }

cancelHandler(e) {
  e.preventDefault();
  Swal
    .fire({
      title: this.props.t('alerts.are_you_sure'),
      text: this.props.t('alerts.information_will_be_lost'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: this.props.t('yes'),
      cancelButtonText: this.props.t('cancel')
    })
    .then((result) => {
      if (result.value) {
        window.open('/institutions/details/profile', '_self');
      }
    })
}

  render() {
    const currentInstitutionName = this.props.location.pathname
      .split("/")
      .slice(4)[0];
    return (
      <div className="container mt-4">
        <div>
          <row>
            <h3>{this.props.t("institutions.admins.add_admin")}</h3>
          </row>
        </div>
        <form
          className="text-left needs-validation"
          onSubmit={(e) => this.submitHandler(e)}
          noValidate
        >
          <div className="col-10 mt-4">
            {this.props.t('name')}:
            <input
              type="text"
              className="mt-2 form-control"
              name="toName"
              value={this.state.toName}
              onChange={(event) => this.hadleUserInput(event)}
              required
            />
            <div className="valid-feedback">Looks good!</div>
            <div className="invalid-feedback">Please fill this field</div>
          </div>
          <div className="col-10 mt-4">
            <label htmlFor="toMail">
            {this.props.t('email')}:</label>

            <input
              type="email"
              className="mt-2 form-control"
              name="toMail"
              id="toMail"
              value={this.state.toMail}
              onChange={(event) => this.hadleUserInput(event)}
              required
            />
            <div className="valid-feedback">Looks good!</div>
            <div className="invalid-feedback">Please enter a valid email.</div>
          </div>
          <div className="col-10 mt-4">
            
            {this.props.t('institution')}:
            <input
              type="text"
              disabled
              className="mt-2"
              name="institutionName"
              value={currentInstitutionName}
              onChange={(event) => this.hadleUserInput(event)}
              required
            />
          </div>
          <div className="col-10 mt-4">
            {
              this.props.t('language')
            }:
            <select
              className="ml-2"
              name="languaje"
              onChange={(event) => this.hadleUserInput(event)}
            >
              <option value="es">ES</option>
              <option value="en">EN</option>
            </select>
          </div>

          <div className="row float-right">
            <div className="col-12 mb-4">
              <input
                type="reset"
                value={this.props.t('cancel')}
                className="btn btn-outline-danger mr-2"
                onClick={(e) => this.cancelHandler(e)}
              />
              <input type="submit" className="btn btn-primary" value=
            {this.props.t('save')} />
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default withTranslation('Generals')(AddNewAdmin);
