// Dependecies
import React, { Component } from "react";
import Swal from "sweetalert2";
import axios from "axios";

// Server URL
import serverURL from "../../serverURL";
import { withTranslation } from "react-i18next";
var direccion = serverURL;

class EditGoals extends Component {
  constructor(props) {
    var program = JSON.parse(localStorage.getItem("selectedProgram"));
    super(props);
    this.state = {
      programId: program._id,
      programName: program.name,
      goals: program.goals,
      startDate: program.startDate,
      endDate: program.endDate,
    };
  }

  onDeleteGoalLinks(e, index, currentValue, linkIndex) {
    e.preventDefault();
    this.state.goals[index].links.splice([linkIndex], 1);
    this.setState({ responsabilities: this.state.responsabilities });
  }

  addGoals(e) {
    e.preventDefault();
    this.setState({
      goals: [
        ...this.state.goals,
        {
          subject: "",
          description: "",
          endDate: "",
          startDate: "",
          tasks: [
            {
              description: "",
              menteeAssignment: false,
              mentorAssignment: false,
              targetStartDate: "",
              targetEndDate: "",
              startDate: "",
              endDate: "",
            },
          ],
          links: [
            {
              title: "",
              address: "",
              createdBy: localStorage.getItem("UserId"),
              createdOn: "",
            },
          ],
        },
      ],
    });
  }

  changeGoal(e, index, currentValue) {
    this.state.goals[index][e.target.name] =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    this.setState({ goals: this.state.goals });
  }

  addGoalLink(e, index) {
    e.preventDefault();
    let currentState = this.state;
    currentState.goals[index].links = [
      ...currentState.goals[index].links,
      {
        title: "",
        address: "",
        createdBy: localStorage.getItem("UserId"),
        createdOn: "",
      },
    ];
    this.setState(currentState);
  }

  changeTask(e, index, currentValue, taskIndex) {
    this.state.goals[index].tasks[taskIndex][e.target.name] =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    this.setState({ goals: this.state.goals });
  }

  changeGoalLink(e, index, currentValue, linkIndex) {
    let currentState = this.state;
    currentState.goals[index].links[linkIndex][e.target.name] = e.target.value;
    this.setState(currentState);
  }

  addTasks(e, index) {
    let currentState = this.state;
    currentState.goals[index].tasks = [
      ...currentState.goals[index].tasks,
      {
        description: "",
        menteeAssignment: false,
        mentorAssignment: false,
        targetStartDate: "",
        targetEndDate: "",
        startDate: "",
        endDate: "",
      },
    ];

    this.setState(currentState);
  }

  addGoals(e) {
    e.preventDefault();
    this.setState({
      goals: [
        ...this.state.goals,
        {
          subject: "",
          description: "",
          startDate: "",
          endDate: "",
          tasks: [
            {
              description: "",
              menteeAssignment: false,
              mentorAssignment: false,
              targetStartDate: "",
              targetEndDate: "",
              startDate: "",
              endDate: "",
            },
          ],
          links: [
            {
              title: "",
              address: "",
              createdBy: localStorage.getItem("UserId"),
              createdOn: "",
            },
          ],
        },
      ],
    });
  }
  onDeleteGoal(e, index) {
    e.preventDefault();
    Swal.fire({
      title: "Are you sure you want to delete this goal?",
      text: "The information will be lost",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        this.state.goals.splice([index], 1);
        this.setState({
          goals: this.state.goals,
        });
      }
    });
  }

  onDeleteTask(e, index, taskIndex) {
    e.preventDefault();
    Swal.fire({
      title: "Are you sure you want to delete this task?",
      text: "The information will be lost",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        this.state.goals[index].tasks.splice([taskIndex], 1);
        this.setState({ goals: this.state.goals });
      }
    });
  }

  submitHandler(e) {
    e.preventDefault();
    e.target.className += " was-validated";
    var object = {
      goals: this.state.goals,
    };
    Swal.fire({
      title: "Alerta",
      text: "Recuerda cambiar las metas tambien en tus traducciones",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Enterado",
    }).then((result) => {
      if (result.value) {
        axios
          .post(
            direccion + "api/program/updateArrayGoals/" + this.state.programId,
            object,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("UserSession"),
                
              },
            }
          )
          .then((response) => {
            localStorage.setItem(
              "selectedProgram",
              JSON.stringify(response.data.programs)
            );
            Swal.fire({
              title: "Success",
              icon: "success",
              text: "Responsabilities updated correctly",
            }).then((result) => {
              if (result.value) {
                window.open("/programs/details/summary", "_self");
              }
            });
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: error.response.data.stack[4],
              icon: "error",
            });
          });
      }
    });
  }

  cancelHandler(e) {
    e.preventDefault();
    Swal.fire({
      title: "Are you sure you want to leave?",
      text: "The information will be lost",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        window.open("/programs/details/goalstasks", "_self");
      }
    });
  }

  handleButtonAnimation(e) {
    var position = e.target.className.toString();
    if (position.includes("collapsed") === true) {
      e.target.className =
        "btn btn-sm btn-primary w-100 fas fa-chevron-up collapsed";
    } else {
      e.target.className = "btn btn-sm btn-primary w-100 fas fa-chevron-down ";
    }
  }

  componentDidMount() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    today = mm + "-" + dd + "-" + yyyy;
    this.setState({
      todayDate: today,
    });
  }

  render() {
    return (
      <div className="container mt-4">
        <div className="col-12 text-center">
          <h3>{'Edit "' + this.state.programName + '" goals'}</h3>
        </div>
        <div className="col-12 text-right mt-4">
          <button className="ProgramAddNew" onClick={(e) => this.addGoals(e)}>
            {
              this.props.t('add')
            }
          </button>
        </div>
        <div className="col-12  mt-2 p-0">
          <form onSubmit={(e) => this.submitHandler(e)}>
            <div id="accordion">
              {this.state.goals.map((currentValue, index, array) => {
                return (
                  <div className="card my-1" key={index}>
                    <div className="card-header" id={"heading-" + index}>
                      <div className="row">
                        <div className="col">
                          <button
                            className="btn btn-sm btn-danger"
                            onClick={(e) => this.onDeleteGoal(e, index)}
                          >
                            <i className="fas fa-trash" aria-hidden="true"></i>
                          </button>
                        </div>
                        <div className="col-10 text-left ">
                          Goal subject:
                          <input
                            className="AddNewTitleInput form-control"
                            name="subject"
                            value={currentValue.subject}
                            onChange={(e) =>
                              this.changeGoal(e, index, currentValue)
                            }
                            required
                          ></input>
                        </div>
                        <div className="col">
                          <button
                            type="button"
                            onClick={(e) => this.handleButtonAnimation(e)}
                            className="btn btn-sm btn-primary w-100 fas fa-chevron-down"
                            data-toggle="collapse"
                            data-target={"#collapse" + index}
                            aria-expanded="true"
                            aria-controls={"collapse" + index}
                          ></button>
                        </div>
                      </div>
                    </div>

                    <div
                      id={"collapse" + index}
                      className="collapse show"
                      aria-labelledby="headingOne"
                      data-parent="#accordion"
                    >
                      <div className="card-body text-left">
                        <div className="row">
                          <div className="col-6"></div>
                          <div className="col-3 mb-3">
                            Start date:
                            <input
                              type="date"
                              className="AddNewTitleInput form-control"
                              name="startDate"
                              min={this.state.startDate.substring(0, 10)}
                              max={this.state.endDate.substring(0, 10)}
                              value={
                                !!currentValue.startDate
                                  ? currentValue.startDate.substring(0, 10)
                                  : ""
                              }
                              onChange={(e) =>
                                this.changeGoal(e, index, currentValue)
                              }
                              required
                            ></input>
                          </div>
                          <div className="col-3 mb-3">
                            End date:
                            <input
                              type="date"
                              className="AddNewTitleInput form-control"
                              name="endDate"
                              value={
                                !!currentValue.endDate
                                  ? currentValue.endDate.substring(0, 10)
                                  : ""
                              }
                              min={this.state.goals[index].startDate.substring(
                                0,
                                10
                              )}
                              max={this.state.endDate.substring(0, 10)}
                              onChange={(e) =>
                                this.changeGoal(e, index, currentValue)
                              }
                              required
                            ></input>
                          </div>
                          <div className="col-12">
                            Goal description:
                            <input
                              className="AddNewTitleInput form-control"
                              name="description"
                              value={currentValue.description}
                              onChange={(e) =>
                                this.changeGoal(e, index, currentValue)
                              }
                            ></input>
                          </div>

                          <div className="col-12 mt-3">
                            Goal Tasks:
                            <button
                              type="button"
                              className="ProgramAddNew"
                              onClick={(e) => this.addTasks(e, index)}
                            >
                              {
                                this.props.t('add')
                              }
                            </button>
                          </div>
                          <div className="col-12  mt-2 p-0">
                            <div id="tasksAccordion">
                              {this.state.goals[index].tasks.map(
                                (currentValue, taskIndex, array) => {
                                  return (
                                    <div className="card" key={taskIndex}>
                                      <div
                                        className="card-header"
                                        id={"heading-" + taskIndex}
                                      >
                                        <div className="row">
                                          <div className="col">
                                            <button
                                              className="btn btn-sm btn-danger"
                                              onClick={(e) =>
                                                this.onDeleteTask(
                                                  e,
                                                  index,
                                                  taskIndex
                                                )
                                              }
                                            >
                                              <i
                                                className="fas fa-trash"
                                                aria-hidden="true"
                                              ></i>
                                            </button>
                                          </div>
                                          <div className="col-8 text-left ">
                                            {this.state.goals[index].subject +
                                              " "}
                                            task No.{taskIndex + 1}
                                          </div>
                                          <div className="col-3">
                                            <button
                                              type="button"
                                              className="btn btn-sm btn-primary w-100 "
                                              data-toggle="collapse"
                                              data-target={
                                                "#collapseTasks" + taskIndex
                                              }
                                              aria-expanded="true"
                                              aria-controls={
                                                "collapseTasks" + taskIndex
                                              }
                                            >
                                              See / hide more
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        id={"collapseTasks" + taskIndex}
                                        className="collapse show"
                                        data-parent="#tasksAccordion"
                                      >
                                        <div className="card-body">
                                          <div className="row">
                                            <div className="col-3">
                                              Target start date:
                                              <input
                                                type="date"
                                                className="AddNewTitleInput form-control"
                                                name="targetStartDate"
                                                min={this.state.goals[
                                                  index
                                                ].startDate.substring(0, 10)}
                                                max={
                                                  !!this.state.goals[index]
                                                    .endDate
                                                    ? this.state.goals[
                                                        index
                                                      ].endDate.substring(0, 10)
                                                    : ""
                                                }
                                                value={
                                                  !!currentValue.targetStartDate
                                                    ? currentValue.targetStartDate.substring(
                                                        0,
                                                        10
                                                      )
                                                    : ""
                                                }
                                                onChange={(e) =>
                                                  this.changeTask(
                                                    e,
                                                    index,
                                                    currentValue,
                                                    taskIndex
                                                  )
                                                }
                                                required
                                              ></input>
                                            </div>
                                            <div className="col-3">
                                              Target end date:
                                              <input
                                                type="date"
                                                className="AddNewTitleInput form-control"
                                                name="targetEndDate"
                                                value={
                                                  !!currentValue.targetEndDate
                                                    ? currentValue.targetEndDate.substring(
                                                        0,
                                                        10
                                                      )
                                                    : ""
                                                }
                                                min={
                                                  !!this.state.goals[
                                                    index
                                                  ].tasks[
                                                    taskIndex
                                                  ].targetStartDate ? this.state.goals[
                                                    index
                                                  ].tasks[
                                                    taskIndex
                                                    ].targetStartDate.substring(
                                                    0,
                                                    10
                                                  ) : ""
                                                }
                                                max={
                                                  !!this.state.goals[index]
                                                    .endDate
                                                    ? this.state.goals[
                                                        index
                                                      ].endDate.substring(0, 10)
                                                    : ""
                                                }
                                                onChange={(e) =>
                                                  this.changeTask(
                                                    e,
                                                    index,
                                                    currentValue,
                                                    taskIndex
                                                  )
                                                }
                                                required
                                              ></input>
                                            </div>
                                            <div className="col"></div>
                                            <div className="col-2 text-center">
                                              <span>Mentee:</span>
                                              <br />
                                              <input
                                                className="AddNewCheckbox mt-1"
                                                type="checkbox"
                                                name="menteeAssignment"
                                                checked={
                                                  currentValue.menteeAssignment
                                                }
                                                value={
                                                  currentValue.menteeAssignment
                                                }
                                                onChange={(e) =>
                                                  this.changeTask(
                                                    e,
                                                    index,
                                                    currentValue,
                                                    taskIndex
                                                  )
                                                }
                                              ></input>
                                            </div>
                                            <div className="col-2 text-center">
                                              <span>Mentor:</span>
                                              <br />
                                              <input
                                                className="AddNewCheckbox mt-1"
                                                type="checkbox"
                                                name="mentorAssignment"
                                                checked={
                                                  currentValue.mentorAssignment
                                                }
                                                value={
                                                  currentValue.mentorAssignment
                                                }
                                                onChange={(e) =>
                                                  this.changeTask(
                                                    e,
                                                    index,
                                                    currentValue,
                                                    taskIndex
                                                  )
                                                }
                                              ></input>
                                            </div>

                                            <div className="col-12 mt-3">
                                              Task description:
                                              <input
                                                className="AddNewTitleInput"
                                                name="description"
                                                value={currentValue.description}
                                                onChange={(e) =>
                                                  this.changeTask(
                                                    e,
                                                    index,
                                                    currentValue,
                                                    taskIndex
                                                  )
                                                }
                                                required
                                              ></input>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </div>
                          <div className="col-12 mt-3">
                            Goal Links:
                            <button
                              type="button"
                              className="ProgramAddNew"
                              onClick={(e) => this.addGoalLink(e, index)}
                            >
                              {
                                this.props.t('add')
                              }
                            </button>
                          </div>
                          <div className="col-12 addNewContainer mt-2 p-3">
                            <div className="row addNewHeaders">
                              <div className="col-4">Title</div>
                              <div className="col-7 text-center">URL</div>
                            </div>
                            {this.state.goals[index].links.map(
                              (currentValue, linkIndex, array) => {
                                return (
                                  <div className="row py-2" key={index}>
                                    <div className="col-4">
                                      <input
                                        className="AddNewTitleInput"
                                        name="title"
                                        value={currentValue.title}
                                        onChange={(e) =>
                                          this.changeGoalLink(
                                            e,
                                            index,
                                            currentValue,
                                            linkIndex
                                          )
                                        }
                                      ></input>
                                    </div>
                                    <div className="col-7 text-center">
                                      <input
                                        className="AddNewTitleInput"
                                        name="address"
                                        value={currentValue.address}
                                        onChange={(e) =>
                                          this.changeGoalLink(
                                            e,
                                            index,
                                            currentValue,
                                            linkIndex
                                          )
                                        }
                                      ></input>
                                    </div>
                                    <div className="col-1 text-center">
                                      <button
                                        type="button"
                                        className="removeInput"
                                        onClick={(e) =>
                                          this.onDeleteGoalLinks(
                                            e,
                                            index,
                                            linkIndex
                                          )
                                        }
                                      >
                                        <i className="fas fa-times"></i>
                                      </button>
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>

            <div className="row float-right">
              <div className="col-12 my-4">
                <input
                  type="reset"
                  value="Cancel"
                  className="btn btn-outline-danger mr-2"
                  onClick={(e) => this.cancelHandler(e)}
                />
                <input type="submit" className="btn btn-primary" value="Save" />
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default withTranslation('Generals')(EditGoals);
