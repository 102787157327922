// Dependencies
import React, { Component } from "react";
import axios from "axios";
import Swal from "sweetalert2";

// Assets
import logo from "../../Assets/logo.png";

// Server URL
import serverURL from "../../serverURL";
import adminURL from "../../adminURL";
var direccion = serverURL;

class AdminConfirmation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
    };
  }

  hadleUserInput(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value });
  }


  render() {
    var direccionFront = adminURL + "/download";

    const Email = this.props.location.pathname.split("/").slice(1)[1];
    const Token = this.props.location.pathname.split("/").slice(1)[2];

      return (
        <div className="Container loginBG">
          <img src={logo} className="App-logo" alt="logo" />
          <div className="row">
            <div className="col-1 col-md-4"></div>
            <div className="col-10 col-md-4">
              <div className="card login-card">
                <div className="card-body">
                  <div className="card-title gray">Confirm Account</div>
                  <div className="container">
                    <form>
                      <div className="row">
                        <input
                          disabled
                          type="email"
                          id="inputEmail"
                          className="form-input"
                          name="email"
                          value={Email}
                          onChange={(event) => this.hadleUserInput(event)}
                        />
                      </div>
                      <button
                        onClick={() => {
                          axios
                            .post(
                              direccion +
                                "access/confirmAccount/" +
                                Email +
                                "/" +
                                Token
                            )
                            .then((response) => {
                              Swal.fire({
                                title: "Invite succesfully confirmed",
                                text:
                                  "You will be automatically redirected to the Login page",
                                timer: 4000,
                                timerProgressBar: true,
                                icon: "success",
                              }).then(() => {
                                window.open(direccionFront, "_self");
                              });
                            })
                            .catch((error) => {
                              Swal.fire({
                                title: "Something wen't wrong",
                                timer: 2000,
                                timerProgressBar: true,
                                icon: "error",
                              });
                            });
                        }}
                        type="button"
                        className="btn btn-primary loginButtons"
                      >
                        Accept
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-1 col-md-4"></div>
          </div>
        </div>
      );
  }
}

export default AdminConfirmation;
