// Dependencies
import React, {Component} from 'react';
import { withTranslation } from 'react-i18next';

// Components
import PropTypes from 'prop-types';

// Assets
import imgEx2 from '../../Assets/imgEx2.jpg';

class UserCards extends Component {
  static propTypes = {
    StarsNumber: PropTypes.number
  }

  render() {

    return (

      <div className="Users">
        <div className="UsersRows row ">

          <div className="col-2 UsersCols text-center">
            <img src={imgEx2} className="UserCardImg" alt="User|"/>
          </div>

          <div className="col-3 InfoUserCol text-left">
            <div className="col-12  UserInfoHeader Raleway text-left">{this.props.t('name')}</div>
            <div className=" col-12 Raleway ">
              {this.props.userName}
            </div>
          </div>

          <div className="col-5">
            <div className="row">
              <div className="col-12  UserInfoHeader Raleway text-left">{this.props.t('email')}</div>
            </div>
            <div className="row">
              <div className="col-3 text-center pt-1 Raleway">{this.props.email}</div>
            </div>
          </div>
          <div className="col-2">
            <div className="row">
              <div className="col-12  UserInfoHeader Raleway text-left">{this.props.t('role')}</div>
            </div>
            <div className="row">
              <div className="col-3 text-center pt-1">{this.props.role}</div>
            </div>
          </div>
        </div>
        <hr/>
      </div>

    )
  }
}

export default withTranslation('Generals')(UserCards);