// Dependecies
import React,{Component} from "react";


class Link extends Component{
    render(){
        return(
            <div className="col-3 link-card m-2">
                <h6>{this.props.title}</h6>
                <a href={this.props.address} target="_blank" rel="noopener noreferrer">{this.props.address}</a>
            </div>
        )
    }
}

export default Link;