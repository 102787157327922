// Dependencies
import React, { Component } from "react";
import axios from "axios";
// Server URL
import serverURL from "../../serverURL";

// Components
import AddUserCard from "./AddUserCard";

var direccion = serverURL;

class AddUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      availableUsers: [],
      selectedUsers: [],
      showModal: "",
      displayModal: "none",
      invitesTotal: "",
      invitesCount: 0,
      invitesFailed: [],
      inviteStatus: "",
      isButtonDisabled: true,
      notAvailableLegend: "Not available users",
    };
  }

 async componentDidMount() {
    await axios
      .get(
        direccion +
          "api/institution/getAllUsersOfInstitution/" +
          this.props.institution,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("UserSession"),
          },
        }
      )
      .then((response) => {
        this.setState({ responseUsers: response.data.institutions.users });
        this.setState({ currentUsers: this.props.currentUsers });
      })
     .catch((error) => { });
   
   await this.filterUsers();

  }

  async filterUsers() {
    var checkedUsers = [];
    for (let i = 0; i < this.state.responseUsers.length; i++) {
       if (this.state.responseUsers[i].userId) {
        checkedUsers.push(this.state.responseUsers[i]);
      }
    };

    var filtered = await checkedUsers.filter(
      (a) => !this.state.currentUsers.some((b) => a.userId._id === b.userId._id)
    );


    for (var i in filtered) {
      filtered[i].role = "mentee";
    }

    this.setState({ availableUsers: filtered });
    if (this.state.availableUsers.length != 0) {
      this.setState({
        isButtonDisabled: false,
        notAvailableLegend: "",
      });
    }
  }

  handleSelect(selectedUser) {
    if (
      !this.state.selectedUsers.find(
        (user) => user._id === selectedUser.userId._id
      )
    ) {
      var object = {
        _id: selectedUser.userId._id,
        role: selectedUser.role,
      };
      var currentArray = this.state.selectedUsers;
      currentArray.push(object);
      this.setState({ selectedUsers: currentArray });
    } else {
      var currentArray = this.state.selectedUsers;
      currentArray.splice(
        currentArray.findIndex((user) => user._id === selectedUser.userId._id),
        1
      );
      this.setState({ selectedUsers: currentArray });
    }
  }

  changeRole(e, r) {
    if (!this.state.selectedUsers.find((user) => user._id === r.userId._id)) {
      var i = this.state.availableUsers.findIndex(
        (user) => user.userId._id === r.userId._id
      );
      var currentAvailable = this.state.availableUsers;
      currentAvailable[i].role = e.target.value;
      this.setState({
        availableUsers: currentAvailable,
      });
    } else {
      var i = this.state.selectedUsers.findIndex(
        (user) => user._id === r.userId._id
      );
      var currentSelected = this.state.selectedUsers;
      currentSelected[i].role = e.target.value;
      this.setState({
        selectedUsers: currentSelected,
      });

      var j = this.state.availableUsers.findIndex(
        (user) => user.userId._id === r.userId._id
      );
      var currentAvailable = this.state.availableUsers;
      currentAvailable[j].role = e.target.value;
      this.setState({
        availableUsers: currentAvailable,
      });
    }
  }

  async processRequests(e) {
    this.setState({
      showModal: "show d-block personalized-modal",
      displayModal: "block",
    });
    var total = this.state.selectedUsers.length;
    this.setState({
      invitesTotal: total,
      invitesCount: 0,
      inviteStatus: "Request in process",
    });

    var responseArray = [];

    for (let i = 0; i < this.state.selectedUsers.length; i++) {
      const object = {
        rol: this.state.selectedUsers[i].role,
      };

      const inviteResponse = await axios
        .post(
          direccion +
            "api/program/addByAdmin/" +
            this.state.selectedUsers[i]._id +
            "/" +
            this.props.id,
          object,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("UserSession"),
              
            },
          }
        )
        .then((response) => {
          this.setState({ invitesCount: this.state.invitesCount + 1 });
          return response;
        })
        .catch((error) => {
          return error;
        });
      responseArray.push(inviteResponse);
    }

    localStorage.setItem(
      "selectedProgram",
      JSON.stringify(responseArray[responseArray.length - 1].data.programs)
    );
    this.setState({
      inviteStatus: "Invites sent",
    });
  }

  closeModal(e) {
    this.setState({
      showModal: "",
      displayModal: "none",
    });

    window.open("/programs/details/users", "_self");
  }

  render() {
    return (
      <div className="container-fluid px-4">
        <div className="row">
          <div className="col-12 text-center  my-4">
            <h5>Select the users, assign their roles and click submit:</h5>
          </div>
          <div className="col-12">
            {!!this.state.availableUsers
              ? this.state.availableUsers.map((result, index, array) => {
                  return (
                    <div className="Users" key={this.props.key}>
                      <div className="UsersRows row ">
                        <div className="col-2">
                          <input
                            className="AddNewCheckbox mt-2"
                            type="checkbox"
                            onClick={(e) => this.handleSelect(result)}
                          />
                        </div>

                        <div className="col-3 InfoUserCol text-left">
                          <div className="col-12  UserInfoHeader Raleway text-left">
                            Name
                          </div>
                          <div className=" col-12  mt-2">
                            {result.userId != undefined
                              ? !!result.userId.userName
                                ? result.userId.userName
                                : "No user name"
                              : "Invite not confirmed"}
                          </div>
                        </div>

                        <div className="col-3 InfoUserCol text-center">
                          <div className="col-12  UserInfoHeader Raleway text-center">
                            Mail
                          </div>
                          <div className=" col-12  mt-2">{result.mail}</div>
                        </div>

                        <div className="col-2">
                          <div className="row">
                            <div className="col-12  UserInfoHeader Raleway text-center">
                              Role
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12 text-center pt-1">
                              <select
                                name="role"
                                id=""
                                value={result.role}
                                className="form-control input-sm"
                                onChange={(e) => this.changeRole(e, result)}
                              >
                                <option value="mentor">mentor</option>
                                <option value="mentee">mentee</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />
                    </div>
                  );
                })
              : "Not available users"}
            {this.state.notAvailableLegend}
          </div>
        </div>
        <div className="row text-right px-5">
          <div className="col-12">
            <button
              className="btn btn-primary"
              onClick={(e) => this.processRequests(e)}
              disabled={this.state.isButtonDisabled}
            >
              Submit
            </button>
          </div>
        </div>

        {/***** Modal ******/}
        <div
          className={"modal fade " + this.state.showModal}
          id="exampleModalCenter"
          tabindex="-1"
          style={{
            display: `${this.state.display}`,
          }}
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalCenterTitle">
                  {this.state.inviteStatus}
                </h5>
                <button
                  type="button"
                  className="close"
                  onClick={(e) => this.closeModal(e)}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                Sending invites to users emails.{" "}
                {this.state.invitesCount + " / " + this.state.invitesTotal}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AddUsers;
