import React from "react";
import { Pie } from "react-chartjs-2";

class PieCharts extends React.Component {
  state = {
     chartOptions: {
         responsive: true,
         layout: {
           padding: {
             left: 15,
             right: 15,
             top: 5,
             bottom: 10,
           },
         },
         legend: {
           position: "bottom",
           labels: {
             fontColor: "#7553BB",
             boxWidth: 15,
             fontFamily: "Raleway",
             fontStyle: "bold",
             padding: 20,
           },
         },
         tooltips: {
           mode: "label",
         },
         elements: {
           line: {
             fill: false,
           },
         },
       },
    chartData: {
         labels: this.props.dataArray.labels,
         datasets: [{
           data: this.props.dataArray.tasks,
           backgroundColor: [
             '#3C2865cc',
             '#5B9BD5cc',
             '#3BD4C0cc',
             '#24183Dcc',
             '#255E91cc',
             '#1D8578cc',
             '#5B3D9Acc'
           ],
           hoverBackgroundColor: [
             '#3C2865',
             '#5B9BD5',
             '#3BD4C0',
             '#24183D',
             '#255E91',
             '#1D8578',
             '#5B3D9A'
           ]
         }],
       },
       dataPicker: 0,
     }

  render() {
    return (
        <div className="chart-card">
            <h4 className="chart-header">{this.props.label}</h4>
            <Pie data={this.state.chartData} options={this.state.chartOptions} />
        </div>
    );
  }
}

export default PieCharts;