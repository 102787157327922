// Dependencies
import React, { Component } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import AddUserCard from "./AddUserCard";
import readXlsxFile from "read-excel-file";

// Assets
import usuariosFile from "../../Assets/Templates/massive-match.xlsx";

// Server URL
import serverURL from "../../serverURL";
import { withTranslation } from "react-i18next";
var direccion = serverURL;

class MassiveMatch extends Component {
  constructor(props) {
    super(props);
    var program = JSON.parse(localStorage.getItem("selectedProgram"));
    this.state = {
      buttonStatus: true,
      saveStatus: false,
      matchArray: [
        {
          mentorEmail: "",
          menteeEmail: "",
        },
      ],
      programId: program._id,
      file: {},
      fileName: "",
      showModal: "",
      displayModal: "none",
      invitesTotal: "",
      invitesCount: 0,
      invitesFailed: [],
      inviteStatus: "",
      errors: [],
    };
  }

  hadleUserInput(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value });
  }

  changeInput(e, index, currentValue) {
    this.state.matchArray[index][e.target.name] = e.target.value;
    this.setState({ matchArray: this.state.matchArray });
  }

  onChangeHandler = (event) => {
    readXlsxFile(event.target.files[0]).then((data) => {
      this.setState({ file: data });
    });
    this.setState({
      fileName: event.target.files[0].name,
      buttonStatus: false,
      saveStatus: true,
    });
  };

  convert(e) {
    e.preventDefault();
    var currentArray = this.state.file;
    var a = currentArray.slice(1, currentArray.length);
    a.forEach((element, index, array) => {
      this.state.matchArray[index] = {
        mentorEmail: element[0],
        menteeEmail: element[1],
      };
    });

    this.setState({ matchArray: this.state.matchArray, saveStatus: false });
  }

  closeModal(e) {
    this.setState({
      showModal: "",
      displayModal: "none",
    });
    window.open("/programs/details/matches", "_self");
  }

  async submitHandler(e) {
    e.preventDefault();
    e.target.className += " was-validated";
    this.setState({
      showModal: "show d-block personalized-modal",
      displayModal: "block",
    });

    var total = this.state.matchArray.length;
    this.setState({
      invitesTotal: total,
      invitesCount: 0,
      inviteStatus: "Request in process",
    });

    var responseArray = [];

    for (let i = 0; i < this.state.matchArray.length; i++) {
      const object = {
        mentorEmail: this.state.matchArray[i].mentorEmail,
        menteeEmail: this.state.matchArray[i].menteeEmail,
      };

      const inviteResponse = await axios
        .post(
          direccion + "api/program/matchForcedByAdmin/" + this.state.programId,
          object,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("UserSession"),
              
            },
          }
        )
        .then((response) => {
          this.setState({ invitesCount: this.state.invitesCount + 1 });
          return response;
        })
        .catch((error) => {
          var currentErrors = this.state.errors;
          var obj = {
            mentorEmail: this.state.matchArray[i].mentorEmail,
            menteeEmail: this.state.matchArray[i].menteeEmail,
            problem:
              error.response.data.stack[error.response.data.stack.length - 1],
          };
          currentErrors.push(obj);
          this.setState({ errors: currentErrors });
          return error;
        });
      responseArray.push(inviteResponse);
    }
    this.setState({
      inviteStatus: "Process Finished",
    });
  }

  cancelHandler(e) {
    e.preventDefault();
    Swal.fire({
      title: "Are you sure you want to leave?",
      text: "The information will be lost",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        window.open("/programs/details/matches", "_self");
      }
    });
  }

  addMatchObject(e) {
    e.preventDefault();
    this.setState({
      matchArray: [
        ...this.state.matchArray,
        {
          mentorEmail: "",
          menteeEmail: "",
        },
      ],
    });
  }

  onDelete(e, index) {
    e.preventDefault();
    this.state.matchArray.splice([index], 1);
    this.setState({ matchArray: this.state.matchArray });
  }

  render() {
    return (
      <div className="container mt-4">
        <div className="row text-center">
          <div className="col-12">
            <h3>{this.props.t("add_users")}</h3>
          </div>
        </div>
        <form className="text-left">
          <div className="col-12 p-0 mt-4">
            <button
              className="ProgramAddNew"
              onClick={(e) => this.addMatchObject(e)}
            >
              {this.props.t("add")}
            </button>
          </div>
          <div className="col-12 addNewContainer mt-2 p-3">
            <div className="row addNewHeaders">
              <div className="col-5 text-center">Mentor</div>
              <div className="col-5 text-center">Mentee</div>
              <div className="col-2"></div>
            </div>
            {this.state.matchArray.map((currentValue, index, array) => {
              return (
                <div className="row py-2" key={index}>
                  <div className="col-5">
                    <input
                      className="AddNewTitleInput"
                      name="mentorEmail"
                      value={currentValue.mentorEmail}
                      onChange={(e) => this.changeInput(e, index, currentValue)}
                    ></input>
                  </div>
                  <div className="col-5">
                    <input
                      className="AddNewTitleInput"
                      name="menteeEmail"
                      value={currentValue.menteeEmail}
                      onChange={(e) => this.changeInput(e, index, currentValue)}
                    ></input>
                  </div>
                  <div className="col-2 text-center">
                    <button
                      className="removeInput"
                      onClick={(e) => this.onDelete(e, index)}
                    >
                      <i className="fas fa-times"></i>
                    </button>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="row">
            <div className="col-6">
              <div className="my-4 inputArea">
                <label>
                  <b>Step 1: Dowload the template file</b>
                </label>
                <label className="personalizedFile2">
                  <i
                    style={{
                      color: "gray",
                      fontSize: "70px",
                      marginBottom: "5px",
                    }}
                    className="fas fa-file-download"
                  ></i>
                  <br />
                  User template
                </label>
                <a className="m-3 convert" href={usuariosFile} download>
                  Download
                </a>
              </div>
            </div>
            <div className="col-6">
              <div className="my-4 inputArea">
                <label>
                  <b>Step 2: Upload the edited file and the click on convert</b>
                </label>
                <div className="excelFile">
                  <input
                    id="file"
                    className="file m-3"
                    type="file"
                    name="file"
                    onChange={this.onChangeHandler}
                  />
                  <span></span>
                  <label className="personalizedFile" for="file">
                    <i className="far fa-file-excel"></i>
                    <br />
                    Choose a file
                  </label>
                </div>

                <button
                  className="m-3 convert"
                  onClick={(e) => this.convert(e)}
                  disabled={this.state.buttonStatus}
                >
                  Convert {" " + this.state.fileName}
                </button>
              </div>
            </div>
          </div>

          <div className="row float-right">
            <div className="col-12 mb-4">
              <input
                type="reset"
                value="Cancel"
                className="btn btn-outline-danger mr-2"
                onClick={(e) => this.cancelHandler(e)}
              />
              <input
                type="submit"
                className="btn btn-primary"
                value="Save"
                disabled={this.state.saveStatus}
                onClick={(e) => this.submitHandler(e)}
              />
            </div>
          </div>
        </form>

        {/***** Modal ******/}
        <div
          className={"modal fade " + this.state.showModal}
          id="exampleModalCenter"
          tabindex="-1"
          style={{
            display: `${this.state.display}`,
          }}
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalCenterTitle">
                  {this.state.inviteStatus}
                </h5>
                <button
                  type="button"
                  className="close"
                  onClick={(e) => this.closeModal(e)}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="text-left">
                  <strong className="text-success">Satisfactory matches:</strong>
                  {" " + this.state.invitesCount + " / " + this.state.invitesTotal} <br />
                  <strong className="text-danger">Errors:</strong>
                  <div className="row">
                    <div className="col-4 text-center">Mentor</div>
                    <div className="col-4 text-center">Mentee</div>
                    <div className="col-4 text-center">Problem</div>
                  </div>
                  <hr />
                  {this.state.errors.map((currentValue, index, array) => {
                    return (
                      <div className="row">
                        <div className="col-4 text-break text-center">
                          {currentValue.mentorEmail}
                        </div>
                        <div className="col-4 text-break text-center">
                          {currentValue.menteeEmail}
                        </div>
                        <div className="col-4 text-break text-center">
                          {currentValue.problem}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation("Generals")(MassiveMatch);
