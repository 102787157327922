// Dependencies
import React, { Component } from "react";
import { Link } from "react-router-dom";

// Components
import PropTypes from "prop-types";

// Assets
import imgEx2 from "../../Assets/imgEx2.jpg";
import { withTranslation } from "react-i18next";

class InstitutionalUserCards extends Component {
  static propTypes = {
    StarsNumber: PropTypes.number,
  };

  showPredefined(v) {
    if (v) {
      return <i className="fas fa-check-circle"></i>;
    } else {
      return <i className="fas fa-times-circle"></i>;
    }
  }

    showName(v) {
        if (v === "Invite not confirmed yet") {
            return <b style={{ color: "red" }}>Invite not confirmed yet</b>;
        } else {
            return v
        }
  }

  render() {
    return (
      <div className="Users">
        <div className="UsersRows row ">
          <div className="col UsersCols text-center">
            <img src={imgEx2} className="UserCardImg" alt="User|" />
          </div>

          <div className="col-4 InfoUserCol text-left">
            <div className="col-12  UserInfoHeader Raleway text-left">{this.props.t('name')}</div>
            <div className=" col-12 Raleway ">
              {this.showName(this.props.userName)}
            </div>
          </div>

          <div className="col-3">
            <div className="row">
              <div className="col-12  UserInfoHeader Raleway text-left">
                {this.props.t('email')}
              </div>
            </div>
            <div className="row">
              <div className="col-3 text-center pt-1 Raleway">
                {this.props.email}
              </div>
            </div>
          </div>
          <div className="col-3 ">
            <div className="row">
              <div className="col-12  UserInfoHeader Raleway text-center">
                {this.props.t('is_predefined_mentor')}
              </div>
            </div>
            <div className="row">
              <div className="col-12 text-center pt-1">
                {this.showPredefined(this.props.isPredefinedMentor)}
              </div>
            </div>
          </div>
        </div>
        <hr />
      </div>
    );
  }
}

export default withTranslation('Generals')(InstitutionalUserCards);
