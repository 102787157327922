// Dependencies
import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

// Server URL
import serverURL from "../../serverURL";
import MatchCard from "./MatchCard";
import { withTranslation } from "react-i18next";
var direccion = serverURL;

class ProgramsMatches extends Component {
  constructor(props) {
    super(props);
    this.state = {
      oneToOnePrograms: []
    };
  }

  componentDidMount() {
    axios
      .get(direccion + "api/program/getAllProgramsOneToOne/" + this.props.id, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("UserSession"),
          
        },
      })
      .then((response) => {
        this.setState({
          oneToOnePrograms: response.data.programsOneToOne
        });
      })
      .catch((error) => {});
    }
  

  render() {
    const Print = () => {
        return this.state.oneToOnePrograms.map((result, index, array) => (
          <div>
            <MatchCard
              key={index}
              mentor={result.mentor.userName}
              mentee={result.mentee.userName}
              createdDate={result.createdDate.substring(0,10)}
            />
          </div>
        ));
    };

    return (
      <div className="container-fluid">
        <div className="row ">
          <div className="col-6 DescriptionHeaderTitle mt-3">
            <h5>{this.props.t('current_matches') }</h5>
          </div>
          <div className="col-6 text-right mt-3">
            <Link
              className="btn btn-sm btn-primary mr-1"
              to={"/programs/details/add/match"}
            >
              {this.props.t('assign_match') }
            </Link>
            <Link
              className="btn btn-sm btn-primary ml-1"
              to={"/programs/details/add/massive-match"}
            >
              {this.props.t('massive_match') }
            </Link>
          </div>
        </div>
        <hr />
        <div className="col-12">
          <Print />
        </div>
      </div>
    );
  }
}

export default withTranslation('Generals')(ProgramsMatches);
