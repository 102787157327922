// Dependencies
import React, { Component } from "react";
import { withTranslation } from "react-i18next";

class MatchCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  render() {
    return (
      <div className="Users">
        <div className="UsersRows row ">
          <div className="col-5">
            <div className="row">
              <div className="col-12  UserInfoHeader Raleway text-center">
                Mentee
              </div>
            </div>
            <div className="row">
              <div className="col-12 text-center">{this.props.mentee}</div>
            </div>
          </div>

          <div className="col-5">
            <div className="row">
              <div className="col-12  UserInfoHeader Raleway text-center">
                Mentor
              </div>
            </div>
            <div className="row">
              <div className="col-12 text-center">{this.props.mentor}</div>
            </div>
          </div>

          <div className="col-2">
            <div className="row">
              <div className="col-12  UserInfoHeader Raleway text-center">
                {this.props.t('start_date')}
              </div>
            </div>
            <div className="row">
              <div className="col-12 text-center">
                {this.props.createdDate}
              </div>
            </div>
          </div>
          
        </div>
        <hr />
      </div>
    );
  }
}

export default withTranslation('Generals')(MatchCard);
