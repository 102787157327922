// Dependencies 
import React,{Component} from 'react';
import {NavLink, Switch, Route,BrowserRouter} from 'react-router-dom';

// Components
import UserActivity from './UserActivity.js';
import MatchStatus from "./MatchStatus.js";
import Interests from "./Interests.js";
import GoalProgress from "./GoalProgress.js";

// CSS
import './Analytics.css'
import { withTranslation } from 'react-i18next';

class Analytics extends Component{
    render(){
        return (
          <div className="container-fluid">
            <BrowserRouter>
              <div className="SubMenu">
                <ul className="row">
                  <NavLink to="/analytics/user-activity" className="col">
                    <li>{this.props.t('analytics.users_last_activity')}</li>
                  </NavLink>
                  <NavLink to="/analytics/match-status" className="col">
                    <li>{this.props.t('analytics.match_status')}</li>
                  </NavLink>
                  <NavLink to="/analytics/goalsProgress" className="col">
                    <li>{this.props.t('analytics.goal_progress')}</li>
                  </NavLink>
                  <NavLink to="/analytics/interests" className="col">
                    <li>{this.props.t('interests')}</li>
                  </NavLink>
                </ul>
              </div>

              <Switch>
                <Route
                  path="/analytics/match-status"
                  render={(props) => <MatchStatus {...props} />}
                />
                <Route
                  path="/analytics/user-activity"
                  render={(props) => <UserActivity {...props} />}
                />
                <Route
                  path="/analytics/goalsProgress"
                  render={(props) => <GoalProgress {...props} />}
                />
                <Route
                  path="/analytics/interests"
                  render={(props) => <Interests {...props} />}
                />
              </Switch>
            </BrowserRouter>
          </div>
        );
    }
}

export default withTranslation('Generals')(Analytics);