// Dependecies
import React,{Component} from "react";

// Components
import LinkComponent from './Link';


import {Link} from 'react-router-dom';
import { withTranslation } from "react-i18next";

class ProgramsLinks extends Component{
    render(){

        var Print = () =>{
                return this.props.links.map((v,i,a) =>  <LinkComponent key={i} address = {v.address} title = {v.title} />
            )
        }

        return(
            <div className="px-4">
                <div className="row">
                    <div className="col"></div>
                    <div className="col-8 mt-3">
                    </div>
                    <div className="col mt-2">
                        <Link className="btn btn-primary"  to={"/programs/details/edit/links" }>{this.props.t('edit')}</Link>
                    </div>
                </div>
                <hr/>
                <div className="container-fluid">
                    <div className="row">
                        <Print/>
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation('Generals')(ProgramsLinks);