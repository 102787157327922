// Dependencies
import React, {
    Component
} from 'react';
import axios from "axios";
import Swal from 'sweetalert2'

// Assets
import imgEx2 from '../../Assets/imgEx2.jpg';

// Server URL
import serverURL from '../../serverURL'
var direccion = serverURL;

class AddUserCard extends Component {

    constructor(props) {
        var program = JSON.parse(localStorage.getItem('selectedProgram'));
        super(props);
        this.state = {
          role: this.props.role,
          programId: program._id,
          personalIntersts: [],
        };
    }
    
    render() {

    return (
      <div className="Users" key={this.props.key}>
        <div className="UsersRows row py-3 align-middle">
          <div className="col-4 InfoUserCol text-left">
            <div className="row">
              <div className="col-12  UserInfoHeader Raleway text-left">
                Name
              </div>
              <div className=" col-12  mt-2">{this.props.name}</div>
            </div>
          </div>
          <div className="col-6">
            <div className="row">
              <div className="col-12 UserInfoHeader Raleway ">Intereses</div>
              <div className="col-12">
                <div className="row m-1">
                  {this.props.professionalInterests.map(
                    (currentValue, index, array) => {
                      return (
                        <div className="badge badge-info col mx-1">
                          {currentValue.interest}
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-2 p-auto">
            <div className="row text-center h-100">
              <div className="col-12 my-auto">
                <input className="AddNewCheckbox mt-3" type="checkbox" />
              </div>
            </div>
          </div>
        </div>
        <hr />
      </div>
    );
  }
}

export default AddUserCard;