// Dependencies
import React, {Component} from 'react';
import axios from "axios";
import Swal from 'sweetalert2'

// Assets
import imgEx2 from '../../Assets/imgEx2.jpg';

// Server URL
import serverURL from '../../serverURL'
import { withTranslation } from 'react-i18next';
var direccion = serverURL;

class ProgramsUserCards extends Component {

  constructor(props) {
    var program = JSON.parse(localStorage.getItem('selectedProgram'));
    super(props);
    this.state = {
      role: this.props.role,
      programId: program._id
    }
  }

  changeRole(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({[name]: value});
    Swal
      .fire({
      title: 'Are you sure?',
      text: "You will change the role of " + this.props.userName,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    })
      .then((result) => {
        if (result.value) {
          axios.post(direccion + "api/program/UserRoleChange/" + this.state.programId, {
            newRole: this.state.role,
            userId: this.props._id
          }, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("UserSession"),
              
            }
          }).then((response) => {
            localStorage.setItem("selectedProgram", JSON.stringify(response.data.programs));
            Swal.fire({
              position: 'bottom-end',
              icon: 'success',
              title: this.props.userName + ' changed to ' + this.state.role ,
              showConfirmButton: false,
              timer: 1500,
              backdrop: false,
              timerProgressBar: true
            });
            
          }).catch((error) => {
            Swal.fire({
              position: 'bottom-end',
              icon: 'error',
              title: 'Something went wrong',
              text: error.data,
              showConfirmButton: false,
              timer: 1500,
              backdrop: false,
              timerProgressBar: true
            });
            if(this.state.role=="mentee"){
              this.setState({role: "mentor"})
            }else{
              this.setState({role: "mentee"})
            }
          });
        } else {
          if(this.state.role=="mentee"){
            this.setState({role: "mentor"})
          }else{
            this.setState({role: "mentee"})
          }
        }
      });
  }

  render() {

    return (

      <div className="Users" key={this.props.key}>
        <div className="UsersRows row ">

          <div className="col-2 UsersCols text-center">
            <img src={imgEx2} className="UserCardImg" alt="User"/>
          </div>

          <div className="col-4 InfoUserCol text-left">
            <div className="col-12  UserInfoHeader Raleway text-left">{this.props.t('name')}</div>
            <div className=" col-12  mt-2">
              {this.props.userName}
            </div>
          </div>

          <div className="col-4 InfoUserCol text-center">
            <div className="col-12  UserInfoHeader Raleway text-center">{this.props.t('email')}</div>
            <div className=" col-12  mt-2">
              {this.props.mail}
            </div>
          </div>

          {/* <div className="col-2">
            <div className="row">
              <div className="col-12  UserInfoHeader Raleway text-center">Have access?</div>
            </div>
            <div className="row">
              <div className="col-12 text-center">
                <input
                  className="AddNewCheckbox mt-2"
                  type="checkbox"
                  name="menteeAssignment"
                  checked={this.props.active}
                  readOnly></input>
              </div>
            </div>
    </div>*/}

          <div className="col-2">
            <div className="row">
              <div className="col-12  UserInfoHeader Raleway text-center">{this.props.t('role')}</div>
            </div>
            <div className="row">
              <div className="col-12 text-center pt-1">
                <select
                  name="role"
                  id=""
                  value={this.state.role}
                  className="form-control input-sm"
                  onChange={(e) => this.changeRole(e)}>
                  <option value="mentor">mentor</option>
                  <option value="mentee">mentee</option>
                </select>
              </div>
            </div>
          </div>

        </div>
        <hr/>
      </div>

    )
  }
}

export default withTranslation('Generals')(ProgramsUserCards);