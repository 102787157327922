// Dependecies
import React, { Component } from "react";
import Swal from "sweetalert2";
import axios from "axios";

// Server URL
import serverURL from "../../serverURL";
import { withTranslation } from "react-i18next";
var direccion = serverURL;

class EditResponsabilities extends Component {
  constructor(props) {
    var program = JSON.parse(localStorage.getItem("selectedProgram"));
    super(props);
    this.state = {
      programId: program._id,
      programName: program.name,
      responsabilities: program.responsabilities,
    };
  }

  onDeleteResponsabilities(e, index, currentValue) {
    e.preventDefault();
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        this.state.responsabilities.splice([index], 1);
        this.setState({ responsabilities: this.state.responsabilities });
      }
    });
  }

  addResponsabilitie(e) {
    e.preventDefault();
    this.setState({
      responsabilities: [
        ...this.state.responsabilities,
        {
          title: "",
          isMenteeOwner: false,
          isMentorOwner: false,
        },
      ],
    });
  }

  changeResponsabilitie(e, index, currentValue) {
    this.state.responsabilities[index][e.target.name] =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    this.setState({ responsabilities: this.state.responsabilities });
  }

  submitHandler(e) {
    e.preventDefault();
    e.target.className += " was-validated";
    var object = { responsabilities: this.state.responsabilities };
    Swal.fire({
      title: "Alerta",
      text: "Recuerda cambiar las responsabilidades tambien en tus traducciones",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Enterado",
    }).then((result) => {
      if (result.value) {
        axios
          .post(
            direccion +
            "api/program/updateArrayResponsabilities/" +
            this.state.programId,
            object,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("UserSession"),
                
              },
            }
          )
          .then((response) => {
            localStorage.setItem(
              "selectedProgram",
              JSON.stringify(response.data.programs)
            );
            Swal.fire({
              title: "Success",
              icon: "success",
              text: "Responsabilities updated correctly",
            }).then((result) => {
              if (result.value) {
                window.open("/programs/details/summary", "_self");
              }
            });
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: error.response.data.stack[4],
              icon: "error",
            });
          });
      }
    });
  }

  cancelHandler(e) {
    e.preventDefault();
    Swal.fire({
      title: this.props.t('alerts.are_you_sure'),
        text: this.props.t('alerts.information_will_be_lost'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.props.t('yes'),
        cancelButtonText: this.props.t('cancel')
    }).then((result) => {
      if (result.value) {
        window.open("/programs/details/summary", "_self");
      }
    });
  }

  render() {
    return (
      <div className="container mt-4">
        <div className="col-12 text-center">
          <h3>{this.props.t('edit') + " " + this.props.t('responsabilities') + " (" + this.state.programName + ")"}</h3>
        </div>
        <div className="col-12 text-right mt-4">
          <button
            className="ProgramAddNew"
            onClick={(e) => this.addResponsabilitie(e)}
          >
            {
              this.props.t('add')
            }
          </button>
        </div>
        <form
          className=" needs-validation"
          onSubmit={(e) => this.submitHandler(e)}
        >
          <div className="col-12 addNewContainer mt-2 p-3">
            <div className="row addNewHeaders">
              <div className="col-7">{this.props.t('responsability')}</div>
              <div className="col-2 text-center">Mentee</div>
              <div className="col-2 text-center">Mentor</div>
            </div>

            {this.state.responsabilities.map((currentValue, index, array) => {
              return (
                <div className="row py-2" key={index}>
                  <div className="col-7">
                    <input
                      className="AddNewTitleInput form-control"
                      name="title"
                      value={currentValue.title}
                      onChange={(e) =>
                        this.changeResponsabilitie(e, index, currentValue)
                      }
                      required
                    />
                    <div className="valid-feedback">Looks good!</div>
                    <div className="invalid-feedback">
                      Please fill this field
                    </div>
                  </div>
                  <div className="col-2 text-center">
                    <input
                      className="AddNewCheckbox"
                      type="checkbox"
                      name="isMenteeOwner"
                      checked={currentValue.isMenteeOwner}
                      onChange={(e) =>
                        this.changeResponsabilitie(e, index, currentValue)
                      }
                    ></input>
                  </div>
                  <div className="col-2 text-center">
                    <input
                      className="AddNewCheckbox"
                      type="checkbox"
                      name="isMentorOwner"
                      checked={currentValue.isMentorOwner}
                      onChange={(e) =>
                        this.changeResponsabilitie(e, index, currentValue)
                      }
                    ></input>
                  </div>
                  <div className="col-1 text-center">
                    <button
                      className="removeInput"
                      onClick={(e) =>
                        this.onDeleteResponsabilities(e, index, currentValue)
                      }
                    >
                      <i className="fas fa-times"></i>
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="row float-right">
            <div className="col-12 my-4">
              <input
                type="reset"
                value={this.props.t('cancel')}
                className="btn btn-outline-danger mr-2"
                onClick={(e) => this.cancelHandler(e)}
              />
              <input type="submit" className="btn btn-primary" value={this.props.t('save')} />
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default withTranslation('Generals')(EditResponsabilities);
