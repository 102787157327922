// Dependecies
import React,{Component} from "react";
import { withTranslation } from "react-i18next";

// Components
import Task from './Tasks'

class Goals extends Component{
    render(){
        return(
            <div className="row goal">
                <div className="col-12">
                    <div className="row text-left pt-3">
                        <div className="col-6 goal-header">{this.props.t('goal')}</div>
                        <div className="col-3 goal-header">{this.props.t('start_date') }</div>
                        <div className="col-3 goal-header">{this.props.t('end_date') }</div>
                    </div>
                    <div className="row text-left py-3">
                        <div className="col-6">{!!(this.props.description)?this.props.description:""}</div>
                        <div className="col-3">{!!(this.props.startDate)?this.props.startDate.substring(0,10):""}</div>
                        <div className="col-3">{!!(this.props.endDate)?this.props.endDate.substring(0,10):""}</div>
                    </div>
                </div>
                <div className="col-12 Task">
                    <div className="row py-3 showTaskList">
                        <div className="col-12 text-center" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample"><strong>{this.props.t('show_tasks') }&nbsp;&nbsp;<i className="fas fa-chevron-down"></i></strong></div>
                    </div>
                    <div className="py-3 collapse" id="collapseExample">
                        <div className="col-12">
                            <div className="row  TaskHeader">
                                <div className="col-4 text-left">{this.props.t('task') }</div>
                                <div className="col-2 text-center">{this.props.t('start_date') }</div>
                                <div className="col-2 text-center">{this.props.t('end_date') }</div>
                                <div className="col-2  text-center">Mentor</div>
                                <div className="col-2  text-center">Mentee</div>
                            </div>
                            <hr/>
                        </div>
                        {this.props.tasks.map((v,i,a) => 
                                {return  <Task
                                        key={i}
                                        description= {v.description}
                                        durationInDays= {v.durationInDays}
                                        endDate= {v.endDate}
                                        menteeAssignment= {v.menteeAssignment}
                                        mentorAssignment= {v.mentorAssignment}
                                        startDate = {v.startDate}
                                        targetEndDate={v.targetEndDate}
                                        targetStartDate={v.targetStartDate}
                                    />
                                } 
                            )
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation('Generals')(Goals);