// Dependencies
import React, { Component } from "react";
import axios from "axios";
import Swal from "sweetalert2";

// Server URL
import serverURL from "../../serverURL";
import { withTranslation } from "react-i18next";
var direccion = serverURL;

class AddProgram extends Component {
  constructor(props) {
    super(props);
    this.state = {
      todayDate: "",
      startDate: "",
      endDate: "",
      programName: "",
      programDesc: "",
      allowMenteMatchRequest : true,
      allowMenteeChangeRandR : true,
      allowMentorChangeRandR: true,
      maxMatchesPerMentor : 1,
      allowMenteeReopenProgram : true,
      allowMentorReopenProgram  : true,
      allowMentorAddNewGoals : true,
      allowMenteeAddNewGoals : true,
      responsabilities: [
        {
          title: "",
          isMenteeOwner: false,
          isMentorOwner: false,
        },
      ],
      links: [
        {
          title: "",
          address: "",
          createdBy: localStorage.getItem("UserId"),
          createdOn: "",
        },
      ],
      goals: [
        {
          subject: "",
          description: "",
          startDate: "",
          endDate: "",
          tasks: [
            {
              description: "",
              menteeAssignment: false,
              mentorAssignment: false,
              targetStartDate: "",
              targetEndDate: "",
              startDate: "",
              endDate: "",
            },
          ],
          links: [
            {
              title: "",
              address: "",
              createdBy: localStorage.getItem("UserId"),
              createdOn: "",
            },
          ],
        },
      ],
      language:"es"
    };
  }

  hadleUserInput(e) {
    const name = e.target.name;
    const value = e.target.type == "checkbox"?e.target.checked:e.target.value;
    this.setState({ [name]: value }, ()=>{/*console.log(this.state)*/});
  }

  changeResponsabilitie(e, index, currentValue) {
    this.state.responsabilities[index][e.target.name] =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    this.setState({ responsabilities: this.state.responsabilities });
  }

  changeGoal(e, index, currentValue) {
    this.state.goals[index][e.target.name] =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    this.setState({ goals: this.state.goals });
  }

  changeTask(e, index, currentValue, taskIndex) {
    this.state.goals[index].tasks[taskIndex][e.target.name] =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    this.setState({ goals: this.state.goals });
  }

  changeLink(e, index, currentValue) {
    this.state.links[index][e.target.name] = e.target.value;
    this.setState({ links: this.state.links });
  }

  changeGoalLink(e, index, currentValue, linkIndex) {
    let currentState = this.state;
    currentState.goals[index].links[linkIndex][e.target.name] = e.target.value;
    this.setState(currentState);
  }

  addResponsabilitie(e) {
    e.preventDefault();
    this.setState({
      responsabilities: [
        ...this.state.responsabilities,
        {
          title: "",
          isMenteeOwner: false,
          isMentorOwner: false,
        },
      ],
    });
  }

  addGoals(e) {
    e.preventDefault();
    this.setState({
      goals: [
        ...this.state.goals,
        {
          subject: "",
          description: "",
          startDate: "",
          endDate: "",
          tasks: [
            {
              description: "",
              menteeAssignment: false,
              mentorAssignment: false,
              targetStartDate: "",
              targetEndDate: "",
              startDate: "",
              endDate: "",
            },
          ],
          links: [
            {
              title: "",
              address: "",
              createdBy: localStorage.getItem("UserId"),
              createdOn: "",
            },
          ],
        },
      ],
    });
  }

  addTasks(e, index) {
    let currentState = this.state;
    currentState.goals[index].tasks = [
      ...currentState.goals[index].tasks,
      {
        description: "",
        menteeAssignment: false,
        mentorAssignment: false,
        targetStartDate: "",
        targetEndDate: "",
        startDate: "",
        endDate: "",
      },
    ];

    this.setState(currentState);
  }

  addGoalLink(e, index) {
    e.preventDefault();
    let currentState = this.state;
    currentState.goals[index].links = [
      ...currentState.goals[index].links,
      {
        title: "",
        address: "",
        createdBy: localStorage.getItem("UserId"),
        createdOn: "",
      },
    ];
    this.setState(currentState);
  }

  addLink(e) {
    e.preventDefault();
    this.setState({
      links: [
        ...this.state.links,
        {
          title: "",
          address: "",
          createdBy: localStorage.getItem("UserId"),
          createdOn: "",
        },
      ],
    });
  }

  onDeleteResponsabilities(e, index, currentValue) {
    e.preventDefault();
    this.state.responsabilities.splice([index], 1);
    this.setState({ responsabilities: this.state.responsabilities });
  }

  onDeleteLinks(e, index, currentValue) {
    e.preventDefault();
    this.state.links.splice([index], 1);
    this.setState({ responsabilities: this.state.responsabilities });
  }

  onDeleteGoalLinks(e, index, currentValue, linkIndex) {
    e.preventDefault();
    this.state.goals[index].links.splice([linkIndex], 1);
    this.setState({ responsabilities: this.state.responsabilities });
  }

  handleButtonAnimation(e) {
    var position = e.target.className.toString();
    if (position.includes("collapsed") === true) {
      e.target.className =
        "btn btn-sm btn-primary w-100 fas fa-chevron-up collapsed";
    } else {
      e.target.className = "btn btn-sm btn-primary w-100 fas fa-chevron-down ";
    }
  }

  onDeleteGoal(e, index) {
    e.preventDefault();
    Swal.fire({
      title: "Are you sure you want to delete this goal?",
      text: "The information will be lost",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        this.state.goals.splice([index], 1);
        this.setState({ goals: this.state.goals });
      }
    });
  }

  onDeleteTask(e, index, taskIndex) {
    e.preventDefault();
    Swal.fire({
      title: "Are you sure you want to delete this task?",
      text: "The information will be lost",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        this.state.goals[index].tasks.splice([taskIndex], 1);
        this.setState({ goals: this.state.goals });
      }
    });
  }

  cancelHandler(e) {
    e.preventDefault();
    Swal.fire({
      title: "Are you sure you want to leave?",
      text: "The information will be lost",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        window.open("/institutions/details/programs", "_self");
      }
    });
  }

  componentDidMount() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    today = yyyy + "-" + mm + "-" + dd;
    this.setState({
      todayDate: today,
    });
  }

  submitHandler(e) {
    e.preventDefault();
    axios
      .post(
        direccion +
          "api/program/createNew/" +
          this.props.location.pathname.split("/").slice(1)[3],
        this.state,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("UserSession"),
            
          },
        }
      )
      .then((response) => {
        localStorage.setItem(
          "selectedInstitution",
          JSON.stringify(response.data.institutions[0])
        );
        var user = JSON.parse(localStorage.getItem("User"));
        var userRole = user.role;
        if (userRole === "user") {
          axios
            .post(
              direccion + "api/user/dashboard/" + user._id,
              {
                module: "admin",
              },
              {
                headers: {
                  Authorization:
                    "Bearer " + localStorage.getItem("UserSession"),
                  
                },
              }
            )
            .then((response) => {
              localStorage.setItem(
                "Institutions",
                JSON.stringify(response.data.institutions)
              );
              localStorage.setItem(
                "Programs",
                JSON.stringify(response.data.programs)
              );
            });
        }
        Swal.fire({
          title: "Success",
          icon: "success",
          text: "Program created",
        }).then((result) => {
          if (result.value) {
            window.open("/institutions/details/programs", "_self");
          }
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error,
          icon: "error",
        });
      });
  }

  cancelHandler(e) {
    e.preventDefault();
    Swal.fire({
      title: "Are you sure you want to leave?",
      text: "The information will be lost",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        window.open("/programs/details/goalstasks", "_self");
      }
    });
  }

  render() {
    return (
      <div className="container my-3">
        <form onSubmit={(e) => this.submitHandler(e)} autocomplete="off">
          <div className="row text-left">
            <div className="col-6  mt-4">
              {this.props.t('name')}
              <input
                type="text"
                className="mt-2 form-control"
                name = "programName"
                value={this.state.programName}
                onChange={(event) => this.hadleUserInput(event)}
                required
              />
            </div>
            <div className="col-2 mt-4">
              {this.props.t('start_date')}
              <br />
              <input
                type="date"
                className="mt-2 w-100 form-control"
                name="startDate"
                min={this.state.todayDate}
                value={this.state.startDate}
                onChange={(event) => this.hadleUserInput(event)}
                required
              />
            </div>
            <div className="col-2 mt-4 mb-2">
              {this.props.t('end_date')}
              <br />
              <input
                type="date"
                className="mt-2 w-100 form-control"
                name="endDate"
                min={this.state.startDate.substring(0, 10)}
                value={this.state.endDate}
                onChange={(event) => this.hadleUserInput(event)}
                required
              />
            </div>
            
             <div className="col-2 mt-4 text-center">
              {this.props.t('language')}
              <br />
              <select
                className="selectP"
                name="language"
                id=""
                onChange={(event) => this.hadleUserInput(event)}
                value={this.state.language}
              >
                <option value="es" >{this.props.t("languages.spanish")}</option>
                <option value="en" >{this.props.t("languages.english")}</option>
                <option value="fr" >{this.props.t("languages.french")}</option>
                <option value="de" >{this.props.t("languages.deutsch")}</option>
                <option value="pt" >{this.props.t("languages.portuguese")}</option>
              </select>
            </div>
            <div className="col-12 mt-4">
              {this.props.t('description')} <span className="font-italic"> (Max 150 characters) </span>
              <input
                type="text"
                className="mt-2 form-control"
                name = "programDesc"
                value={this.state.programDesc}
                maxLength={150}
                onChange={(event) => this.hadleUserInput(event)}
                required
              />
            </div>
            <div className="col-6 mt-4">
              <div className="row">
                <strong> {this.props.t('gral_controls.gral_controls')}</strong>
              </div>
              <div className="row"><label>{this.props.t('gral_controls.allowMenteMatchRequest')}</label></div>
              <div className="row"><label>{this.props.t('gral_controls.allowMenteeChangeRandR')}</label></div>
              <div className="row"><label>{this.props.t('gral_controls.allowMentorChangeRandR')}</label></div>
              <div className="row"><label>{this.props.t('gral_controls.maxMatchesPerMentor')}</label></div>
              <div className="row"><label>{this.props.t('gral_controls.allowMenteeReopenProgram')}</label></div>
              <div className="row"><label>{this.props.t('gral_controls.allowMentorReopenProgram')}</label></div>
              <div className="row"><label>{this.props.t('gral_controls.allowMentorAddNewGoals')}</label></div>
              <div className="row"><label>{this.props.t('gral_controls.allowMenteeAddNewGoals')}</label></div>
            </div>
            <div className="col-6 mt-4">
              <div className="row">
                <strong>ON/OFF</strong>
              </div>
              <div className="row"><label><input type="checkbox" name="allowMenteMatchRequest" defaultChecked="true" onChange={(event) => this.hadleUserInput(event)} /></label></div>
              <div className="row"><label><input type="checkbox" name="allowMenteeChangeRandR" defaultChecked="true" onChange={(event) => this.hadleUserInput(event)} /></label></div>
              <div className="row"><label><input type="checkbox" name="allowMentorChangeRandR" defaultChecked="true" onChange={(event) => this.hadleUserInput(event)} /></label></div>
              <div className="row"><label><input type="number" min="1" name="maxMatchesPerMentor" value={this.state.maxMatchesPerMentor} onChange={(event) => this.hadleUserInput(event)}/></label></div>
              <div className="row"><label><input type="checkbox" name="allowMenteeReopenProgram" defaultChecked="true" onChange={(event) => this.hadleUserInput(event)} /></label></div>
              <div className="row"><label><input type="checkbox" name="allowMentorReopenProgram" defaultChecked="true" onChange={(event) => this.hadleUserInput(event)} /></label></div>
              <div className="row"><label><input type="checkbox" name="allowMentorAddNewGoals" defaultChecked="true" onChange={(event) => this.hadleUserInput(event)} /></label></div>
              <div className="row"><label><input type="checkbox" name="allowMenteeAddNewGoals" defaultChecked="true" onChange={(event) => this.hadleUserInput(event)} /></label></div>
            </div>
            <div className="col-12 mt-4">
              <div className="row">
                <div className="col-12 p-0 mt-4">
                  {this.props.t('goal')}s
                  <button
                    className="ProgramAddNew"
                    onClick={(e) => this.addGoals(e)}
                  >
                    {this.props.t('add')}
                  </button>
                </div>
                <div className="col-12  mt-2 p-0">
                  <div id="accordion">
                    {this.state.goals.map((currentValue, index, array) => {
                      return (
                        <div className="card" key={index}>
                          <div className="card-header" id={"heading-" + index}>
                            <div className="row">
                              <div className="col">
                                <button
                                  className="btn btn-sm btn-danger"
                                  onClick={(e) => this.onDeleteGoal(e, index)}
                                >
                                  <i
                                    className="fas fa-trash"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                              </div>
                              <div className="col-10 text-left">
                                <div className="row">
                                  <div className="col">{this.props.t('goal')}</div>
                                  <div className="col-10">
                                    <input
                                      className="AddNewTitleInput form-control"
                                      name="subject"
                                      value={currentValue.subject}
                                      onChange={(e) =>
                                        this.changeGoal(e, index, currentValue)
                                      }
                                      required
                                    ></input>
                                  </div>
                                </div>
                              </div>
                              <div className="col">
                                <button
                                  type="button"
                                  onClick={(e) => this.handleButtonAnimation(e)}
                                  className="btn btn btn-primary w-100 fas fa-chevron-down"
                                  data-toggle="collapse"
                                  data-target={"#collapse" + index}
                                  aria-expanded="true"
                                  aria-controls={"collapse" + index}
                                ></button>
                              </div>
                            </div>
                          </div>

                          <div
                            id={"collapse" + index}
                            className="collapse show"
                            aria-labelledby="headingOne"
                            data-parent="#accordion"
                          >
                            <div className="card-body">
                              <div className="row">
                                <div className="col-6"></div>
                                <div className="col-3 mb-3">
                                  {this.props.t('start_date')}
                                  <input
                                    type="date"
                                    className="AddNewTitleInput form-control"
                                    name="startDate"
                                    min={this.state.startDate}
                                    max={this.state.endDate}
                                    value={currentValue.startDate}
                                    onChange={(e) =>
                                      this.changeGoal(e, index, currentValue)
                                    }
                                    required
                                  ></input>
                                </div>
                                <div className="col-3 mb-3">
                                  {this.props.t('end_date')}
                                  <input
                                    type="date"
                                    className="AddNewTitleInput form-control"
                                    name="endDate"
                                    min={this.state.goals[
                                      index
                                    ].startDate.substring(0, 10)}
                                    max={this.state.endDate}
                                    value={currentValue.endDate}
                                    onChange={(e) =>
                                      this.changeGoal(e, index, currentValue)
                                    }
                                    required
                                  ></input>
                                </div>
                                <div className="col-12">
                                  {this.props.t('description')}
                                  <input
                                    className="AddNewTitleInput form-control"
                                    name="description"
                                    maxLength={150}
                                    value={currentValue.description}
                                    onChange={(e) =>
                                      this.changeGoal(e, index, currentValue)
                                    }
                                  ></input>
                                </div>

                                <div className="col-12 mt-3">
                                  {this.props.t('task')}s
                                  <button
                                    type="button"
                                    className="ProgramAddNew"
                                    onClick={(e) => this.addTasks(e, index)}
                                  >
                                    {
                                      this.props.t('add')
                                    }
                                  </button>
                                </div>
                                <div className="col-12  mt-2 p-0">
                                  <div id="tasksAccordion">
                                    {this.state.goals[index].tasks.map(
                                      (currentValue, taskIndex, array) => {
                                        return (
                                          <div className="card" key={taskIndex}>
                                            <div
                                              className="card-header"
                                              id={"heading-" + taskIndex}
                                            >
                                              <div className="row">
                                                <div className="col">
                                                  <button
                                                    className="btn btn-sm btn-danger"
                                                    onClick={(e) =>
                                                      this.onDeleteTask(
                                                        e,
                                                        index,
                                                        taskIndex
                                                      )
                                                    }
                                                  >
                                                    <i
                                                      className="fas fa-trash"
                                                      aria-hidden="true"
                                                    ></i>
                                                  </button>
                                                </div>
                                                <div className="col-8 text-left ">
                                                  {this.state.goals[index]
                                                    .subject + " "}
                                                  {this.props.t('task')} No.{taskIndex + 1}
                                                </div>
                                                <div className="col-3">
                                                  <button
                                                    type="button"
                                                    className="btn btn-sm btn-primary w-100 "
                                                    data-toggle="collapse"
                                                    data-target={
                                                      "#collapseTasks" +
                                                      taskIndex
                                                    }
                                                    aria-expanded="true"
                                                    aria-controls={
                                                      "collapseTasks" +
                                                      taskIndex
                                                    }
                                                  >
                                                    {this.props.t('see')}
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                            <div
                                              id={"collapseTasks" + taskIndex}
                                              className="collapse show"
                                              data-parent="#tasksAccordion"
                                            >
                                              <div className="card-body">
                                                <div className="row">
                                                  <div className="col-3">
                                                    {this.props.t('start_date')}
                                                    <input
                                                      type="date"
                                                      className="AddNewTitleInput form-control"
                                                      name="targetStartDate"
                                                      min={
                                                        this.state.goals[index]
                                                          .startDate
                                                      }
                                                      value={
                                                        currentValue.targetStartDate
                                                      }
                                                      onChange={(e) =>
                                                        this.changeTask(
                                                          e,
                                                          index,
                                                          currentValue,
                                                          taskIndex
                                                        )
                                                      }
                                                      required
                                                    ></input>
                                                  </div>
                                                  <div className="col-3">
                                                    {this.props.t('end_date')}
                                                    <input
                                                      type="date"
                                                      className="AddNewTitleInput form-control"
                                                      name="targetEndDate"
                                                      value={
                                                        currentValue.targetEndDate
                                                      }
                                                      min={this.state.goals[
                                                        index
                                                      ].tasks[
                                                        taskIndex
                                                      ].targetStartDate.substring(
                                                        0,
                                                        10
                                                      )}
                                                      max={
                                                        this.state.goals[index]
                                                          .endDate
                                                      }
                                                      onChange={(e) =>
                                                        this.changeTask(
                                                          e,
                                                          index,
                                                          currentValue,
                                                          taskIndex
                                                        )
                                                      }
                                                      required
                                                    ></input>
                                                  </div>
                                                  <div className="col"></div>
                                                  <div className="col-2 text-center">
                                                    <span>Mentee:</span>
                                                    <br />
                                                    <input
                                                      className="AddNewCheckbox mt-1"
                                                      type="checkbox"
                                                      name="menteeAssignment"
                                                      value={
                                                        currentValue.menteeAssignment
                                                      }
                                                      onChange={(e) =>
                                                        this.changeTask(
                                                          e,
                                                          index,
                                                          currentValue,
                                                          taskIndex
                                                        )
                                                      }
                                                    ></input>
                                                  </div>
                                                  <div className="col-2 text-center">
                                                    <span>Mentor:</span>
                                                    <br />
                                                    <input
                                                      className="AddNewCheckbox mt-1"
                                                      type="checkbox"
                                                      name="mentorAssignment"
                                                      value={
                                                        currentValue.mentorAssignment
                                                      }
                                                      onChange={(e) =>
                                                        this.changeTask(
                                                          e,
                                                          index,
                                                          currentValue,
                                                          taskIndex
                                                        )
                                                      }
                                                    ></input>
                                                  </div>

                                                  <div className="col-12 mt-3">
                                                    {this.props.t('description')}
                                                    <input
                                                      className="AddNewTitleInput form-control"
                                                      name="description"
                                                      value={
                                                        currentValue.description
                                                      }
                                                      maxLength={150}
                                                      onChange={(e) =>
                                                        this.changeTask(
                                                          e,
                                                          index,
                                                          currentValue,
                                                          taskIndex
                                                        )
                                                      }
                                                      required
                                                    ></input>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                </div>
                                <div className="col-12 mt-3">
                                  {this.props.t('links')}
                                  <button
                                    type="button"
                                    className="ProgramAddNew"
                                    onClick={(e) => this.addGoalLink(e, index)}
                                  >
                                    {
                                      this.props.t('add')
                                    }
                                  </button>
                                </div>
                                <div className="col-12 addNewContainer mt-2 p-3">
                                  <div className="row addNewHeaders">
                                    <div className="col-4">{this.props.t('name')}</div>
                                    <div className="col-7 text-center">URL</div>
                                  </div>
                                  {this.state.goals[index].links.map(
                                    (currentValue, linkIndex, array) => {
                                      return (
                                        <div className="row py-2" key={index}>
                                          <div className="col-4">
                                            <input
                                              className="AddNewTitleInput form-control"
                                              name="title"
                                              value={currentValue.title}
                                              onChange={(e) =>
                                                this.changeGoalLink(
                                                  e,
                                                  index,
                                                  currentValue,
                                                  linkIndex
                                                )
                                              }
                                              required
                                            ></input>
                                          </div>
                                          <div className="col-7 text-center">
                                            <input
                                              type="url"
                                              pattern="https?://.+"
                                              className="AddNewTitleInput form-control"
                                              name="address"
                                              value={currentValue.address}
                                              onChange={(e) =>
                                                this.changeGoalLink(
                                                  e,
                                                  index,
                                                  currentValue,
                                                  linkIndex
                                                )
                                              }
                                              required
                                            ></input>
                                          </div>
                                          <div className="col-1 text-center">
                                            <button
                                              type="button"
                                              className="removeInput"
                                              onClick={(e) =>
                                                this.onDeleteGoalLinks(
                                                  e,
                                                  index,
                                                  linkIndex
                                                )
                                              }
                                            >
                                              <i className="fas fa-times"></i>
                                            </button>
                                          </div>
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>

                <div className="col-12 p-0 mt-4">
                  {this.props.t('responsabilities')}
                  <button
                    className="ProgramAddNew"
                    onClick={(e) => this.addResponsabilitie(e)}
                  >
                    {
                      this.props.t('add')
                    }
                  </button>
                </div>
                <div className="col-12 addNewContainer mt-2 p-3">
                  <div className="row addNewHeaders">
                    <div className="col-7">{this.props.t('responsability')}</div>
                    <div className="col-2 text-center">Mentee</div>
                    <div className="col-2 text-center">Mentor</div>
                  </div>
                  {this.state.responsabilities.map(
                    (currentValue, index, array) => {
                      return (
                        <div className="row py-2" key={index}>
                          <div className="col-7">
                            <input
                              className="AddNewTitleInput  form-control"
                              name="title"
                              value={currentValue.title}
                              onChange={(e) =>
                                this.changeResponsabilitie(
                                  e,
                                  index,
                                  currentValue
                                )
                              }
                              required
                            ></input>
                          </div>
                          <div className="col-2 text-center">
                            <input
                              className="AddNewCheckbox"
                              type="checkbox"
                              name="isMenteeOwner"
                              checked={currentValue.isMenteeOwner}
                              onChange={(e) =>
                                this.changeResponsabilitie(
                                  e,
                                  index,
                                  currentValue
                                )
                              }
                            ></input>
                          </div>
                          <div className="col-2 text-center">
                            <input
                              className="AddNewCheckbox"
                              type="checkbox"
                              name="isMentorOwner"
                              checked={currentValue.isMentorOwner}
                              onChange={(e) =>
                                this.changeResponsabilitie(
                                  e,
                                  index,
                                  currentValue
                                )
                              }
                            ></input>
                          </div>
                          <div className="col-1 text-center">
                            <button
                              className="removeInput"
                              onClick={(e) =>
                                this.onDeleteResponsabilities(
                                  e,
                                  index,
                                  currentValue
                                )
                              }
                            >
                              <i className="fas fa-times"></i>
                            </button>
                          </div>
                        </div>
                      );
                    }
                  )}
                </div>
                <div className="col-12 p-0 mt-4">
                  {this.props.t('links')}
                  <button
                    className="ProgramAddNew"
                    onClick={(e) => this.addLink(e)}
                  >
                    {
                      this.props.t('add')
                    }
                  </button>
                </div>
                <div className="col-12 addNewContainer mt-2 p-3">
                  <div className="row addNewHeaders">
                    <div className="col-4">{this.props.t('name')}</div>
                    <div className="col-7 text-center">URL</div>
                  </div>
                  {this.state.links.map((currentValue, index, array) => {
                    return (
                      <div className="row py-2" key={index}>
                        <div className="col-4">
                          <input
                            className="AddNewTitleInput form-control"
                            name="title"
                            value={currentValue.title}
                            onChange={(e) =>
                              this.changeLink(e, index, currentValue)
                            }
                            required
                          ></input>
                        </div>
                        <div className="col-7 text-center">
                          <input
                            type="url"
                            pattern="https?://.+"
                            className="AddNewTitleInput form-control"
                            name="address"
                            value={currentValue.address}
                            onChange={(e) =>
                              this.changeLink(e, index, currentValue)
                            }
                            required
                          ></input>
                        </div>
                        <div className="col-1 text-center">
                          <button
                            className="removeInput"
                            onClick={(e) =>
                              this.onDeleteLinks(e, index, currentValue)
                            }
                          >
                            <i className="fas fa-times"></i>
                          </button>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>

          <div className="row float-right">
            <div className="col-12 my-4">
              <input
                type="reset"
                value={this.props.t('cancel')}
                className="btn btn-outline-danger mr-2"
                onClick={(e) => this.cancelHandler(e)}
              />
              <input type="submit" className="btn btn-primary" value={this.props.t('save')} />
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default withTranslation('Generals')(AddProgram);
