 // Dependencies
 import React from 'react';
 import {Route, Switch} from 'react-router-dom';
 import {CSSTransition,TransitionGroup} from 'react-transition-group';

 // Components
 import Main from './components/Main';
 import Dashboard from './components/Dashboard/Dashboard'
 import Programs from './components/Programs/Programs'
 import Institutions from './components/Institutions/Institutions'
 import InstitutionDetails from './components/Institutions/InstitutionDetails'
 import ProgramDetails from './components/Programs/ProgramDetails'
 import Users from './components/Users/Users'
 import UserDetails from './components/Users/UserDetails'
 import Analytics from './components/Analytics/Analytics'
 import AddInstitution from './components/Institutions/AddInstitution';
 import AddProgram from './components/Programs/AddProgram';
 import Notifications from './components/Notifications/Notifications';
 import AddUser from './components/Users/AddUser'

 var authRoutes;

 if("UserSession" && "User" in localStorage){
    authRoutes=true;
 } else {
    authRoutes=false;
 }
 

const PrivateRoute = ({component: Component, ...rest}) => (
    <Route {...rest} render={(props)=> (
        authRoutes===true ? <Component {...props}/> : window.open("/login","_self") 
    )}/>
)

 const AppRoutes = () => 
    <Main>
        <TransitionGroup>
        <CSSTransition  
        timeout={500}
        classNames="fade"
        >  
            <Switch>
                <PrivateRoute exact path="/" component={Dashboard}/>
                <PrivateRoute exact path="/dashboard" component={Dashboard}/>
                <PrivateRoute exact path="/programs" component={Programs}/>
                <PrivateRoute path="/programs/addnew" component={AddProgram}/>
                <PrivateRoute path="/programs/details" component={ProgramDetails}/>
                <PrivateRoute exact path="/institutions" component={Institutions}/>
                <PrivateRoute path="/institutions/details" component={InstitutionDetails}/>
                <PrivateRoute exact path="/institutions/addnew" component={AddInstitution}/>
                <PrivateRoute exact path="/users/addnew" component={AddUser}/>
                <PrivateRoute exact path="/users" component={Users}/>
                <PrivateRoute path="/users/details" component={UserDetails}/>
                <PrivateRoute path="/analytics" component={Analytics}/>
                <PrivateRoute exact path="/notifications" component={Notifications}/>
            </Switch>
        </CSSTransition>
      </TransitionGroup>   
    </Main>;

export default AppRoutes;