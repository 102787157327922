// Dependencies
import React,{Component} from 'react';

// Components
import SearchBar from '../Bars/SearchBar.js'


// CSS
import './Users.css';



class Mentors extends Component{

    

    render(){

        return(
            <div>
                <SearchBar
                    SearchType="user"
                    AddNewLink="/users/addnew"
                    CardType="UserCards"
                    hide=" d-none"
                />       
            </div>
        )
    }
}

export default Mentors;