// Dependencies
import React from "react";
import axios from "axios";
import {
  withTranslation
} from "react-i18next";

// Components
import SearchBar from "../Bars/SearchBar.js";
import InstitutionsCards from "./InstitutionsCards.js";

// CSS
import "./Institutions.css";

// Server URL
import serverURL from "../../serverURL";
var direccion = serverURL;

export class Institutions extends React.Component {
  constructor(props) {
    super(props);
    var userRole = JSON.parse(localStorage.getItem("User"));
    this.state = {
      currentInstitution: [
        {
          institutionReferenceId: ""
        }
      ],
      institutions: JSON.parse(localStorage.getItem("Institutions")),
      institutionsPrograms: [],
      userRole: userRole.role,
    };
  }

  selectedCard(institution, index) {
    console.log("selectes")
    var object = {
      isprogramTemplate: "true",
    };
    localStorage.setItem("selectedInstitution", JSON.stringify(institution));
    localStorage.setItem("InstitutionsUsers",  JSON.stringify(institution.users));
    localStorage.setItem("InstitutionsUsersLength", JSON.stringify(institution.usersLength));
    localStorage.setItem("selectedInstitutionIndex", JSON.stringify(index));


    axios
      .post(direccion + "api/institution/getTranslation/" + institution._id, {}, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("UserSession"),
          'Access-Control-Allow-Origin': "*"
        },
      })
      .then((response) => {
        localStorage.setItem(
          "institutionTranslations",
          JSON.stringify(response.data.translations)
        );
      });

    
    axios
      .post(
        direccion + "api/institution/getAllPrograms/" + institution._id,
        {
          isProgramTemplate: true,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("UserSession"),
            
          },
        }
      )
      .then((response) => {
        localStorage.setItem(
          "InstitutionsPrograms",
          JSON.stringify(response.data.programs)
        );
        window.open("/institutions/details/profile", "_self");
      })
      .catch((error) => {});
  }

  render() {

  if (this.state.currentInstitution[0].institutionReferenceId) {
    this.state.display = "none";
  } 


    const Print = () => {
      if (this.state.userRole === "sysadmin") {
        var local = localStorage.getItem("selectedInstitution");
        this.state.currentInstitution = [local];
        return (
          <div>
            <SearchBar
              AddNewLink="/institutions/addnew"
              SearchType="institution"
              hide=" d-md-block"
            />
          </div>
        );
      } else {
        return (
          <div className="">
            <div className="navbar navbar-dark SearchBar text-left row">
              <div className="col-7">
                <button
                  className="btn btn-outline-primary btn-sm px-2 py-0"
                  data-toggle="collapse"
                  data-target="#multiCollapseExample1"
                  aria-expanded="true"
                  aria-controls="multiCollapseExample1"
                >
                  
                  {this.props.t("show_institutions")}
                </button>
              </div>
            </div>
            {this.state.institutions.map((result, index, array) => (
              <div
                onClick={(e) => this.selectedCard(array[index],index)}
                id="multiCollapseExample1"
                className="show multi-collapse"
                key={index}
              >
                <InstitutionsCards
                  InstitutionName={result.institutionName}
                  InstitutionId={result._id}
                  programs={result.programs}
                  isActive={result.isActive}
                  image={result.image}
                />
              </div>
            ))}
          </div>
        );
      }
    };

    return (
      <div>
        <Print />
      </div>
    );
  }
}

export default withTranslation("Generals")(Institutions);
