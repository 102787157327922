import React, {Component} from "react";
import { withTranslation } from 'react-i18next';

class ResultsBar extends Component {

  render() {
    return (
      <div className="text-left results-bar">
        <span className="search-title">{this.props.SearchTitle}</span>
        <br/>
        <span className="search-results">{ this.props.t('results_bar.found') + this.props.SearchResults +  this.props.t('results_bar.results')}</span>
        <hr/>
      </div>

    )

  }

}

export default withTranslation('Generals')(ResultsBar);
