// Dependencies
import React from "react";

// Components
import BarCharts from "../Charts/BarCharts.js";
import axios from "axios";
import DataTable, { createTheme } from "react-data-table-component";

// Server URL
import serverURL from "../../serverURL";
import { withTranslation } from "react-i18next";
var direccion = serverURL;

export class MatchStatus extends React.Component {
         constructor(props) {
           super(props);
            var userId = localStorage.getItem("UserId");
           this.state = {
             userid: userId,
           };
         }

         componentDidMount() {
           var object = {
             module: "admin",
           };
           axios
             .get(
               direccion +
                 "api/institutionalAdmin/matchStatus/" +
                 this.state.userid,
               {
                 headers: {
                   Authorization:
                     "Bearer " + localStorage.getItem("UserSession"),
                      
                 },
               }
             )
             .then((response) => {
               this.setState({
                 matchStatus: response.data.matchStatus,
               });
             });
         }

         render() {
           return (
             <div>
               <div className="container-fluid">
                 <div className="row w-100">
                   <div className="col-8 my-4 mx-auto">
                       {this.state.matchStatus ? <BarCharts dataArray={this.state.matchStatus} label={this.props.t("charts.match_status")}/> : null }
                   </div>
                 </div>
               </div>
             </div>
           );
         }
       }

export default withTranslation('Generals')(MatchStatus);
