// Dependencies
import React, { Component } from "react";
import { withTranslation } from 'react-i18next';

// Assets
import imgEx from "../../Assets/imgEx.jpg";

class InstitutionsCards extends Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    if (this.props.image == undefined) {
      this.setState({
        img: imgEx,
      });
    } else {
      this.setState({
        img:
          "https://mentorme-staging-upload.s3-us-west-2.amazonaws.com/" +
          this.props.image,
      });
    }
  }

  render() {
    return (
      <div className="Institutions">
        <div className="row hoverCards">
          <div className="col-3 imageContainer " style={{ backgroundImage: `url(${this.state.img})` }} >
          </div>
          <div className="col-4 InfoInstitutionCol">
            <div className="row text-left">
              <div className="col-12  InfoHeader Raleway p-0">{this.props.t('name')}</div>
            </div>
            <div className="row InstitutionsDetails">
              <div className="InstitutionName Raleway text-center">
                <strong>{this.props.InstitutionName}</strong>
              </div>
            </div>
          </div>
          <div className="col-3">
            <div className="row">
              <div className="col-12 text-center InfoHeader Raleway">
                {this.props.t('programs')}
              </div>
            </div>
            <div className="row InstitutionsDetails">
              <div className="col-12 text-center ">
                <strong>{this.props.programs.length}</strong>
              </div>
            </div>
          </div>
          <div className="col-2">
            <div className="row">
              <div className="col-12 text-center InfoHeader Raleway">
                {this.props.t('status')}
              </div>
            </div>
            <div className="row InstitutionsDetails">
              <div className="col-12 text-center ">
                {(() => {
                  switch (this.props.isActive) {
                    case true:
                      return <i className="fas fa-check-circle"></i>;
                    case false:
                      return <i className="fas fa-times-circle"></i>;
                    default:
                      return <i className="fas fa-check-circle"></i>;
                  }
                })()}
              </div>
              <div className="col-10 text-left p-0">{this.props.Status}</div>
            </div>
          </div>
        </div>
        <hr />
      </div>
    );
  }
}

export default withTranslation('Generals')(InstitutionsCards);
