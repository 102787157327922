// Dependencies
import React from "react";
import exportFromJSON from "export-from-json";

// Components
import LineCharts from "../Charts/LineCharts.js";
import axios from "axios";
import DataTable, { createTheme } from "react-data-table-component";

// Server URL
import serverURL from "../../serverURL";
import { withTranslation } from "react-i18next";
var direccion = serverURL;

export class UserActivity extends React.Component {
  constructor(props) {
    super(props);
    var data = JSON.parse(localStorage.getItem("Data"));
    var userId = localStorage.getItem("UserId");
    var count = data;
    this.state = {
      userid: userId,
      programsCount: count.programs,
      matchesCount: count.matches,
      lastActivityTable: [],
      columns: [
        {
          name: "User",
          //selector: "user",
          selector: (row) => row.userEmail.toString(),
          sortable: true,
        },
        /*{
          name: "Status",
          selector: "userstatus",
          sortable: true,
        },*/
        {
          name: "Institution",
          selector: "institution",
          sortable: true,
        },
        {
          name: "Assigned to program",
          selector: (row) => row.program.toString(),
          sortable: true,
        },
        {
          name: "Program role",
          selector: "userRole",
          sortable: true,
        },
        {
          name: "Last activity",
          selector: (row) => row.userLastActivityDate.toString().substring(0,10),
          sortable: true,
        },
        {
          name: "Progress",
          selector: (row) => (row.progress * 100) + " %" ,
          sortable: true,
        }
      ],
    };
  }

  componentDidMount() {
    var object = {
      module: "admin",
    };
    axios
      .post(
        direccion + "api/institutionaladmin/dashboard/" + this.state.userid,
        object,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("UserSession"),
            
          },
        }
      )
      .then((response) => {
        this.setState({
          lastActivityTable: response.data.lastActivityTable,
          lastActivityDataset: response.data.lastActivityDataset,
        });
      });
  }

  downloadLastActivity(e) {
    e.preventDefault();
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();
    today = mm + "-" + dd + "-" + yyyy;

    var Name = "User_Last_Activity_" + today;
    var dataExport = this.state.lastActivityTable;
    var dataType = 'csv';

    exportFromJSON({
      data: dataExport,
      fileName: Name,
      exportType: exportFromJSON.types.csv,
    });
  }

  render() {
    return (
      <div>
        <div className="container-fluid">
          <div className="row w-100">
            <div className="row w-100">
              <div className="col-2 mt-3 ml-auto">
                <button
                  className="btn btn-outline-success w-100"
                  onClick={(e) => this.downloadLastActivity(e)}
                >
                  {this.props.t('download')}
                  <i
                    style={{
                      marginLeft: "5px",
                    }}
                    className="far fa-file-excel"
                  ></i>
                </button>
              </div>
            </div>
            <div className="row w-100">
              <div className="col-12 mt-3">
                <div className="card tab-card">
                  <div className="card-header tab-card-header text-center mb-0">
                    <h3>{this.props.t('analytics.users_last_activity')}</h3>
                  </div>
                  <DataTable
                    columns={this.state.columns}
                    data={this.state.lastActivityTable}
                    pagination={true}
                    paginationPerPage={5}
                    paginationRowsPerPageOptions={[5, 10, 15]}
                    highlightOnHover={true}
                  />
                </div>
              </div>
            </div>
            <div className="row w-100">
              <div className="col-8 mx-auto my-3">
                {this.state.lastActivityDataset ? (
                  <LineCharts
                    dataArray={this.state.lastActivityDataset}
                    label={this.props.t('users')}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation('Generals')(UserActivity);
