// Dependencies
import React, {Component} from 'react';

// Components
import RequestsCards from './RequestsCards';

class ProgramsRequests extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }
  render() {

    const Print = () => {
      if (this.props.requestProgramAccess != "") {
        return this
          .props
          .requestProgramAccess
          .map((result, index, array) => {
            "M"
          });
      } else {
        return "No requests to join this program"
      }
    }

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 DescriptionHeaderTitle my-4">
            <h5>Requests to join this program</h5>
          </div>

          <div className="col-12">
            <Print/>
          </div>
        </div>

      </div>

    )
  }
}

export default ProgramsRequests;