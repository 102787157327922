// Dependencies
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import axios from "axios";

// Assets
import imgEx from "../../Assets/imgEx.jpg";

// Server URL
import serverURL from "../../serverURL";
var direccion = serverURL;

class TranslationsCards extends Component {
  constructor(props) {
    super(props);
    this.state = {
      institution: this.props,
    };
  }

  changeTranslation(_id, type) {
    if (type === "institution") {
      axios
        .get(direccion + "api/institution/" + _id, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("UserSession"),
            
          },
        })
        .then((response) => {
          localStorage.setItem(
            "selectedInstitution",
            JSON.stringify(response.data.institutions[0])
          );

          window.location.reload();
        });
    } else {
      axios
        .get(direccion + "api/program/getProgramByID/" + _id , {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("UserSession"),
            
          },
        })
        .then((response) => {
          localStorage.setItem(
            "selectedProgram",
            JSON.stringify(response.data.programs)
          );
          localStorage.setItem(
            "programsResult",
            JSON.stringify(response.data.programs)
          );
          window.open("/programs/details/summary", "_self");
        })
        .catch((error) => {});
    }
  }

  componentDidMount() {
    if (this.props.image == undefined) {
      this.setState({
        img: imgEx,
      });
    } else {
      this.setState({
        img:
          "https://mentorme-staging-upload.s3-us-west-2.amazonaws.com/" +
          this.props.image,
      });
    }
  }

  render() {
    return (
      <div className="col-2 text-center card mx-1 p-2">
        <strong>{this.props.t("name")}:</strong> {this.props.name}
        <strong>{this.props.t("language")}:</strong> {this.props.language}
        <button
          className="btn btn-outline-primary btn-sm py-0 mt-1"
          onClick={(e) => {
            this.changeTranslation(this.props._id, this.props.type);
          }}
        >
          {this.props.t("see")}
        </button>
      </div>
    );
  }
}

export default withTranslation("Generals")(TranslationsCards);
