// Dependencies
import React, { Component } from "react";
import axios from "axios";
import Swal from "sweetalert2";

// Assets
import logo from "../../Assets/logo.png";

// Server URL
import serverURL from "../../serverURL";
import adminURL from "../../adminURL";
var direccion = serverURL;

class AdminConfirmation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      consentCheck: false,
      disabledButton: true,
    };
  }

  hadleUserInput(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value });
  }

  hadleConsent(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.state.consentCheck =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    this.setState({
      consentCheck: this.state.consentCheck,
    });

    if (e.target.checked == true) {
      this.setState({
        disabledButton: false,
      });
    } else {
      this.setState({
        disabledButton: true,
      });
    }
  }

  componentDidMount(e) {
    var email = this.props.location.pathname.split("/").slice(1)[1];
    axios.get(direccion + "access/searchemail/" + email).then((response) => {
      this.setState({ userExists: response.data.user });
    });
  }

  render() {
    var direccionFront = adminURL + "/login";
    var display;

    const Email = this.props.location.pathname.split("/").slice(1)[1];
    const Token = this.props.location.pathname.split("/").slice(1)[2];

    if (this.state.userExists != null) {
      return (
        <div className="Container loginBG">
          <img src={logo} className="App-logo" alt="logo" />
          <div className="row">
            <div className="col-1 col-md-4"></div>
            <div className="col-10 col-md-4">
              <div className="card login-card">
                <div className="card-body">
                  <div className="card-title gray">Confirm Invite</div>
                  <div className="container">
                    <form>
                      <div className="row">
                        <input
                          disabled
                          type="email"
                          id="inputEmail"
                          className="form-input"
                          name="email"
                          value={Email}
                          onChange={(event) => this.hadleUserInput(event)}
                        />
                      </div>
                      <button
                        onClick={() => {
                          axios
                            .post(
                              direccion +
                                "access/confirmAdminInvite/" +
                                Email +
                                "/" +
                                Token
                            )
                            .then((response) => {
                              Swal.fire({
                                title: "Invite succesfully confirmed",
                                text:
                                  "You will be automatically redirected to the Login page",
                                timer: 4000,
                                timerProgressBar: true,
                                icon: "success",
                              }).then(() => {
                                window.open(direccionFront, "_self");
                              });
                            })
                            .catch((error) => {
                              Swal.fire({
                                title: "Something wen't wrong",
                                timer: 2000,
                                timerProgressBar: true,
                                icon: "error",
                              });
                            });
                        }}
                        type="button"
                        className="btn btn-primary loginButtons"
                      >
                        Accept
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-1 col-md-4"></div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="Container loginBG">
          <img src={logo} className="App-logo" alt="logo" />
          <div className="row">
            <div className="col-1 col-md-4"></div>
            <div className="col-10 col-md-4">
              <div className="card login-card">
                <div className="card-body">
                  <div className="card-title gray">Create account</div>
                  <div className="container">
                    <form>
                      <div className="row">
                        <input
                          disabled
                          type="email"
                          id="inputEmail"
                          className="form-input"
                          name="email"
                          value={Email}
                          onChange={(event) => this.hadleUserInput(event)}
                        />
                      </div>
                      <div className="row">
                        <input
                          type="password"
                          id="password"
                          placeholder="Password"
                          className={"form-input " + display}
                          name="password"
                          value={this.state.password}
                          onChange={(event) => this.hadleUserInput(event)}
                        />
                      </div>
                      <div className="row">
                        <input
                          type="password"
                          id="passwordConfirm"
                          placeholder="Confirm password"
                          className={"form-input " + display}
                          name="passwordConfirm"
                          value={this.state.passwordConfirm}
                          onChange={(event) => this.hadleUserInput(event)}
                        />
                      </div>

                      <div className="row">
                        <input
                          type="checkbox"
                          name="consentCheck"
                          id="consentCheck"
                          value={this.state.consentCheck}
                          className="ml-3 my-2"
                          onChange={(event) => this.hadleConsent(event)}
                        />
                        <span className="my-2" style={{ fontSize: "11px" }}>
                          &nbsp;I've read and accept the{" "}
                          <a
                            href="https://mentormeconnect.com/termsandconditions.html"
                            target="_blank"
                          >
                            Terms and conditions
                          </a>{" "}
                          and{" "}
                          <a
                            href="https://mentormeconnect.com/privacyconditions.html"
                            target="_blank"
                          >
                            Privacy conditions
                          </a>
                        </span>
                      </div>
                      <button
                        onClick={() => {
                          var password = document.getElementById("password")
                            .value;
                          var passwordConfirm = document.getElementById(
                            "passwordConfirm"
                          ).value;
                          if (password === passwordConfirm) {
                            axios
                              .post(
                                direccion +
                                  "access/confirmAdminInvite/" +
                                  Email +
                                  "/" +
                                  Token,
                                { password: this.state.password }
                              )
                              .then((response) => {
                                Swal.fire({
                                  title: "Invite succesfully confirmed",
                                  text:
                                    "You will be automatically redirected to the Login page",
                                  timer: 4000,
                                  timerProgressBar: true,
                                  icon: "success",
                                }).then(() => {
                                  window.open(direccionFront, "_self");
                                });
                              })
                              .catch((error) => {
                                Swal.fire({
                                  title: "Something wen't wrong",
                                  timer: 2000,
                                  timerProgressBar: true,
                                  icon: "error",
                                });
                              });
                          } else {
                            Swal.fire({
                              text: "The passwords do not match",
                              timer: 3000,
                              timerProgressBar: true,
                              icon: "error",
                            });
                          }
                        }}
                        type="button"
                        className="btn btn-primary loginButtons"
                        disabled={this.state.disabledButton}
                      >
                        Accept
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-1 col-md-4"></div>
          </div>
        </div>
      );
    }
  }
}

export default AdminConfirmation;
