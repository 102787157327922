// Dependencies
import React,{Component} from 'react';

// Components
import SearchBar from '../Bars/SearchBar.js'
import ResultsBar from '../Bars/ResultsBar.js'
import NotificationCards from './NotificationCards.js'



// CSS
import './Notifications.css';

class Notifications extends Component{
    
    render(){
        return(
            <div className="Notifications">

                <SearchBar/>

                <ResultsBar
                    SearchTitle="Notifications"
                    SearchResults="4"
                />

                <NotificationCards
                     AlertDate="11/05/11"
                     AlertTime="10:23"
                     AlertName="User Name"
                     AlertMessage="10 days without activity" 
                />

                <NotificationCards
                     AlertDate="11/05/11"
                     AlertTime="10:23"
                     AlertName="User Name"
                     AlertMessage="12 days without match" 
                />

                <NotificationCards
                     AlertDate="11/05/11"
                     AlertTime="10:23"
                     AlertName="User Name"
                     AlertMessage="has submitted a request to become a institutional admmin" 
                />

                <NotificationCards
                     AlertDate="11/05/11"
                     AlertTime="10:23"
                     AlertName="User Name"
                     AlertMessage="has surpassed due date of the current task" 
                />

                    
            </div>
        )
    }

}

export default Notifications;