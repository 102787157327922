// Dependencies
import React, {Component} from "react";
import axios from "axios";
import Swal from 'sweetalert2'
import { withTranslation } from 'react-i18next';


// Assets
import logo from '../../Assets/logo.png';

// CSS
import './Login.css'; 

// Server URL
import serverURL from '../../serverURL'
var direccion = serverURL;

class Login extends Component {

  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: ''
    }
  }

  componentDidMount() {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
    console.log("cache deleted!!!");
  }

  hadleUserInput(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({[name]: value});
  }

  

  render() {
    return (
      <div className="Container loginBG">
        <img src={logo} className="App-logo" alt="logo"/>
        <div className="row">
          <div className="col-1 col-md-4"></div>
          <div className="col-10 col-md-4">
            <div className="card login-card">
              <div className="card-body">
                <div className="card-title gray">{this.props.t('title')}</div>
                <div className="container">
                  <form>
                    <div className="row">
                      <input
                        type="email"
                        id="inputEmail"
                        placeholder={this.props.t('form.email')}
                        className="form-input"
                        name="email"
                        value={this.state.email}
                        onChange={(event) => this.hadleUserInput(event)}/>
                    </div>
                    <div className="row">
                      <input
                        type="password"
                        id="password"
                        placeholder={this.props.t('form.password')}
                        className="form-input"
                        name="password"
                        value={this.state.password}
                        onChange=
                        {(event) => this.hadleUserInput(event)}/>
                    </div>

                    <button
                      onClick={(e) => {
                      e.preventDefault();
                      axios
                        .post(direccion + "access/login", {
                        email: this.state.email,
                        password: this.state.password,
                        module: "admin"
                      })
                        .then((response) => {
                          localStorage.setItem("UserSession", JSON.stringify(response.data.token).replace(/"/g, ''));
                          localStorage.setItem("Institutions", JSON.stringify(response.data.institutions));
                          localStorage.setItem("Programs", JSON.stringify(response.data.programs));
                          localStorage.setItem("User", JSON.stringify(response.data.user));
                          localStorage.setItem("Data", JSON.stringify(response.data.dashboard));
                          localStorage.setItem("UserId", JSON.stringify(response.data.user._id).replace(/"/g, ''));
                          window.open("/dashboard", "_self");
                        })
                        .catch((error) => {
                          Swal
                            .fire({title: 'Error', icon: 'error', text: error.response})
                            .then((result) => {});
                        });
                    }}
                      type="submit"
                      className="btn btn-primary loginButtons">
                      {this.props.t('form.login')}
                    </button>

                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="col-1 col-md-4"></div>
        </div>
      </div>
    )

  }

}

export default withTranslation('Login')(Login);
