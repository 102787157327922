// Dependencies
import React,{Component} from 'react';

class UserProfile extends Component{
    constructor(props) {
        super(props);
        this.state = {
        }
      }
    render(){
        return(
            <div className="container-fluid">
                <div className="row text-left">
                    <div className="col-12 mt-4">
                        <h5>Biography</h5><br/>
                            {!!(this.props.description)?this.props.description:"Empty"}
                        <hr/>
                    </div>
                    <div className="col-12">
                        <h5>Interests</h5><br/>
                            {!!(this.props.interests)?this.props.interests:"Empty"} 
                        <hr/>
                    </div>
                </div>
            </div>
        )
    }
}

export default UserProfile;