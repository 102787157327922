// Dependencies
import React, {Component} from 'react';
import { withTranslation } from 'react-i18next';
import {Link} from "react-router-dom";
import Pagination from '../shared/Pagination';
import axios from "axios";
import serverURL from "../../serverURL";

// Components
import InstitutionalUserCards from './InstitutionalUserCards'

class InstitutionalUsers extends Component {
  constructor(props) {
    super(props);
    //const [currentPage, setCurrentPage] = useState(1);
    this.state = {
      users: JSON.parse(localStorage.getItem("InstitutionsUsers")),
      usersLength: JSON.parse(localStorage.getItem("InstitutionsUsersLength")),
      page : 1
    }
  }
  
  onClickEvent(page){
    this.setState({page : page})
    let url =serverURL +
    "api/institution/getUsersPagination/"+
      JSON.parse(localStorage.getItem("selectedInstitution"))._id+"/" +
    this.state.page;
    axios.get(
      serverURL +
        "api/institution/getUsersPagination/"+
          JSON.parse(localStorage.getItem("selectedInstitution"))._id+"/" +
        page,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("UserSession")
        },
      }
    )
    .then((response) => {
      this.setState({
        users: response.data.users
      });
    }).catch(r=>{
      console.log(r);
    });
  }

  render() {
    const Print = () => {
      return this.state.users.map((result, index, array) => 
        <div className="col-12">
            < InstitutionalUserCards
              email={result.mail}
              userName={  (result.userId != undefined) ? (!!result.userId.userName ? result.userId.userName : "No user name") : "Invite not confirmed yet"}
              isPredefinedMentor= {result.isPredefinedMentor}
            />
        </div>);
    }

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-10 DescriptionHeaderTitle my-4">
            <h5>{this.props.t('associated_users_institution')}</h5>
          </div>
          <div className="col-md-2 text-right my-4">
            <Link className="AddNew pr-2" to="/institutions/details/newusers">
              <i className="fas fa-plus-circle"></i>
              <span className="AddText">&nbsp;&nbsp;{this.props.t('add_users')}</span>
            </Link>
          </div>
        </div>
        <Pagination
          className="pagination-bar"
          currentPage={this.state.page}
          totalCount={this.state.usersLength}
          pageSize={50}
          onPageChange={page => this.onClickEvent(page)}
        />
        <div className="row">
          <Print/>
        </div>
        <Pagination
          className="pagination-bar"
          currentPage={this.state.page}
          totalCount={this.state.usersLength}
          pageSize={50}
          onPageChange={page => this.onClickEvent(page)}
        />
      </div>

    )
  }
}

export default withTranslation('Generals')(InstitutionalUsers);