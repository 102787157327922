// Dependencies
import React, {Component} from 'react';
import { withTranslation } from 'react-i18next';

class ProgramsCards extends Component{



    render(){
        return(
        <div className="ProgramsCards" > 
                <div className="ProgramsRows row ">

                    <div className="col-3 ProgramsCols text-left">
                        <div className="row ProgramCardHeaders">
                            <div>{this.props.t('name') }</div>
                        </div>
                        <div className="row pt-1">
                            {this.props.name}
                        </div>
                    </div>

                    <div className="col-3 InfoProgramCol">
                        <div className="row ProgramCardHeaders">
                            <div>{this.props.t('institution') }</div>
                        </div>
                        <div className="row ProgramCardInfo">
                            {this.props.institutionName}
                        </div>
                    </div>


                    <div className="col-2">
                        <div className="row ProgramCardHeaders">
                            <div>{this.props.t('start_date') }</div>
                        </div>
                        <div className="row ProgramCardInfo">
                            {this.props.startDate}
                        </div>
                    </div>

                    <div className="col-2">
                        <div className="row ProgramCardHeaders">
                            <div>{this.props.t('end_date') }</div>
                        </div>
                        <div className="row ProgramCardInfo">
                            {this.props.endDate}
                        </div>
                    </div>

                </div>
        </div>
        )
    }

}

export default withTranslation('Generals')(ProgramsCards);