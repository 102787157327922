// Dependencies
import React from "react";
import { withTranslation } from "react-i18next";

// Components
import DashboardCards from "./DashboardCards.js";
// Components
import LineCharts from "../Charts/LineCharts.js";
import BarCharts from "../Charts/BarCharts.js";
import axios from "axios";

// Server URL
import serverURL from "../../serverURL";
var direccion = serverURL;

export class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    var data = JSON.parse(localStorage.getItem("Data"));
    var userId = localStorage.getItem("UserId");
    var count = data;
    this.state = {
      userid: userId,
      programsCount: count? count.programs: null,
      matchesCount: count?count.matches:null,
    };
  }

  componentDidMount() {
    var object = {
      module: "admin",
    };
    axios
      .post(
        direccion + "api/institutionaladmin/dashboard/" + this.state.userid,
        object,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("UserSession"),
            
          },
        }
      )
      .then((response) => {
        this.setState({
          lastActivityTable: response.data.lastActivityTable,
          lastActivityDataset: response.data.lastActivityDataset,
        });
      });

    axios
      .get(
        direccion + "api/institutionalAdmin/matchStatus/" + this.state.userid,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("UserSession"),
            
          },
        }
      )
      .then((response) => {
        this.setState({
          matchStatus: response.data.matchStatus,
        });
      });
  }

  render() {
    return (
      <div>
        <div className="row DashboardCards border-between border-between2">
          <DashboardCards
            CardTitle={this.props.t("programs")}
            CardNumber={this.state.programsCount}
            TagCard="/programs"
          />
          <DashboardCards
            CardTitle={this.props.t("matches")}
            CardNumber={this.state.matchesCount}
            TagCard="/institutions"
          />
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-6 mt-5">
              {this.state.lastActivityDataset ? (
                <LineCharts
                  dataArray={this.state.lastActivityDataset}
                  label={this.props.t("charts.users_last_activity")}
                />
              ) : null}
            </div>
            <div className="col-6 mt-5">
              {this.state.matchStatus ? (
                <BarCharts
                  dataArray={this.state.matchStatus}
                  label={this.props.t("charts.match_status")}
                />
              ) : null}
            </div>
            <div className="col mt-4">
              <a
                className="btn btn-outline-primary"
                href="/analytics/user-activity"
              >
                {this.props.t("see") + " " + this.props.t("metrics")}
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation("Generals")(Dashboard);
