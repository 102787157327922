import React from "react";
import { Radar } from "react-chartjs-2";

class RadarChart extends React.Component {
  state = {
    chartOptions: {
      responsive: true,
      layout: {
        padding: {
          left: 15,
          right: 15,
          top: 5,
          bottom: 10,
        },
      },
      legend: {
        position: "bottom",
        labels: {
          fontColor: "#7553BB",
          boxWidth: 15,
          fontFamily: "Raleway",
          fontStyle: "bold",
          padding: 20,
        },
      },
      scale: {
        angleLines: {
          display: false,
        },
        gridLines: {
          color: "#27B2A0",
        },
        pointLabels: {
          fontFamily: "Raleway",
          fontStyle: "bold",
          fontColor: "#3C2865",
        },
        ticks: {
          fontColor: "#3C2865",
          fontFamily: "Raleway",
          stepSize: 1,
        },
      },
      tooltips: {
        enabled: true,
        mode: "nearest",
        callbacks: {
          label: function (tooltipItem, data) {
            var label = data.datasets[tooltipItem.datasetIndex].label || "";

            if (label) {
              label += ": ";
            }
            label += Math.round(tooltipItem.yLabel * 100) / 100;
            return label;
          },
        },
      },
    },
    chartData: {
      labels: this.props.dataArray.labels,
      datasets: [
        {
          label: "Mentees",
          backgroundColor: "#3BD4C0aa",
          borderColor: "#3BD4C0",
          fill: true,
          pointBackgroundColor: "white",
          borderWidth: "1",
          data: this.props.dataArray.menteeCount,
        },
        {
          label: "Mentors",
          backgroundColor: "#327DC2aa",
          borderColor: "#327DC2",
          fill: true,
          pointBackgroundColor: "white",
          borderWidth: "1",
          data: this.props.dataArray.mentorCount,
        },
      ],
    },
  };

  render() {
    return (
      <div className="chart-card">
        <h4 className="chart-header"> {this.props.label} </h4>
        <Radar data={this.state.chartData} options={this.state.chartOptions} />
      </div>
    );
  }
}

export default RadarChart;
