// Dependecies
import React,{Component} from "react";
import { withTranslation } from "react-i18next";
import {Link} from 'react-router-dom';

// Components
import Goal from './Goals'

class ProgramsGoalsTasks extends Component{
    render(){

        var Print = () => {
            return this.props.goals.map((v,i,a) => 
                <Goal
                    key={i}
                    description = {v.description}
                    documents = {v.documents}
                    durationInDays = {v.durationInDays}
                    links = {v.links}
                    startDate={v.startDate}
                    endDate={v.endDate}
                    subject = {v.subject}
                    tasks = {v.tasks}
                />
            )
        }


        return(
            <div className="px-4">
                 <div className="row">
                    <div className="col"></div>
                    <div className="col-8 mt-3">
                    </div>
                    <div className="col mt-2">
                        <Link className="btn btn-primary"  to={"/programs/details/edit/goals" }>{this.props.t('edit')}</Link>
                    </div>
                </div>
                <hr/>
                <Print/>
            </div>
        )
    }
}

export default withTranslation('Generals')(ProgramsGoalsTasks);