// Dependencies
import React, { Component } from "react";

class Interests extends Component {
  render() {
    const PrintInterests = () => {
      return this.props.interests.map((result, index, array) => (
          <li>{result}</li>
      ));
    };
    return (
      <div className="InterestCards">
            <span className="InterestCategory">{this.props.category}</span>
        <ul className="InterestsList">
          <PrintInterests />
        </ul>
      </div>
    );
  }
}

export default Interests;
