import React from "react";
import { Bar } from "react-chartjs-2";
import { withTranslation } from "react-i18next";


class BarCharts extends React.Component {
  state = {
    chartOptions: {
      responsive: true,
      layout: {
        padding: {
          left: 15,
          right: 15,
          top: 5,
          bottom: 10,
        },
      },
      legend: {
        position: "bottom",
        labels: {
          fontColor: "#7553BB",
          boxWidth: 15,
          fontFamily: "Raleway",
          fontStyle: "bold",
          padding: 20,
        },
      },
      tooltips: {
        mode: "label",
      },
      elements: {
        line: {
          fill: false,
        },
      },
      scales: {
        xAxes: [
          {
            display: true,
            gridLines: {
              display: true,
            },
            labels: this.props.dataArray.labels,
            stacked: true,
            ticks: {
              fontColor: "#7553BB",
              fontFamily: "Raleway",
              fontStyle: "bold",
            },
          },
        ],
        yAxes: [
          {
            type: "linear",
            display: true,
            position: "left",
            id: "y-axis-1",
            gridLines: {
              display: true,
            },
            labels: {
              show: true,
            },
            stacked: true,
            ticks: {
              fontColor: "#7553BB",
              fontFamily: "Raleway",
              fontStyle: "bold",
              fontSize: "15",
              stepSize: Math.ceil(1),
            },
          },
        ],
      },
    },
    chartData: {
      datasets: [
        {
          label: this.props.t("accepted"),
          fill: true,
          backgroundColor: "rgba(117, 225, 211, .9)",
          borderColor: "rgba(117, 225, 211, 1)",
          data: this.props.dataArray.accepted,
        },
        {
          label: this.props.t("canceled"),
          fill: true,
          backgroundColor: "rgba(76, 151, 191, .9)",
          borderColor: "rgba(76, 151, 191, 1)",
          data: this.props.dataArray.cancel,
        },
        {
          label: this.props.t("pending"),
          fill: true,
          backgroundColor: "rgba(140, 185, 226, .9)",
          borderColor: "rgba(140, 185, 226, 1)",
          data: this.props.dataArray.pending,
        },
        {
          label: this.props.t("rejected"),
          fill: true,
          backgroundColor: "rgba(106, 188, 177, .9)",
          borderColor: "rgba(106, 188, 177, 1)",
          data: this.props.dataArray.rejected,
        },
      ],
    },
    dataPicker: 0,
  };

  render() {
    return (
      <div className="chart-card">
        <h4 className="chart-header">{this.props.label}</h4>
        <Bar data={this.state.chartData} options={this.state.chartOptions} />
      </div>
    );
  }
}

export default withTranslation("Generals")(BarCharts);
