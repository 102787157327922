// Dependencies
import React, { Component } from "react";
import axios from "axios";
import Swal from "sweetalert2";

// Server URL
import serverURL from "../../serverURL";
import { withTranslation } from "react-i18next";
var direccion = serverURL;

class AddTranslation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      institutionName: "",
      description: "",
      language: "",
      professionalInterests: this.props.professionalInterests,
      currentInstitution:  JSON.parse(localStorage.getItem("selectedInstitution"))
    };
  }

  hadleUserInput(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value });
  }



  handleButtonAnimation(e) {
    var position = e.target.className.toString();
    if (position.includes("collapsed") === true) {
      e.target.className =
        "btn btn-sm btn-primary w-100 fas fa-chevron-up collapsed h-100";
    } else {
      e.target.className =
        "btn btn-sm btn-primary w-100 fas fa-chevron-down h-100";
    }
  }

  cancelHandler(e) {
    e.preventDefault();
    Swal.fire({
     title: this.props.t('alerts.are_you_sure'),
       text: this.props.t('alerts.information_will_be_lost'),
       icon: 'warning',
       showCancelButton: true,
       confirmButtonColor: '#3085d6',
       cancelButtonColor: '#d33',
       confirmButtonText: this.props.t('yes'),
       cancelButtonText: this.props.t('cancel')
    }).then((result) => {
      if (result.value) {
        window.open("/institutions", "_self");
      }
    });
  }

  changeCategory(e, index) {
    this.state.professionalInterests[index][e.target.name] = e.target.value;
    this.setState({ professionalInterests: this.state.professionalInterests });
  }

  changeInterest(e, index, interestIndex) {
    this.state.professionalInterests[index].interests[interestIndex] =
      e.target.value;
    this.setState({ professionalInterests: this.state.professionalInterests });
  }

  submitHandler(e) {
    e.preventDefault();
    e.target.className += " was-validated";
    var object = {
      institutionName: this.state.institutionName,
      description: this.state.description,
      language: this.state.language,
      professionalInterests: this.state.professionalInterests,
    };

    axios
      .post(direccion + "api/institutionalAdmin/addInstitutionTranslation/" + this.state.currentInstitution._id, object, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("UserSession"),
          
        },
      })
      .then((response) => {
       localStorage.setItem(
         "selectedProgram",
         JSON.stringify(response.data.programs)
       );
        Swal.fire({
          title: "Success",
          icon: "success",
          text: "Translation created",
        }).then((result) => {
          if (result.value) {
            window.open("/institutions/details/profile", "_self");
          }
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.stack[1],
          icon: "error",
        });
      });
  }

  render() {
    const MyArray = this.props.professionalInterests;

    return (
      <div className="container my-3">
        <h4>Add Institution Translation</h4>
        <form
          className=" needs-validation"
          onSubmit={(e) => this.submitHandler(e)}
        >
          <div className="row text-left">
            <div className="col mt-4">
              {this.props.t('institution')}
              <input
                type="text"
                className="mt-2"
                name="institutionName"
                placeholder={this.state.currentInstitution.institutionName}
                value={this.state.institutionName}
                onChange={(event) => this.hadleUserInput(event)}
                required
              />
            </div>
            <div className="col-1 mt-4 text-center">
              {
                this.props.t('language')
              }
              <br />
              <select
                className="selectP"
                name="language"
                id=""
                onChange={(event) => this.hadleUserInput(event)}
                value={this.state.language}
              >
                <option value="es">ES</option>
                <option value="en">EN</option>
                <option value="fr">FR</option>
                <option value="de">DE</option>
                <option value="pt">PT</option>
              </select>
            </div>
            <div className="col-12 mt-4">
              {
                this.props.t('description')
              }
              <input
                type="text"
                className="mt-2"
                name="description"
                placeholder={this.state.currentInstitution.description}
                value={this.state.description}
                onChange={(event) => this.hadleUserInput(event)}
              />
            </div>
          </div>
          <div className="row my-4">
            <div className="col-12">
              <div id="accordion">
                <div className="row">
                  <div className="col-4">
                    <h5 className="mb-3">
                       {
                         this.props.t('original_language_interests')
                       }
                    </h5>
                    {this.state.currentInstitution.professionalInterests.map(
                      (Value, index, array) => {
                        return (
                          <div className="card my-1" key={index}>
                            <div className="card-header py-2">
                              <div className="row " id={"heading-" + index}>
                                <div className="col-12 text-left ">
                                  <div className="row align-middle">
                                    <div className="col py-1">
                                      {
                                        this.props.t('category')
                                      }: {
                                        Value.category
                                      }
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div
                              id={"collapse" + index}
                              className="collapse show"
                              aria-labelledby="headingOne"
                              data-parent="#accordion"
                            >
                              <div className="card-body text-left">
                                <div className="row ">
                                  <div className="col-12 ">
                                    {
                                        this.props.t('interests')
                                      }:</div>
                                  <div className="col-12">
                                    <div id="tasksAccordion">
                                      {this.state.currentInstitution.professionalInterests[
                                        index
                                      ].interests.map(
                                        (Value, interestIndex, array) => {
                                          return (
                                            <div className="row py-1">
                                              <div className="col-11">
                                                {Value}
                                              </div>
                                            </div>
                                          );
                                        }
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                  <div className="col-8">
                    <h5 className="mb-3">
                       {
                         this.props.t('to_translate_interests')
                       }
                    </h5>
                    {this.state.professionalInterests.map(
                      (currentValue, index, array) => {
                        return (
                          <div className="card my-1" key={index}>
                            <div
                              className="card-header py-2"
                              id={"heading-" + index}
                            >
                              <div className="row">
                                <div className="col-11 text-left ">
                                  <div className="row align-middle">
                                    <div className="col-2 py-1 pr-0">
                                      {
                                        this.props.t('category')
                                      }:
                                    </div>
                                    <div className="col-10">
                                      <input
                                        className="AddNewTitleInput form-control"
                                        name="category"
                                        placeholder={this.state.currentInstitution.professionalInterests[index].category}
                                        value={currentValue.category}
                                        onChange={(e) =>
                                          this.changeCategory(
                                            e,
                                            index,
                                            currentValue
                                          )
                                        }
                                        required
                                      ></input>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-1 p-1">
                                  <button
                                    type="button"
                                    onClick={(e) =>
                                      this.handleButtonAnimation(e)
                                    }
                                    className="btn btn-sm btn-primary w-100 fas fa-chevron-down h-100"
                                    data-toggle="collapse"
                                    data-target={"#collapse" + index}
                                    aria-expanded="true"
                                    aria-controls={"collapse" + index}
                                  ></button>
                                </div>
                              </div>
                            </div>

                            <div
                              id={"collapse" + index}
                              className="collapse show"
                              aria-labelledby="headingOne"
                              data-parent="#accordion"
                            >
                              <div className="card-body text-left">
                                <div className="row ">
                                  <div className="col-12 d-flex">
                                    {
                                      this.props.t('interests')
                                    }:
                                  </div>
                                  <div className="col-12 px-1 py-0">
                                    <div id="tasksAccordion">
                                      {this.state.professionalInterests[
                                        index
                                      ].interests.map(
                                        (
                                          currentValue,
                                          interestIndex,
                                          array
                                        ) => {
                                          return (
                                            <div className="row py-1">
                                              <div className="col-12">
                                                <input
                                                  className="AddNewTitleInput form-control"
                                                  name="subject"
                                                  value={currentValue}
                                                  placeholder={this.state.currentInstitution.professionalInterests[index].interests[interestIndex]}
                                                  onChange={(e) =>
                                                    this.changeInterest(
                                                      e,
                                                      index,
                                                      interestIndex,
                                                      currentValue
                                                    )
                                                  }
                                                  required
                                                ></input>
                                              </div>
                                            </div>
                                          );
                                        }
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row float-right">
            <div className="col-12 my-4">
              <input
                type="reset"
                value={this.props.t('cancel')}
                className="btn btn-outline-danger mr-2"
                onClick={(e) => this.cancelHandler(e)}
              />
              <input type="submit" className="btn btn-primary" value={this.props.t('save')} />
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default withTranslation('Generals')(AddTranslation);
