// Dependencies
import React from "react";

// Components
import RadarChart from "../Charts/RadarChart.js";
import axios from "axios";
import DataTable, { createTheme } from "react-data-table-component";

// Server URL
import serverURL from "../../serverURL";
import PieCharts from "../Charts/PieCharts.js";
import { withTranslation } from "react-i18next";
var direccion = serverURL;

export class UserActivity extends React.Component {
  constructor(props) {
    super(props);
    var userId = localStorage.getItem("UserId");
    this.state = {
      userid: userId,
    };
  }

  componentDidMount() {
    axios
      .get(
        direccion +
          "api/institutionalAdmin/countingPersonalAndProfessionalInterests/" +
          this.state.userid,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("UserSession"),
            
          },
        }
      )
      .then((response) => {
        this.setState({
          personalCategoriesCount: response.data.personalCategoriesCount,
          personalInterestsCount: response.data.personalInterestsCount,
          professionalCategoriesCount:
            response.data.professionalCategoriesCount,
          professionalInterestsCount: response.data.professionalInterestsCount,
        });
      });
  }

  render() {
    return (
      <div>
        <div className="container-fluid">
          <div className="row w-100">
            <div className="col-12"></div>
            <div className="col-6 my-3">
              {this.state.professionalCategoriesCount ? (
                <RadarChart
                  dataArray={this.state.professionalCategoriesCount}
                  label={this.props.t('charts.professional_categories')}
                />
              ) : null}
            </div>
            <div className="col-6 my-3">
              {this.state.personalCategoriesCount ? (
                <RadarChart
                  dataArray={this.state.personalCategoriesCount}
                  label={this.props.t('charts.personal_categories')}
                />
              ) : null}
            </div>
            <div className="col-6 my-3">
              {this.state.professionalInterestsCount ? (
                <PieCharts
                  dataArray={this.state.professionalInterestsCount}
                  label={this.props.t('charts.professional_interests')}
                />
              ) : null}
            </div>
            <div className="col-6 my-3">
              {this.state.personalInterestsCount ? (
                <PieCharts
                  dataArray={this.state.personalInterestsCount}
                  label={this.props.t('charts.personal_interests')}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation('Generals')(UserActivity);
