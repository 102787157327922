// Dependencies
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import axios from "axios";

// Components
import ProgramsUserCards from "./ProgramsUserCards";
import serverURL from "../../serverURL";
var direccion = serverURL;

class ProgramsUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page : 1,
      users : []
    }
    axios
    .post(
      direccion + "api/program/getUsersByPage/" + props.programId + "/"+ this.state.page,{},
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("UserSession"),
          
        }
      }
    )
    .then(response => {
      //this.state.users = response.data.users;
      this.setState({ users: response.data.users});
    })
    .catch(error => {
    });
    
  }

  render() {
    const Print = () => {
      return this.state.users.map((v, i, a) => (
        <div className="col-12">
          <ProgramsUserCards
            key={i}
            role={v.rol}
            active={v.active}
            mail={v.userId.email}
            userName={v.userId.userName}
            _id={v.userId._id}
          />
        </div>
      ));
    };

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-6 DescriptionHeaderTitle mt-3">
            <h5>{this.props.t('associated_users')}</h5>
          </div>
          <div className="col-6 text-right mt-3 pr-5">
            <Link
              className="btn btn-sm btn-primary"
              to={"/programs/details/add/users"}
            >{this.props.t('add_users') }</Link>
          </div>
        </div>
        <hr/>
        <div className="row">
          <Print />
        </div>
      </div>
    );
  }
}

export default withTranslation('Generals')(ProgramsUsers);
