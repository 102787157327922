// Dependencies
import React, { Component } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import loading from "../../Assets/loading.gif";
import Pagination from '../shared/Pagination';


// Server URL
import serverURL from "../../serverURL";
import { withTranslation } from "react-i18next";
var direccion = serverURL;

class AddMatch extends Component {
  constructor(props) {
    super(props);
    var program = JSON.parse(localStorage.getItem("selectedProgram"));
    this.state = {
      program : program ,
      justLoaded : true,
      menteesArray: [],
      mentorsArray: [],
      mentor: {id: undefined, email : undefined, index : undefined},
      mentee: {id: undefined, email : undefined, index : undefined},
      programId: program._id,
      isCalc : false,
      menteePage : 1,
      mentorPage : 1,
      pageSize : 25,
      temporalMatches : [],
      mentorMaxMatches : program.maxMatchesPerMentor ? program.maxMatchesPerMentor : 1,
      menteeMaxMatches : 1,
      menteesReady: false,
      mentorsReady: false,
      menteeCount : 0,
      mentorCount : 0
    };
    this.getMentees()
  }
  getMentees(){
    console.log("getting mentee Page "+ this.state.menteePage );
    axios
    .post(
      direccion + "api/program/getUsersByPage/" + this.state.programId + "/"+ this.state.menteePage,
      {role : "mentee", pageSize : this.state.pageSize},
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("UserSession"),
          
        }
      }
    )
    .then(response => {
      this.setState({ 
        menteesArray: response.data.users , 
        menteesReady : true,
        menteeCount : response.data.users[0].totalsOnRole,
        mentorCount : response.data.users[0].totalUsers - response.data.users[0].totalsOnRole,
        justLoaded: false }, ()=>{
          this.menteesVsPairs();
        });
    })
    .catch(error => {
    });
  }
  
  menteesVsPairs(callback){
    for(let e = 0; e < this.state.menteesArray.length; e++){
      this.state.menteesArray[e].isAssigned = false;
      for(let p = 0; p<this.state.temporalMatches.length; p++){
        if(this.state.menteesArray[e].userId._id == this.state.temporalMatches[p].mentee.id){
          this.state.menteesArray[e].isAssigned = true;
        }
      }
    }
    this.setState({menteesArray : this.state.menteesArray})
  }

  mentorsVsPairs(callback){
    console.log(this.state.temporalMatches);
    console.log(this.state.mentorsArray);
    for(let o = 0; o < this.state.mentorsArray.length; o++){
      this.state.mentorsArray[o].assignedCount = 0;
      for(let p = 0; p<this.state.temporalMatches.length; p++){
        if(this.state.mentorsArray[o].userId == this.state.temporalMatches[p].mentor.id){
          this.state.mentorsArray[o].assignedCount ++;
        }
      }
    }
    this.setState({mentorsArray : this.state.mentorsArray})
  }

  hadleUserInput(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value });
  }

  addPair(e){
    this.state.menteesArray[this.state.mentee.index].isAssigned = true;
    this.state.mentorsArray[this.state.mentor.index].assignedCount ++;
    this.state.mentor.assignedCount = this.state.mentor.assignedCount?this.state.mentor.assignedCount+1:1;
    this.state.temporalMatches.push({
      mentor: this.state.mentor,
      mentee: this.state.mentee,
      match : this.state.mentorsArray[this.state.mentor.index].Match
    })
    this.setState({ temporalMatches: this.state.temporalMatches,
      mentor: {id: undefined, email : undefined, index : undefined},
      mentee: {id: undefined, email : undefined, index : undefined},
      menteesArray : this.state.menteesArray,
      mentorsArray : this.state.mentorsArray
    }, ()=>{
    });
  }
  removePair(index, mentee, mentor){
    this.state.temporalMatches.splice(index,1);
    this.setState({temporalMatches : this.state.temporalMatches});
    this.menteesVsPairs();
    this.mentorsVsPairs();
    
  }

  submitHandler(e) {
    /*e.preventDefault();
    e.target.className += " was-validated";*/
    let fails = [];
    Swal.fire({
      title: "Progress",
      icon: "success",
      text: "Working on matches",
      buttons: false
    });
    Swal.showLoading()
    let index = 0;
    const interval = setInterval(() => {
      if(index == this.state.temporalMatches.length-1){
        clearInterval(interval);
      }
      let i = index;
      index ++;
      var object = {
        mentorId: this.state.temporalMatches[i].mentor.id,
        menteeId: this.state.temporalMatches[i].mentee.id,
      };
      axios
        .post(
          direccion + "api/program/matchForcedByAdmin/" + this.state.programId,
          object,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("UserSession"),
            },
          }
        )
        .then((response) => {
          Swal.update({
            title: "Progress",
            icon: "success",
            text: "Processed : " + index + "/" + this.state.temporalMatches.length + 
             "\n Failed:  " + fails.length,
            buttons: false
          });
          if(i == this.state.temporalMatches.length -1){
            Swal.close();
            Swal.fire({
                title: "Completed",
                text: "Completed : " + index + "/" + this.state.temporalMatches.length + 
                "\n Failed:  " + fails.length + "\n Failed matches will remain on the pairing list, if no fails you will be redirected",
                showConfirmButton : true,
                confirmButtonColor: '#3085d6',
                confirmButtonText: this.props.t('yes')
            }).then((result)=>{
              if (result.value) {
                if(fails.length == 0){
                  localStorage.setItem(
                    "selectedInstitution",
                    JSON.stringify(this.state.program)
                  );          
                  window.open("/programs/details/matches", "_self");
                }else{
                  this.setState({temporalMatches : fails})
                }
              }
            });
            /*localStorage.setItem(
              "selectedInstitution",
              JSON.stringify(response.data.programs)*/
          }
        })
        .catch((error) => {
          let confirm = false;
          if(i == this.state.temporalMatches.length -1)
            confirm = true;
          fails.push(this.state.temporalMatches[i]);
          Swal.update({
            title: "Progress",
            icon: "success",
            text: "Processed : " + index + "/" + this.state.temporalMatches.length + 
             "\n Failed:  " + fails.length,
            buttons: false,
            showConfirmButton: confirm
          });
        });
    }, 500);
  }

  menteeCheck(v, email, index) {
    this.setState({ mentee : 
      { email : email, 
        index : index,
        isAssigned : false }, 
        isCalc : true,
        mentorsReady : false,
        mentorsArray : [],
        mentee:{id: v, email : email, index : index},
        mentor:{id: undefined, email : undefined, index : undefined}  }, ()=>{
          this.getMentorMatches();
        });
  }

  getMentorMatches(){
    axios
      .get(
        direccion + "api/program/matchInProgramUsers/" + this.state.programId + "/" + this.state.mentee.id + "/" + this.state.mentorPage + "/" + this.state.pageSize,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("UserSession"),
          },
        }
      )
      .then((response) => {
        this.setState({ 
          mentorsArray: response.data.userMatch, 
          isCalc : false, 
          mentorsReady : true},
          ()=>{
            this.mentorsVsPairs();
          });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error,
          icon: "error",
        });
      });
  }

  mentorCheck(v, email, index) {
    this.setState({ mentor: {id : v, email : email, index : index} });
  }


  cancelHandler(e) {
    e.preventDefault();
    Swal.fire({
      title: "Are you sure you want to leave?",
      text: "The information will be lost",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        window.open("/programs/details/matches", "_self");
      }
    });
  }
  nextMenteePage(page){
    this.setState({ 
      menteesReady : false, 
      mentorsReady : false, 
      //  menteesArray : [],
      mentorsArray: [],
      mentor: {id: undefined, email : undefined, index : undefined},
      mentee: {id: undefined, email : undefined, index : undefined},
      menteePage : page,
      mentorPage : 1
    },()=>{this.getMentees()});
  }
  nextMentorPage(page){
    this.setState({ 
      mentorsReady : false,
      //mentorsArray: [],
      mentor: {id: undefined, email : undefined, index : undefined},
      mentorPage : page
    },()=>{this.getMentorMatches()});
  }
  render() {
    return (
      <div className="container-fluid mt-4">
        <div className="row">
          <div className="col-12">
            <h3>
              {this.props.t('assign_match')}
            </h3>
          </div>
        </div>
        <div className="row" hidden = {!this.state.justLoaded} >
          <div className="col-12">
            <img src={loading} className="fixed-mid-load" alt="logo" />
          </div>
        </div>
        <form
          hidden = {this.state.justLoaded}
          className="text-left needs-validation"
          onSubmit={(e) => this.submitHandler(e)}
          noValidate
        >
          <div className="row my-3">
            <div className="col-6 text-center px-1">
              <h5 className="mb-4">{this.props.t('available_mentees') + " " 
                + ((this.state.pageSize*(this.state.menteePage-1))+1) + " - " 
                + ((this.state.pageSize*this.state.menteePage)>this.state.menteeCount?
                  this.state.menteeCount:
                  this.state.pageSize*this.state.menteePage)}</h5>
              <Pagination
                className="pagination-bar"
                currentPage={this.state.menteePage}
                totalCount={this.state.menteeCount}
                pageSize={this.state.pageSize}
                onPageChange={menteePage => this.nextMenteePage(menteePage)}
              />
              <div className="userCardContainer">
                <div className="row" hidden = {this.state.menteesReady} >
                  <div className="col-12">
                    <img src={loading} className="fixed-mid-load" alt="logo" />
                  </div>
                </div>
                {this.state.menteesArray.map((currentValue, index, array) => {
                  return (
                    <div className="Users" key={index}>
                      <div className="UsersRows row py-3 align-middle">
                        <div className="col-4 InfoUserCol text-left">
                          <div className="row">
                            <div className="col-12  UserInfoHeader Raleway text-left">
                              {this.props.t("name")}
                            </div>
                            <div className=" col-12  mt-2 textMid">
                              {!!currentValue.userId.userName
                                ? currentValue.userId.userName
                                : currentValue.userId.email}
                            </div>
                            <div className="col-12 align-middle UserInfoHeader">
                              {this.props.t("matchesCount")}
                            </div>
                            <div className="col-12 align-middle">
                              {currentValue.isAssigned?1:currentValue.countMatches}
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-12 UserInfoHeader Raleway ">
                              {this.props.t("professional_interests")}
                            </div>
                            <div className="col-12">
                              <div className="row m-1">
                                {currentValue.professionalInterests.map(
                                  (PcurrentValue, Pindex, array) => {
                                    return (
                                      <div className= {currentValue.isAssigned || currentValue.countMatches >= this.state.menteeMaxMatches?"badge badge-info col-12 mx-1 disabled":"badge badge-info col-12 mx-1"}>
                                        {PcurrentValue.interest}
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                            </div>
                            <div className="col-12 UserInfoHeader Raleway ">
                              {this.props.t("interests")}
                            </div>
                            <div className="col-12">
                              <div className="row m-1">
                                {currentValue.userId.personalInterests.map(
                                  (PcurrentValue, Pindex, array) => {
                                    return (
                                      <div className={currentValue.isAssigned || currentValue.countMatches >= this.state.menteeMaxMatches?"badge badge-info col-12 mx-1 disabled":"badge badge-info col-12 mx-1"}>
                                        {PcurrentValue.interest}
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                            </div>

                          </div>
                        </div>
                        <div className="col-2 p-auto">
                          <div className="row text-center h-100">
                            <div className="col-12 UserInfoHeader Raleway px-0">
                              {this.props.t("select")}
                            </div>
                            <div className="col-12 my-auto px-0" >
                              <input
                                hidden  = {currentValue.isAssigned === true || currentValue.countMatches >= this.state.menteeMaxMatches}
                                className="AddNewCheckbox"
                                name={currentValue.userId._id}
                                type="checkbox"
                                onChange={() =>
                                  this.menteeCheck(currentValue.userId._id, currentValue.userId.email, index)
                                }
                                checked={
                                  this.state.mentee.id === currentValue.userId._id && !currentValue.isAssigned
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="col-6 text-center px-1">
              <h5 className="mb-4">{this.props.t('available_mentors')}</h5>
              <Pagination
                className="pagination-bar"
                currentPage={this.state.mentorsArray.length != 0?this.state.mentorPage:1}
                totalCount={this.state.mentorsArray.length != 0?this.state.mentorCount:1}
                pageSize={this.state.pageSize}
                onPageChange={page => this.nextMentorPage(page)}
              />
              <div className="userCardContainer">
              <div className="row" hidden = {this.state.mentorsReady||this.state.mentee.id == undefined} >
                <div className="col-12">
                  <img src={loading} className="fixed-mid-load" alt="logo" />
                </div>
              </div>
                {this.state.mentorsArray.map((currentValue, index, array) => {
                  return (
                    <div className="Users" key={index}>
                      <div className="UsersRows row py-3 align-middle">
                        <div className="col-4 InfoUserCol text-left">
                          <div className="row">
                            <div className="col-12 mt-2 UserInfoHeader Raleway text-left">
                              {this.props.t("name")}
                            </div>
                            <div className=" col-12  mt-2 textMid text-left">
                              {currentValue.User
                                ? currentValue.User
                                : currentValue.Email}
                            </div>
                            <div className="col-12 align-middle UserInfoHeader">
                              {this.props.t("matchesCount")}
                            </div>
                            <div className="col-12 align-middle">
                              {currentValue.countMatches}
                            </div>
                            <div className="col-12 align-middle UserInfoHeader">
                              {this.props.t("assignedCount")}
                            </div>
                            <div className="col-12 align-middle">
                              {currentValue.assignedCount}
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-12 UserInfoHeader Raleway ">
                              {this.props.t("areas_of_experience")}
                            </div>
                            <div className="col-12">
                              <div className="row m-1">
                                {currentValue.interests.professional.map(
                                  (PcurrentValue, Pindex, array) => {
                                    return (
                                      <div className={!currentValue.yetAvailable || this.state.mentorMaxMatches == currentValue.assignedCount + currentValue.countMatches?"badge badge-info col-12 mx-1 disabled":"badge badge-info col-12 mx-1"}>
                                        {PcurrentValue.interest}
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-12 UserInfoHeader Raleway ">
                              {this.props.t("interests")}
                            </div>
                            <div className="col-12">
                              <div className="row m-1">
                                {currentValue.interests.personal.map(
                                  (PcurrentValue, Pindex, array) => {
                                    return (
                                      <div className={!currentValue.yetAvailable || this.state.mentorMaxMatches == currentValue.assignedCount + currentValue.countMatches?"badge badge-info col-12 mx-1 disabled":"badge badge-info col-12 mx-1"}>
                                        {PcurrentValue.interest}
                                      </div>
                                    );
                                  }
                                )}
                                </div>
                            </div>
                        </div>
                        <div className="col-2 p-auto">
                          <div className="row text-center h-100">
                            <div className="col-12 UserInfoHeader Raleway px-0" hidden = {!currentValue.yetAvailable || this.state.mentorMaxMatches == currentValue.assignedCount + currentValue.countMatches}>
                              {this.props.t("select")}
                            </div>
                            <div className="col-12 " hidden = {!currentValue.yetAvailable || this.state.mentorMaxMatches == currentValue.assignedCount + currentValue.countMatches}>
                              <input
                                className="AddNewCheckbox"
                                name={currentValue.userId._id}
                                type="checkbox"
                                onChange={() => 
                                  this.mentorCheck(currentValue.userId, currentValue.Email, index)
                                }
                                checked={
                                  this.state.mentor.id ===
                                  currentValue.userId
                                }
                              />
                            </div>
                            <div className="col-12 mt-2 UserInfoHeader Raleway text-left">
                              {this.props.t("match")}
                            </div>
                            <div className="col-12 mt-2 text-left" name = "matchPctg">
                              {this.state.isCalc ? <img src={loading} className="tiny-load" alt="logo" /> :
                                !!currentValue.Match ? Number.parseFloat(currentValue.Match).toFixed(1) + "%" :"N/A"}
                            </div>
                            <div className="col-12 my-4" hidden = {!(this.state.mentor.id == currentValue.userId)}>
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={(e) => this.addPair(e)}
                              >{this.props.t("add")} </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="col-12 text-center px-1">
              <h5 className="mb-4">{this.props.t('temp_matches') }</h5>
              <div className="row m-1">
                <div className="col-4 UserInfoHeader Raleway">
                      {this.props.t("mentor")}                  
                </div>
                <div className="col-4 UserInfoHeader Raleway">
                      {this.props.t("mentee")}                  
                </div>
                <div className="col UserInfoHeader Raleway">
                      {this.props.t("match")}                  
                </div>
                <div className="col-2 UserInfoHeader Raleway"/>              
              </div>
              <div className="userCardContainerFlex">
                {this.state.temporalMatches.map((currentValue, index, array) => {
                  return (
                    <div className="Users" key={index}>
                      <div className="UsersRows row py-3 align-middle">
                        <div className="badge badge-info col-4 mx-1">
                          {currentValue.mentee.email}
                        </div>
                        <div className="badge badge-info col-4 mx-1">
                          {currentValue.mentor.email}
                        </div>
                        <div className="badge badge-info col mx-1">
                          {currentValue.match}
                        </div>
                        <button
                          type="button"
                          className="badge badge-info col-2 mx-1 cancel"
                          onClick={(e) => this.removePair(index, currentValue.mentee, currentValue.mentor)}
                        >{this.props.t("cancel")} </button>
                      </div>
                      <hr />
                    </div>
                  );
                })}
              </div>
            </div>
          <div className="row float-right">
            <div className="col-12 my-4">
              <input
                type="reset"
                value={this.props.t("cancel")}
                className="btn btn-outline-danger mr-2"
                onClick={(e) => this.cancelHandler(e)}
              />
              <input
                type="button"
                className="btn btn-primary"
                value={this.props.t("save")}
                onClick={(e) => this.submitHandler(e)}
              />
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default withTranslation('Generals')(AddMatch);
