// Dependecies
import React,{Component} from "react";

class RolesResponsabilities extends Component{

    printStatus(status){
        if(status===true){
            return (<i className="fas fa-check-circle"></i>)
        }else{
            return (<span>-</span>);
        }
    }

    render(){
        return(
                 <div className="col-12 text-left  py-2">   
                    <div className="row">
                        <div className="col-5">{this.props.title}</div>
                        <div className="col-3">{this.props.createdOn.substring(0,10)}</div>
                        <div className="col-2 text-center">
                            {
                                this.printStatus(this.props.isMenteeOwner)
                            }
                        </div>
                        <div className="col-2 text-center">
                            {this.printStatus(this.props.isMentorOwner)}
                        </div>
                    </div>
                    <hr/>
                </div>
        )
    }
}

export default RolesResponsabilities;