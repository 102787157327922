// Dependencies
import React, { Component } from "react";
import axios from "axios";
import Swal from "sweetalert2";

// Server URL
import serverURL from "../../serverURL";
import { withTranslation } from "react-i18next";
var direccion = serverURL;

class AddInstitution extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: "",
      institutionName: "",
      contactFirstName: "",
      contactLastName: "",
      contactEmail: "",
      contactPhone: "",
      streetAddress: "",
      country: "",
      state: "",
      city: "",
      zip: "",
      phone: "",
      email: "",
      webPageUrl: "",
      description: "",
      weightPersonalInterests: 50,
      language:"es"
    };
  }

  imageHandler(e) {
    if (e.target.files[0] != undefined) {
      this.setState({
        image: e.target.files[0],
        img: URL.createObjectURL(e.target.files[0]),
      });
    }
  }

  hadleUserInput(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value });
  }

  cancelHandler(e) {
    e.preventDefault();
    Swal.fire({
       title: this.props.t('alerts.are_you_sure'),
         text: this.props.t('alerts.information_will_be_lost'),
         icon: 'warning',
         showCancelButton: true,
         confirmButtonColor: '#3085d6',
         cancelButtonColor: '#d33',
         confirmButtonText: this.props.t('yes'),
         cancelButtonText: this.props.t('cancel')
    }).then((result) => {
      if (result.value) {
        window.open("/institutions", "_self");
      }
    });
  }

  submitHandler(e) {
    e.preventDefault();
    e.target.className += " was-validated";
    const data = new FormData();
    for (var key in this.state) {
      if (this.state[key] === undefined) {
        continue;
      } else {
        data.append(key, this.state[key]);
      }
    }
    axios
      .post(direccion + "api/institution", data, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("UserSession"),
          
        },
      })
      .then((response) => {
        localStorage.setItem(
          "selectedInstitution",
          JSON.stringify(response.data.institutions[0])
        );
        Swal.fire({
          title: "Success",
          icon: "success",
          text: "Institution created",
        }).then((result) => {
          if (result.value) {
            window.open("/institutions/details/profile", "_self");
          }
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.stack[1],
          icon: "error",
        });
      });
  }

  render() {
    return (
      <div className="container my-3">
        <form
          className=" needs-validation"
          onSubmit={(e) => this.submitHandler(e)}
        >
          <div className="row text-left">
            <div className="col-3">
              <label
                htmlFor="file-upload"
                className="custom-file-upload"
                style={{ backgroundImage: `url(${this.state.img})` }}
              >
                <i className="fas fa-camera"></i>
                <br />
                {
                  this.props.t('upload_image')
                }
              </label>
              <input
                id="file-upload"
                type="file"
                name="image"
                onChange={(event) => this.imageHandler(event)}
              />
            </div>
            <div className="col mt-4">
              {this.props.t('institution')}
              <input
                type="text"
                className="mt-2"
                name="institutionName"
                value={this.state.institutionName}
                onChange={(event) => this.hadleUserInput(event)}
                required
              />
            </div>
             <div className="col-2 mt-4 text-center">
              {this.props.t('language')}
              <br />
              <select
                className="selectP"
                name="language"
                id=""
                onChange={(event) => this.hadleUserInput(event)}
                value={this.state.language}
              >
                <option value="es" >{this.props.t("languages.spanish")}</option>
                <option value="en" >{this.props.t("languages.english")}</option>
                <option value="fr" >{this.props.t("languages.french")}</option>
                <option value="de" >{this.props.t("languages.deutsch")}</option>
                <option value="pt" >{this.props.t("languages.portuguese")}</option>
              </select>
            </div>
            <div className="col-2 text-center mt-4">
              {this.props.t('interests_weight')}
              <div className="row">
                <div className="col-12">
                  <input
                    className="mySlider"
                    type="range"
                    name="weightPersonalInterests"
                    value={this.state.weightPersonalInterests}
                    onChange={(event) => this.hadleUserInput(event)}
                  />
                </div>
                <div className="col-6">
                  <span>{this.state.weightPersonalInterests}%</span>
                  <br />
                  <span>{this.props.t('personal')}</span>
                </div>
                <div className="col-6">
                  <span>{100 - this.state.weightPersonalInterests}%</span>
                  <br />
                  <span>{this.props.t('professional')}</span>
                </div>
              </div>
            </div>
            <div className="col-6 mt-4">
              {this.props.t('requestor.name')}
              <input
                type="text"
                className="mt-2"
                name="contactFirstName"
                value={this.state.contactFirstName}
                onChange={(event) => this.hadleUserInput(event)}
                required
              />
            </div>
            <div className="col-6 mt-4">
              {this.props.t('requestor.last_name')}
              <input
                type="text"
                className="mt-2"
                name="contactLastName"
                value={this.state.contactLastName}
                onChange={(event) => this.hadleUserInput(event)}
                required
              />
            </div>
            <div className="col-6 mt-4">
              {this.props.t('requestor.phone')}
              <input
                type="tel"
                className="mt-2"
                name="contactPhone"
                pattern="[0-9]{10}"
                maxLength="10"
                minLength="10"
                placeholder="10 digit number"
                value={this.state.contactPhone}
                onChange={(event) => this.hadleUserInput(event)}
                required
              />
            </div>
            <div className="col-6 mt-4">
              {this.props.t('requestor.email')}
              <input
                type="email"
                className="mt-2"
                name="contactEmail"
                value={this.state.contactEmail}
                onChange={(event) => this.hadleUserInput(event)}
                required
              />
            </div>
            <div className="col-12 mt-4">
              {this.props.t('address')}
              <input
                type="text"
                className="mt-2"
                name="streetAddress"
                value={this.state.streetAddress}
                onChange={(event) => this.hadleUserInput(event)}
              />
            </div>
            <div className="col-3 mt-4">
              {this.props.t('country')}
              <input
                type="text"
                className="mt-2"
                name="country"
                value={this.state.country}
                onChange={(event) => this.hadleUserInput(event)}
              />
            </div>
            <div className="col-3 mt-4">
              {
                this.props.t('state')
              }
              <input
                type="text"
                className="mt-2"
                name="state"
                value={this.state.state}
                onChange={(event) => this.hadleUserInput(event)}
              />
            </div>
            <div className="col-3 mt-4">
              {
                this.props.t('city')
              }
              <input
                type="text"
                className="mt-2"
                name="city"
                value={this.state.city}
                onChange={(event) => this.hadleUserInput(event)}
              />
            </div>
            <div className="col-3 mt-4">
              {
                this.props.t('postal')
              }
              <input
                type="number"
                className="mt-2"
                name="zip"
                value={this.state.zip}
                onChange={(event) => this.hadleUserInput(event)}
              />
            </div>
            <div className="col-12 mt-4">
              {this.props.t('phone')}
              <input
                type="tel"
                className="mt-2"
                name="phone"
                pattern="[0-9]{10}"
                maxLength="10"
                minLength="10"
                placeholder="10 digit number"
                value={this.state.phone}
                onChange={(event) => this.hadleUserInput(event)}
              />
            </div>
            <div className="col-12 mt-4">
              {this.props.t('email')}
              <input
                type="email"
                className="mt-2"
                name="email"
                value={this.state.email}
                onChange={(event) => this.hadleUserInput(event)}
              />
            </div>
            <div className="col-12 mt-4">
              {this.props.t('website')}
              <input
                type="url"
                pattern = "https?://.+"
                className="mt-2"
                name="webPageUrl"
                value={this.state.webPageUrl}
                onChange={(event) => this.hadleUserInput(event)}
              />
            </div>
            <div className="col-12 mt-4">
              {this.props.t('description')}
              <input
                type="text"
                className="mt-2"
                name="description"
                value={this.state.description}
                onChange={(event) => this.hadleUserInput(event)}
              />
            </div>
          </div>

          <div className="row float-right">
            <div className="col-12 my-4">
              <input
                type="reset"
                value={this.props.t('cancel')}
                className="btn btn-outline-danger mr-2"
                onClick={(e) => this.cancelHandler(e)}
              />
              <input type="submit" className="btn btn-primary" value={this.props.t('save')} />
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default withTranslation('Generals')(AddInstitution);
