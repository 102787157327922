// Dependencies
import React from "react";

// Components
import BarCharts from "../Charts/BarCharts.js";
import axios from "axios";
import PieCharts1 from "../Charts/PieCharts1.js";
import PieCharts2 from "../Charts/PieCharts2.js";
import PieCharts3 from "../Charts/PieCharts3.js";

// Server URL
import serverURL from "../../serverURL";
import { withTranslation } from "react-i18next";
var direccion = serverURL;

export class GoalProgress extends React.Component {
  constructor(props) {
    super(props);
    var userId = localStorage.getItem("UserId");
    this.state = {
      userid: userId,
    };
  }

  componentDidMount() {
    var object = {
      module: "admin",
    };
    axios
      .get(
        direccion +
          "api/institutionalAdmin/statusOfTasksGoalsAndPrograms/" +
          this.state.userid,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("UserSession"),
            
          },
        }
      )
      .then((response) => {
        this.setState({
          GoalProgress: response.data.statusOfTasksGoalsAndPrograms,
        });
      });
  }

  render() {
    return (
      <div>
        <div className="container-fluid">
          <div className="row w-100">
            <div className="col-6 my-3">
              {this.state.GoalProgress ? (
                <PieCharts1
                  dataArray={this.state.GoalProgress}
                  label={this.props.t('charts.programs_progress')}
                />
              ) : null}
            </div>
            <div className="col-6 my-3">
              {this.state.GoalProgress ? (
                <PieCharts2
                  dataArray={this.state.GoalProgress}
                  label={this.props.t('charts.goals_progress')}
                />
              ) : null}
            </div>
            <div className="col-6 my-3 mx-auto">
              {this.state.GoalProgress ? (
                <PieCharts3
                  dataArray={this.state.GoalProgress}
                  label={this.props.t('charts.tasks_progress')}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation('Generals')(GoalProgress);
